import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminModel, ApiModel, PaginationModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminsService extends Api<AdminModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<AdminModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/security/users`;
  }

  public override delete(id: string): Observable<any> {
    const apiUrl = `${this.endpoint}/${id}/status`;
    return this.http.put(apiUrl, {
      status: 'inactive',
    });
  }

  public enable(id: string): Observable<any> {
    const apiUrl = `${this.endpoint}/${id}/status`;
    return this.http.put(apiUrl, {
      status: 'active',
    });
  }

  public export(params: PaginationModel): Observable<Blob> {
    params.limit = 100000;
    const endpoint = `${this.endpoint}/export${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public setNewPassword(data: {
    password: string;
    adminId: string;
  }): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/admin/security/users/${data.adminId}/password`;
    return this.http.put(apiUrl, {
      password: data.password,
    } as Partial<ApiModel>);
  }

  public toggleImpersonate(admin: AdminModel): Observable<any> {
    const apiUrl = `${this.endpoint}/${admin.id}/impersonate`;
    return this.http.put(apiUrl, {
      impersonate: !admin.isImpersonate,
    });
  }

  public updateName(
    data: {
      name: string;
      last_name: string;
      second_last_name?: string;
    },
    adminId: string
  ): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/admin/security/users/${adminId}/name`;
    return this.http.put(apiUrl, data).pipe(first());
  }
}
