import { ActivatedRouteSnapshot } from '@angular/router';
import { PERMISSION_ROUTER_DATA } from '@core/constants';

export function getLeafPermission(route: ActivatedRouteSnapshot) {
  let node = route;
  while (node.children.length === 1) {
    node = node.children[0];
  }
  return node.data[PERMISSION_ROUTER_DATA];
}

export function getListPermission(permission: string) {
  return `${permission}.list`;
}

export function getUpdatePermission(permission: string) {
  return `${permission}.update`;
}
