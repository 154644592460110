import {
  PaymentMonthsModel,
  PaymentTypeEnum,
  PaymentTypeModel,
} from '@core/models';

export const PAYMENTS = {
  spei: 1,
  card: 3,
  store: 4,
  bitcoin: 5,
};

export const PAYMENTS_TYPE: PaymentTypeModel[] = [
  {
    id: PAYMENTS.card,
    name: 'Tarjeta de Crédito/Débito',
    type: PaymentTypeEnum.TC_TD_CARD,
    icons: ['logo-visa', 'logo-master', 'logo-amex'],
  },
  {
    id: PAYMENTS.store,
    name: 'Tienda de Conveniencia/Banco',
    type: PaymentTypeEnum.MONEY,
    icons: ['logo-money'],
  },
  {
    id: PAYMENTS.spei,
    name: 'Transferencia SPEI',
    type: PaymentTypeEnum.SPEI,
    icons: ['logo-spei'],
  },
  {
    id: PAYMENTS.bitcoin,
    name: 'Bitcoin',
    type: PaymentTypeEnum.BITCOIN,
    icons: ['logo-bitcoin'],
  },
];

export const MONTHS: PaymentMonthsModel[] = [
  {
    total: 3,
    desc: '3 meses',
  },
  {
    total: 6,
    desc: '6 meses',
  },
];

export enum CurrencyCodeEnum {
  BITCOIN = 'BTC',
  USD = 'USD',
  MXN = 'MXN',
}
