import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiModel,
  CollectionNoticeModel,
  CollectionNoticePaymentResponse,
  PaginationModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@rcsa-frontend/environments/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CollectionNoticeService extends Api<CollectionNoticeModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<CollectionNoticeModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/collections/collection-notices`;
  }

  public getPaymentCollectionNotices(
    params: PaginationModel
  ): Observable<CollectionNoticePaymentResponse<CollectionNoticeModel>> {
    params.limit = 100000;
    const url = `${this.endpoint}/payment${`?${new URLSearchParams(
      params
    ).toString()}`}`;
    return this.http
      .get<CollectionNoticePaymentResponse<CollectionNoticeModel>>(url)
      .pipe(
        map((respose) => {
          return respose;
        })
      );
  }
  public sendVoucher(data: {
    email: string;
    collectionNoticeId: string;
  }): Observable<any> {
    const url = `${environment.apiUrl}/v1/collections/collection-notices/${data.collectionNoticeId}/send-payment-email`;
    return this.http.post(url, { email: data.email } as Partial<ApiModel>);
  }
}
