import { Injectable } from '@angular/core';
import { PaymentDetailModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentSyncfyService extends Api<PaymentDetailModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<PaymentDetailModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/payments/payment-management`;
  }

  public getPaymentDetail(
    paymentId: string,
    paymentType: string
  ): Observable<PaymentDetailModel> {
    const endpoint = `${this.endpoint}/${paymentType}/external/${paymentId}`;
    return this.apiService.get(endpoint).pipe(
      map((result) => {
        return result.data;
      })
    );
  }
}
