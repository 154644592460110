import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FileUploaderComponent,
    },
  ],
})
export class FileUploaderComponent implements ControlValueAccessor {
  @Input() inputLabel = 'Selecciona un archivo';
  @Input() id: string;
  @Input() touched = false;
  @Input() restrictions: FileRestrictions;
  @Input() filesList: Array<Partial<File>> = [];
  public base64 = '';
  public errorMsg = '';
  private onChange: (value: string) => void;
  private onTouched: () => void;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public writeValue(base64: string): void {
    this.base64 = base64;
  }

  public registerOnChange(onChange: (value: string) => void): void {
    this.onChange = (base64) => {
      this.base64 = base64;
      this.changeDetectorRef.detectChanges();
      onChange(base64);
    };
  }

  public registerOnTouched(onTouched: never): void {
    this.onTouched = onTouched;
  }

  public select(e: SelectEvent) {
    this.errorMsg = '';
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (e.files.length + this.filesList?.length > 1) {
      this.errorMsg =
        'Es necesario remover el archivo actual para cargar uno nuevo.';
      e.preventDefault();
      return;
    }

    const file = e.files[0];

    if (file.validationErrors?.includes('invalidFileExtension')) {
      this.errorMsg = `Archivo no permitido. Solo extensiones ${this.restrictions.allowedExtensions}`;
      e.preventDefault();
      return;
    }

    if (file.validationErrors?.includes('invalidMaxFileSize')) {
      this.errorMsg = `Archivo no permitido. Máximo 2 MB.`;
      e.preventDefault();
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const reader = new FileReader();
    reader.onload = function (ev) {
      let result = ev.target?.result as string;
      if (result) {
        result = result.split(',')[1];
      }
      self.onChange(result);
    };

    if (file.rawFile) {
      reader.readAsDataURL(file.rawFile);
    }
  }

  public remove(): void {
    this.errorMsg = '';
    this.onChange('');
  }
}
