import { Injectable } from '@angular/core';
import { arrayToMap } from '@core/functions';
import {
  BackEndPermissionMap,
  CollectionResponse,
  PermissionModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { PermissionsState } from '@core/state/admin';
import { environment } from '@env/environment';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService extends Api<PermissionModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<PermissionModel>,
    private permissionsState: PermissionsState
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/security/roles`;
  }

  public getPermissions(): Observable<CollectionResponse<PermissionModel>> {
    this.endpoint = `${environment.apiUrl}/v1/admin/security/permissions`;
    return this.getAll({ limit: 1000 }).pipe(
      tap((response) =>
        this.permissionsState.addPermissions(
          this.createPermissionsHash(response.data.items)
        )
      )
    );
  }

  public getPermissionByRole(
    idRole: string
  ): Observable<CollectionResponse<PermissionModel>> {
    this.endpoint = `${environment.apiUrl}/v1/admin/security/roles/${idRole}/permissions`;
    return this.getAll();
  }

  public createPermissionsHash(
    permissionsList: PermissionModel[]
  ): BackEndPermissionMap {
    return arrayToMap<PermissionModel>(permissionsList, 'name');
  }
}
