import { Injectable } from '@angular/core';
import { ApiModel, CollectionResponse, SingleItemResponse } from '@core/models';
import { AccesoryModel } from '@core/models/accessory.model';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccessoriesService extends Api<AccesoryModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<AccesoryModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/collections/accessories`;
  }

  public activateAccessory(
    data: Partial<ApiModel>,
    accessoryId: string
  ): Observable<SingleItemResponse<AccesoryModel>> {
    const endpoint = `${this.endpoint}/${accessoryId}/discount`;
    return this.apiService.put(endpoint, JSON.stringify(data));
  }

  public deleteAccessory(
    accessoryId: string
  ): Observable<CollectionResponse<AccesoryModel>> {
    const endpoint = `${environment.apiUrl}/v1/admin/discounts/${accessoryId}`;
    return this.apiService.delete(endpoint);
  }
}
