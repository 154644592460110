import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DEFAULT_LANGUAGE, LANGUAGES } from '@core/constants';
import { LanguageModel } from '@core/models';
import { ContextMenuSelectEvent } from '@progress/kendo-angular-menu';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent {
  @Input()
  public target: HTMLElement;

  @Output() language: EventEmitter<string> = new EventEmitter();

  public languajes: LanguageModel[] = LANGUAGES;
  public action(event: ContextMenuSelectEvent) {
    this.language.emit(event.item.text || DEFAULT_LANGUAGE);
  }
}
