import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AcademicYear,
  CollectionResponse,
  CycleSchoolModel,
  FiscalYear,
} from '@core/models';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CyclesService extends Api<CycleSchoolModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<CycleSchoolModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/cycles`;
  }

  public getAcademicYears(): Observable<AcademicYear[]> {
    return this.http
      .get<CollectionResponse<AcademicYear>>(`${this.endpoint}/academic_years`)
      .pipe(map((response) => response.data.items));
  }

  public getFiscalYears(): Observable<FiscalYear[]> {
    const uri = `${environment.apiUrl}/v1/admin/reports/collection-notices-concentrate/fiscal-years`;
    return this.http
      .get<CollectionResponse<FiscalYear>>(uri)
      .pipe(map((response) => response.data.items));
  }
}
