import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionNoticeModel,
  CollectionResponse,
  PartialPaymentRefundPayloadModel,
  PartialRefundReasonModel,
  PaymentsCardRespondeModel,
  RefundReasonsModel,
  SingleItemResponse,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService extends Api<PaymentsCardRespondeModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<PaymentsCardRespondeModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/payments/payment-management/cards`;
  }

  public refundPayment(collectionNoticeId: string): Observable<boolean> {
    const apiUrl = `${environment.apiUrl}/v1/admin/payments/payment-management/${collectionNoticeId}/refund`;
    return this.http.put(apiUrl, null).pipe(map(() => true));
  }

  public partialRefund(
    data: Partial<PartialPaymentRefundPayloadModel>
  ): Observable<PartialPaymentRefundPayloadModel> {
    const apiUrl = `${environment.apiUrl}/v1/admin/payments/refunds`;
    return this.http.post<PartialPaymentRefundPayloadModel>(apiUrl, data);
  }

  public getRefundReasons(): Observable<RefundReasonsModel[]> {
    const endpoint = `${environment.apiUrl}/v1/admin/payments/refunds/reasons`;
    return this.http.get<CollectionResponse<RefundReasonsModel>>(endpoint).pipe(
      map((result: CollectionResponse<RefundReasonsModel>) => {
        return result.data.items;
      })
    );
  }

  public getPartialRefundConfiguration(
    reasonRefundId: string,
    collectionNoticeId: string,
    refundDate: string | null
  ): Observable<PartialRefundReasonModel> {
    const endpoint = `${
      environment.apiUrl
    }/v1/admin/payments/refunds/collection-notice/${collectionNoticeId}/reason/${reasonRefundId}${
      refundDate !== null ? '?refund_date=' + refundDate : ''
    }`;
    return this.http
      .get<CollectionResponse<PartialRefundReasonModel>>(endpoint)
      .pipe(
        map((result: any) => {
          return result.data as PartialRefundReasonModel;
        })
      );
  }

  public exportAccountStatus(
    familyId: string,
    startDate: string,
    endDate: string
  ): Observable<Blob> {
    const params = {
      familyId,
      startDate,
      endDate,
    };

    const endpoint = `${
      environment.apiUrl
    }/v1/admin/payments/account-status${`?${new URLSearchParams(
      params
    ).toString()}`}`;
    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public adjustPayment(paymentId: string): Observable<CollectionNoticeModel> {
    const apiUrl = `${environment.apiUrl}/v1/admin/payments/adjust-collection-notices`;
    return this.http
      .post<SingleItemResponse<CollectionNoticeModel>>(apiUrl, {
        payment_id: paymentId,
      })
      .pipe(
        map((result) => {
          return result.data;
        })
      );
  }
}
