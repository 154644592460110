import { PaymentHistoryModel } from '@core/models';

export function isPaymentBillable(payment: PaymentHistoryModel): boolean {
  const isBilled = payment.isBilled?.toLowerCase();
  return (
    payment.status.toLowerCase() === 'pagado' &&
    (payment.isBilled === null ||
      isBilled === 'pagado' ||
      isBilled === 'no facturado' ||
      isBilled === 'devuelto')
  );
}
