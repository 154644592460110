import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChargebacksModel, PaginationModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChargebacksService extends Api<ChargebacksModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<ChargebacksModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/payments/webhooks/chargeback-history`;
  }

  public export(params: PaginationModel): Observable<Blob> {
    params.limit = 1000000;
    params.page = 1;
    const endpoint = `${
      environment.apiUrl
    }/v1/payments/webhooks/chargeback/export${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;
    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public findById(charbackId: string): Observable<ChargebacksModel> {
    const endpoint = `${environment.apiUrl}/v1/payments/webhooks/chargeback-detail/${charbackId}`;
    return this.http.get(endpoint).pipe(map((result: any) => result.data));
  }
}
