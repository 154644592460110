import {
  Computed,
  DataAction,
  Payload,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { AppAlertModel } from '@core/models';
import { State } from '@ngxs/store';
import { Observable, of } from 'rxjs';

interface GlobalAlertStore {
  messages: { [id: string]: AppAlertModel };
}

const defaults: GlobalAlertStore = {
  messages: {},
};

@Persistence()
@StateRepository()
@State<GlobalAlertStore>({
  name: 'alerts',
  defaults,
})
@Injectable()
export class AlertsState extends NgxsDataRepository<GlobalAlertStore> {
  @Computed()
  public get shouldDisplay(): boolean {
    return Object.keys(this.snapshot.messages).length > 0;
  }

  @Computed()
  public get messages$(): Observable<AppAlertModel[]> {
    return of(Object.values(this.snapshot.messages));
  }

  @DataAction()
  public pushMessage(@Payload('alert') alert: AppAlertModel): void {
    this.ctx.patchState({
      messages: {
        ...this.snapshot.messages,
        [alert.id]: alert,
      },
    });
  }

  @DataAction()
  public deleteMessage(@Payload('id') id: string): void {
    const messages = { ...this.snapshot.messages };
    delete messages[id];
    this.ctx.patchState({ messages });
  }

  @DataAction()
  public clearState(): void {
    this.ctx.patchState({
      ...defaults,
    });
  }
}
