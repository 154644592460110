import {
  Computed,
  DataAction,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { GenericState } from '@core/state/generic.state';
import { State } from '@ngxs/store';
import { PageChangeEvent, PagerSettings } from '@progress/kendo-angular-grid';
export interface PageSettings {
  pageLimit: number;
  settings: PagerSettings;
}
const defaults: PageSettings = {
  pageLimit: 20,
  settings: {
    buttonCount: 10,
    type: 'numeric',
    pageSizes: [10, 20, 50],
    position: 'bottom',
  },
};

@Persistence()
@StateRepository()
@State<PageSettings>({
  name: 'pageable',
  defaults,
})
@Injectable()
export class PageableState extends NgxsDataRepository<PageSettings> {
  @Computed()
  public get getLimit(): number {
    return this.snapshot.pageLimit;
  }
  @Computed()
  public get settings(): PagerSettings {
    return this.snapshot.settings;
  }

  @DataAction()
  public setLimit(pageLimit: number): void {
    this.ctx.patchState({
      pageLimit: pageLimit,
    });
  }

  @DataAction()
  public defaultPageLimit(): void {
    this.ctx.patchState({
      ...defaults,
    });
  }

  public setPageLimit(
    event: PageChangeEvent,
    currentState: GenericState<any>
  ): boolean {
    const changeLimit = currentState.pageLimit !== event.take;
    currentState.setLimit(event.take);
    this.setLimit(event.take);
    return changeLimit;
  }
}
