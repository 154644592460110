import {
  AbandonedPaymentsState,
  AccountState,
  InvoicesState as AdminInvoicesState,
  AdministrativeRuleHistoryState,
  AdminsState,
  AuditLogsState,
  BusinessState,
  CollectionNoticesState,
  ConceptState,
  ConceptsAssignmentState,
  ConceptsManagementState,
  CrmLogsState,
  DiscountCouponsHistoryState,
  DiscountCouponsState,
  DiscountRulesState,
  DiscountsState,
  EventsState,
  FailedRecurringPaymentState,
  FamiliesState,
  FamilyLogState,
  FinancialEntriesState,
  FinancialEntrySummaryState,
  ParametersState,
  PaymentsState,
  PendingScholarshipState,
  PermissionsState,
  PricesState,
  RegionState,
  ReportsStates,
  RoleState,
  RvoeState,
  ScholarshipsFilesState,
  SectionState,
  StudentCyclesState,
  StudentScholarshipState,
  StudentsState,
  UsersState,
} from './';
import { ExternalCollectionNoiceState } from './external-collections/external-collections.state';
import { ExternalInvoicesState } from './external-invoices/external-invoices.state';
import { ExternalPaymentsState } from './external-payments/external-payments.state';
export * from './abandoned-payments/abandoned-payments.state';
export * from './account-state/account-state.state';
export * from './administrative-rules/administrative-rules.state';
export * from './admins/admins.state';
export * from './audit-logs/audit-logs.state';
export * from './business/business.state';
export * from './collection-notices/collection-notices.state';
export * from './concepts-assignment/concepts-assignment.state';
export * from './concepts-management/concepts-management.state';
export * from './concepts/concepts.state';
export * from './crm-logs/crm-logs.state';
export * from './discount-rules/discount-rules.state';
export * from './discounts-coupons-history/discounts-coupons-history.state';
export * from './discounts-coupons/discounts-coupons.state';
export * from './discounts/discounts.state';
export * from './events/events.state';
export * from './external-collections/external-collections.state';
export * from './external-invoices/external-invoices.state';
export * from './external-payments/external-payments.state';
export * from './failed-recurring-payment-notice/failed-recurring-payment-notice.state';
export * from './families/families.state';
export * from './family-log/family-log.state';
export * from './financial-entries-summary/financial-entries-summary.state';
export * from './financial-entries/financial-entries.state';
export * from './invoices/invoices.state';
export * from './parameters/parameters.state';
export * from './payments';
export * from './pending-scholarships/pending-scholarships.state';
export * from './permissions/permissions.state';
export * from './prices/prices.state';
export * from './region/region.state';
export * from './reports';
export * from './role/role.state';
export * from './rvoe/rvoe.state';
export * from './scholarships-files/scholarships-files.state';
export * from './section/section.state';
export * from './student-cycles/student-cycles.state';
export * from './student-scholarship/student-scholarship.state';
export * from './students/students.state';
export * from './users/users.state';

export const AdminStates = [
  AccountState,
  AdminInvoicesState,
  AdministrativeRuleHistoryState,
  AdminsState,
  AuditLogsState,
  BusinessState,
  CollectionNoticesState,
  ConceptsAssignmentState,
  ConceptsManagementState,
  ConceptState,
  CrmLogsState,
  DiscountRulesState,
  DiscountsState,
  ExternalCollectionNoiceState,
  ExternalInvoicesState,
  ExternalPaymentsState,
  EventsState,
  FailedRecurringPaymentState,
  FamiliesState,
  FinancialEntriesState,
  FinancialEntrySummaryState,
  ParametersState,
  PendingScholarshipState,
  PermissionsState,
  PricesState,
  RegionState,
  RoleState,
  RvoeState,
  SectionState,
  ScholarshipsFilesState,
  StudentCyclesState,
  StudentScholarshipState,
  StudentsState,
  UsersState,
  DiscountCouponsState,
  AbandonedPaymentsState,
  ...ReportsStates,
  DiscountCouponsHistoryState,
  FamilyLogState,
  ...PaymentsState,
];
