import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { AbandonedPaymentModel, PriceModel } from '@core/models';
import { AbandonedPaymentsService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<PriceModel>>({
  name: 'abandonedPayments',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class AbandonedPaymentsState extends GenericState<AbandonedPaymentModel> {
  override service: AbandonedPaymentsService;

  constructor(private abandonedPaymentsState: AbandonedPaymentsService) {
    super();
    this.service = abandonedPaymentsState;
  }
}
