import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentCardModel, PaymentsCardRespondeModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@rcsa-frontend/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExternalPaymentCardService extends Api<PaymentsCardRespondeModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<PaymentsCardRespondeModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/external/payments/payment-management/cards/multiple`;
  }

  public multiplePayment(
    data: PaymentCardModel
  ): Observable<PaymentsCardRespondeModel> {
    const params = JSON.stringify(data);
    return this.http.post<PaymentsCardRespondeModel>(this.endpoint, params);
  }
}
