import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { ConceptAssignmentModel, GridFilter } from '@core/models';
import { ConceptsAssignmentService } from '@core/services';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { formatMultiStoreData } from '@core/utils/format-multi-store-data.util';
import { State } from '@ngxs/store';
import { Observable, finalize, map, switchMap, tap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<ConceptAssignmentModel>>({
  name: 'conceptsAssignment',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class ConceptsAssignmentState extends GenericState<ConceptAssignmentModel> {
  override service: ConceptsAssignmentService;
  constructor(private conceptsAssignmentService: ConceptsAssignmentService) {
    super();
    this.service = conceptsAssignmentService;
  }

  public clearTable() {
    this.patchState({ pages: {} });
  }

  public createConceptsAssignment(
    data: ConceptAssignmentModel,
    schoolId: string
  ): Observable<ConceptAssignmentModel[]> {
    this.toggleLoading();
    return this.conceptsAssignmentService.create(data, schoolId).pipe(
      finalize(() => {
        this.patchState({
          isLoading: false,
        });
      }),
      switchMap((): Observable<ConceptAssignmentModel[]> => {
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        return this.getConceptsAssignmentsByPage(lastPage, schoolId, true);
      })
    );
  }

  public deleteConceptsAssignment(
    id: string,
    schoolId: string
  ): Observable<ConceptAssignmentModel[]> {
    return this.conceptsAssignmentService.deleteAssignment(id, schoolId).pipe(
      finalize(() => {
        this.toggleLoading();
      }),
      switchMap((): Observable<ConceptAssignmentModel[]> => {
        return this.getConceptsAssignmentsByPage(
          this.currentPage,
          schoolId,
          true
        );
      })
    );
  }

  public getConceptsAssignmentById(
    id: string
  ): Observable<ConceptAssignmentModel> {
    return this.state$.pipe(
      map((conceptsAssignmentState) => {
        return conceptsAssignmentState.items[id];
      })
    );
  }

  public getConceptsAssignmentsByPage(
    page = 1,
    idSchool: string,
    force = false,
    params?: GridFilter
  ): Observable<ConceptAssignmentModel[]> {
    this.patchState({
      currentPage: page,
    });

    if (this.isPageLoaded(page) && !force) {
      return this.itemsByPage$;
    }
    this.toggleLoading();
    return this.conceptsAssignmentService
      .getAssignmentBySchool(idSchool, {
        page,
        limit: this.pageLimit,
        ...params,
      })
      .pipe(
        tap((content): void => {
          const currentState = this.getState();
          const { items: conceptsAssignment } = content.data;
          const objectToPatch: Partial<
            GenericStateModel<ConceptAssignmentModel>
          > = {
            totalItems: content.data.total,
            currentPage: page,
            items: {
              ...currentState.items,
              ...formatMultiStoreData(conceptsAssignment),
            },
            pages: {
              ...currentState.pages,
              [page]: conceptsAssignment.map((concepts) => concepts.id),
            },
            isLoading: false,
          };
          this.patchState(objectToPatch);
        }),
        map((content) => {
          return content.data.items;
        })
      );
  }

  public updateConceptsAssignment(
    concept: ConceptAssignmentModel,
    schoolId: string
  ): Observable<ConceptAssignmentModel[]> {
    this.toggleLoading();
    return this.conceptsAssignmentService.update(concept, concept.id).pipe(
      finalize(() => {
        this.patchState({
          isLoading: false,
        });
      }),
      switchMap((): Observable<ConceptAssignmentModel[]> => {
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        return this.getConceptsAssignmentsByPage(lastPage, schoolId, true);
      })
    );
  }
}
