import { BusinessModel } from './business.model';
import { ApiModel } from './generic-state.model';
import { RegionModel } from './region.model';
import { SchoolModel } from './school.model';

export interface RvoeModel extends ApiModel {
  [key: string]: any;
  schoolId: string;
  school?: SchoolRvoeModel;
  sectionId: string;
  type: string;
  value: string;
}
export interface RvoeTypesModel {
  name: string;
  key: RvoeTypeEnum;
}

export enum RvoeTypeEnum {
  MAN = 'hombre',
  WOMAN = 'mujer',
  MIXED = 'mixto',
}

export interface SchoolRvoeModel extends SchoolModel {
  region: RegionModel;
  business: BusinessModel;
}
