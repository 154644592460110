import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { EventModel } from '@core/models';
import { EventsService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { values } from 'lodash';
import { Observable, map } from 'rxjs';

@StateRepository()
@State<GenericStateModel<EventModel>>({
  name: 'events',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class EventsState extends GenericState<EventModel> {
  override service: EventsService;
  constructor(eventsServive: EventsService) {
    super();
    this.service = eventsServive;
  }

  public getEventByCode(code: string): Observable<EventModel | undefined> {
    return this.state$.pipe(
      map((stateModel) => {
        const item = values(stateModel.items).find(
          (event) => event.code === code
        );
        return item;
      })
    );
  }
}
