import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { ScholarshipsReportModel } from '@core/models/reports/scholarships-report.model';
import { ScholarshipsReportsService } from '@core/services/admin/reports/scholarships-reports/scholarships-reports.service';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<ScholarshipsReportModel>>({
  name: 'scholarshipsReport',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class ScholarshipsReportState extends GenericState<ScholarshipsReportModel> {
  override service: ScholarshipsReportsService;
  constructor(scholarshipsReportsService: ScholarshipsReportsService) {
    super();
    this.service = scholarshipsReportsService;
  }
}
