import { AdminStates } from './admin';
import { ExternalStates } from './external';
import { SharedPersistedStates, SharedStates } from './shared';
import { GlobalState } from './shared/global/global.state';
import { UserStates } from './user';

export const PersistedStates = [...SharedPersistedStates, ...ExternalStates];
export const States = [
  ...AdminStates,
  ...SharedStates,
  ...UserStates,
  ...ExternalStates,
  GlobalState,
];

export * from './generic.state';
export * from './shared/global/global.state';
