import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FamilyLogModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class FamilyLogService extends Api<FamilyLogModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<FamilyLogModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/families/log`;
  }

  public buildStringEndpoint(familyId: string) {
    this.endpoint = `${environment.apiUrl}/v1/admin/families/log/${familyId}`;
  }
}
