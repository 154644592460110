import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { DiscountRuleModel } from '@core/models';
import { DiscountRulesService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<DiscountRuleModel>>({
  name: 'discountRules',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class DiscountRulesState extends GenericState<DiscountRuleModel> {
  override service: DiscountRulesService;

  constructor(private discountRulesService: DiscountRulesService) {
    super();
    this.service = discountRulesService;
  }
}
