import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { BusinessModel } from '@core/models';
import { BusinessService } from '@core/services/business/business.service';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { CatalogsState } from '@core/state/shared';
import { State } from '@ngxs/store';
import { Observable, finalize, switchMap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<BusinessModel>>({
  name: 'business',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class BusinessState extends GenericState<BusinessModel> {
  override service: BusinessService;

  constructor(
    private businessService: BusinessService,
    private catalogsState: CatalogsState
  ) {
    super();
    this.service = businessService;
  }

  public createBusiness(business: BusinessModel): Observable<BusinessModel[]> {
    this.toggleLoading();
    return this.businessService.post(business).pipe(
      finalize(() => {
        this.toggleLoading();
      }),
      switchMap((business: BusinessModel): Observable<BusinessModel[]> => {
        this.toggleLoading();
        this.catalogsState.pushItem(business, 'business');
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        return this.getAllByPage({ page: lastPage }, true);
      })
    );
  }

  public updateBusiness(
    parameter: BusinessModel,
    parameterId: string
  ): Observable<BusinessModel[]> {
    this.toggleLoading();
    return this.businessService.update(parameter, parameterId).pipe(
      finalize(() => {
        this.toggleLoading();
      }),
      switchMap((): Observable<BusinessModel[]> => {
        this.toggleLoading();
        return this.getAllByPage({ page: this.currentPage }, true);
      })
    );
  }

  public deleteBusiness(id: string): Observable<BusinessModel[]> {
    this.toggleLoading();
    return this.businessService.delete(id).pipe(
      finalize(() => {
        this.toggleLoading();
      }),
      switchMap((): Observable<BusinessModel[]> => {
        return this.getAllByPage({ page: this.snapshot.currentPage }, true);
      })
    );
  }
}
