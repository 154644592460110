import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  SingleItemResponse,
  StudentAdministrativeRuleModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignedAdministrativeRulesService extends Api<StudentAdministrativeRuleModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<StudentAdministrativeRuleModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/account/administrative-rules/index`;
  }

  public show(id: string): Observable<StudentAdministrativeRuleModel> {
    const endpoint = `${environment.apiUrl}/v1/account/administrative-rules/show`;
    return this.http
      .get<SingleItemResponse<StudentAdministrativeRuleModel>>(
        `${endpoint}/${id}`
      )
      .pipe(map((response) => response.data));
  }
}
