import { NotificationType } from '@core/models';

export const ALERTS = {
  ADMINISTRATIVE_RULES: {
    PENDING: {
      id: 'pending-administrative-rules',
      message:
        'Tienes Normas Administrativas pendientes de firmar, por favor dirígete a Mi Cuenta > Normas Administrativas.',
    },
  },
};

export const CLASS_ALERT_TYPES: Map<string, string> = new Map([
  [NotificationType.success, 'text-green ph-check-circle'],
  [NotificationType.error, 'text-danger ph-x-circle'],
  [NotificationType.warning, 'text-warning ph-warning'],
  [NotificationType.process, 'text-gray-n4 ph-clock'],
]);
