import { CollectionResponse, PaginationModel } from '@core/models';
import { Observable, map } from 'rxjs';
import { ApiService } from './api/api.service';

export interface ApiModel {
  id?: string;
}

export abstract class Api<T extends ApiModel> {
  abstract endpoint: string;

  constructor(protected readonly apiService: ApiService<T>) {}

  getAll(paginationData?: PaginationModel): Observable<CollectionResponse<T>> {
    return this.apiService.getAll(
      `${this.endpoint}${
        paginationData
          ? `?${new URLSearchParams(paginationData as any).toString()}`
          : ''
      }`
    );
  }

  get(): Observable<T> {
    return this.apiService.get(this.endpoint).pipe(
      map((data) => {
        return data.data;
      })
    );
  }

  delete(id: string): Observable<CollectionResponse<T>> {
    return this.apiService.delete(`${this.endpoint}/${id}`);
  }

  find(id: string): Observable<T> {
    return this.apiService.get(`${this.endpoint}/${id}`).pipe(
      map((data) => {
        return data.data;
      })
    );
  }

  update(object: Partial<ApiModel>, id?: string): Observable<T> {
    const data = JSON.stringify(object);
    const url = id ? `${this.endpoint}/${id}` : this.endpoint;
    return this.apiService.put(url, data).pipe(
      map((data) => {
        if (Array.isArray(data.data)) {
          return data.data[0];
        }
        return data.data;
      })
    );
  }
  put(object: Partial<ApiModel>): Observable<T> {
    const data = JSON.stringify(object);
    return this.apiService.put(this.endpoint, data).pipe(
      map((data) => {
        if (Array.isArray(data.data)) {
          return data.data[0];
        }
        return data.data;
      })
    );
  }
  post(object: Partial<ApiModel>): Observable<T> {
    const data = JSON.stringify(object);
    return this.apiService.post(this.endpoint, data).pipe(
      map((data) => {
        if (Array.isArray(data.data)) {
          return data.data[0];
        }
        return data.data;
      })
    );
  }
}
