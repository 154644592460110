import { ComboboxDataModel } from '@core/models';

export const SV_DOCUMENT_TYPES_IDS = {
  DUI: '13',
  PASSPORT: '03',
  NIT: '36',
  NRC: '45',
};

export const SV_DOCUMENT_TYPES: ComboboxDataModel[] = [
  {
    description: 'DUI',
    id: SV_DOCUMENT_TYPES_IDS.DUI,
  },
  {
    description: 'Pasaporte',
    id: SV_DOCUMENT_TYPES_IDS.PASSPORT,
  },
  {
    description: 'NIT',
    id: SV_DOCUMENT_TYPES_IDS.NIT,
  },
  {
    description: 'NRC',
    id: SV_DOCUMENT_TYPES_IDS.NRC,
  },
];

export const INVOICE_TRANSLATE_KEY = 'INVOICE';

export const INVOICE_TYPES: { value: string }[] = [
  { value: 'tuition' },
  { value: 'inscription' },
  { value: 'reinscription' },
  { value: 'credit_note' },
  { value: 'other' },
  { value: 'public' },
];
