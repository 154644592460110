import { Injectable } from '@angular/core';
import { CurrentUserModel } from '@core/models';
import { environment } from '@env/environment';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends Api<CurrentUserModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<CurrentUserModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/account/profile`;
  }
}
