import { ApiModel } from './generic-state.model';

export interface ConceptModel extends ApiModel {
  id: string;
  name: string;
  type: string;
  is_unique: string;
  conceptCategory: ConceptCategoryModel;
}

export interface ConceptCategoryModel extends ApiModel {
  name: string;
}

export interface ConceptManagmentModel extends ApiModel {
  [key: string]: any;
  name: string;
  description: string;
  isDeductible: boolean;
  isRecurrent: boolean;
  frequency: string;
  activeMonths: string | string[];
  conceptTypeId: string;
  concept: ConceptModel;
  isDiscountApplicable: boolean;
  isBillable: boolean;
  isUnique: boolean;
  isScholarshipAllowed: boolean;
  isInterestApplicable: boolean;
  isIvaApplicable: boolean;
  endDate?: Date;
  startDate?: Date;
  ledgerAccountId: string;
  unityKey: string;
  prodServiceKey: string;
  typeAmount: string;
}

export enum conceptTypesEnum {
  ENROLLMENT = 'colegiatura',
  INSCRIPTION = 'inscripcion',
  ACADEMY = 'academia',
  DISCOUNT = 'descuento',
  SCHOLARSHIP = 'beca',
  FINANCIAL_EXPENSE = 'gasto financiero',
}
