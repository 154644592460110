import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ExchangeRateModel } from '@core/models';
import { ExchangeRateService } from '@core/services/admin';
import { CurrencyCodeEnum } from '@rcsa-frontend/app/features/user/pages/reminder/reminder-contants';
import { BehaviorSubject } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-exchange-rate',
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.css'],
})
export class ExchangeRateComponent implements OnInit {
  @Input() amount: number;
  @Input() from = CurrencyCodeEnum.USD;
  @Input() to = CurrencyCodeEnum.BITCOIN;

  public exchangeRate$: BehaviorSubject<ExchangeRateModel> =
    new BehaviorSubject({} as ExchangeRateModel);

  constructor(public exchangeRateService: ExchangeRateService) {}
  public ngOnInit(): void {
    this.exchangeRate();
  }

  private exchangeRate(): void {
    this.exchangeRateService
      .exchangeRate({ amount: this.amount, from: this.from, to: this.to })
      .subscribe((result) => {
        this.exchangeRate$.next(result);
      });
  }
}
