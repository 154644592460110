<kendo-breadcrumb
  collapseMode="auto"
  [items]="(layoutState.breadcrumbs$ | async) || []"
  (itemClick)="onItemClick($event)"
>
  <ng-template kendoBreadCrumbItemTemplate let-item>
    <div class="breadcrumb-item" role="button">
      {{ item.text }}
    </div>
  </ng-template>
</kendo-breadcrumb>
<div #breadcrumbsDetail></div>
