import { Injectable } from '@angular/core';
import { StateModel } from '@core/models';
import { environment } from '@env/environment';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class StatesService extends Api<StateModel> {
  override endpoint: string;

  constructor(protected override readonly apiService: ApiService<StateModel>) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/states`;
  }
}
