import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Align } from '@progress/kendo-angular-popup';
import { GridColumnSettings } from './models/grid-column-setting';

@Component({
  selector: 'app-grid-column-settings',
  templateUrl: './grid-column-settings.component.html',
  styleUrls: ['./grid-column-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridColumnSettingsComponent {
  @Input() columns: Array<GridColumnSettings> = [];
  @Input() titleButton: string;
  @Input() showSearchColumn = false;
  @Output() columnsHidden: EventEmitter<Array<string>> = new EventEmitter();
  @Output() hideColumnSearch: EventEmitter<boolean> = new EventEmitter();
  public show = false;
  public existsHideColumns = false;
  public columnsSettings: Array<GridColumnSettings> = [];
  public anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  public popupAlign: Align = { horizontal: 'right', vertical: 'top' };

  public onToggle(): void {
    this.show = !this.show;
  }

  onChange($event: any, item: GridColumnSettings, index: number) {
    this.columns[index].isSelected = $event.target.checked;
  }

  onHideColumnSearch() {
    this.showSearchColumn = !this.showSearchColumn;
    this.hideColumnSearch.emit(this.showSearchColumn);
  }
  public onSetDefaulValues(): void {
    for (const item of this.columns) {
      item.isSelected = true;
    }
  }

  public onApply(): void {
    const hideColumns = this.columns
      .filter((item) => !item.isSelected)
      .map((item) => item.label);
    this.existsHideColumns = hideColumns.length > 0;
    this.columnsHidden.emit(hideColumns);
    this.closePopup();
  }

  public closePopup(): void {
    this.show = false;
  }
}
