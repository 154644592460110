import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CollectionResponse } from '@core/models';
import { CycleModel } from '@core/models/cycle.model';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CyclesService extends Api<CycleModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<CycleModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/cycles`;
  }

  public getCyclesBySchool(schoolId: string): Observable<CycleModel[]> {
    const url = `${environment.apiUrl}/v1/admin/configuration/cycles/byschool/${schoolId}`;
    return this.http.get<CollectionResponse<CycleModel>>(url).pipe(
      map((respose) => {
        return respose.data.items;
      })
    );
  }
}
