import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { FamilyLogModel } from '@core/models';
import { FamilyLogService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<FamilyLogModel>>({
  name: 'familyLog',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class FamilyLogState extends GenericState<FamilyLogModel> {
  override service: FamilyLogService;

  constructor(private familyService: FamilyLogService) {
    super();
    this.service = familyService;
  }

  public buildStringEndpoint(familyId: string): void {
    this.familyService.buildStringEndpoint(familyId);
  }
}
