import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import {  PaymentOrdersReportModel } from '@core/models';
import { PaymentOrdersReportService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<PaymentOrdersReportModel>>({
  name: 'paymentOrdersReport',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class PaymentOrdersReportState extends GenericState<PaymentOrdersReportModel> {
  override service: PaymentOrdersReportService;
  constructor(
    paymentOrders: PaymentOrdersReportService
  ) {
    super();
    this.service = paymentOrders;
  }
}
