import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  FinancialEntrySummaryModel,
  LedgerAccountSummaryModel,
  PaginationModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FinancialEntrySummaryService extends Api<FinancialEntrySummaryModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<FinancialEntrySummaryModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/accounting/financial-entries/summary`;
  }

  public getFinancialEntrySummary(
    params: PaginationModel
  ): Observable<FinancialEntrySummaryModel> {
    const url = `${
      environment.apiUrl
    }/v1/admin/accounting/financial-entries/summary?${new URLSearchParams(
      params
    ).toString()}`;
    this.endpoint = url;
    return this.get();
  }

  public getLedgerAccountsSummary(
    params: PaginationModel
  ): Observable<LedgerAccountSummaryModel[]> {
    const url = `${
      environment.apiUrl
    }/v1/admin/accounting/financial-entries/summary/ledger-accounts?${new URLSearchParams(
      params
    ).toString()}`;
    return this.http
      .get<CollectionResponse<LedgerAccountSummaryModel>>(url)
      .pipe(
        map((result) => {
          return result.data.items;
        })
      );
  }
}
