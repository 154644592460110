import { HttpMethodsEnum } from '@rcsa-frontend/fake-backend/enums';
import { FakeApisModel } from '@rcsa-frontend/fake-backend/models';
import { ledgerSeatData } from '../fake-data';
import { adminReports } from '../fake-data/admin-report/admin-report.fake';
import { businessFake } from '../fake-data/business/business.fake';
import { fiscalYearsListFake } from '../fake-data/catalogs/fiscal-years.fake';
import { countriesFake } from '../fake-data/countries/countries.fake';
import {
  externalCollections,
  externalInvoices,
  externalPayments,
} from '../fake-data/external/external.fake';
import { pastDueReportFake } from '../fake-data/past-due-notice/past-due-notice.fake';
import { paymentsFake } from '../fake-data/payment-history/payment-history.fake';
import { paymentMethodsFake } from '../fake-data/payment-methods/payment-methods.fake';
import { generalIcomeConcentrationListFake } from '../fake-data/reports/general-income-concentration-list-fake';
import { statesFake } from '../fake-data/states/states.fake';
import { scholarhipsFake } from '../fake-data/student-scholarships/student-scholarships.fake';

export const fakeApisConstant: FakeApisModel[] = [
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
      HttpMethodsEnum.POST,
      HttpMethodsEnum.PUT,
    ],
    regex: /\/v1\/admin\/security\/roles\/([A-Za-z0-9-]+)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          object: 'string',
          id: '9ef73212-b2e6-483b-a093-13c3d325287c',
          name: 'root',
          description: null,
          created_at: '2023-06-28 04:04:06',
          updated_at: '2023-06-28 04:04:06',
          is_admin: true,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
      HttpMethodsEnum.POST,
      HttpMethodsEnum.PUT,
    ],
    regex: /\/v1\/admin\/configuration\/business(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: businessFake,
          total: 17,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
    ],
    regex: /\/v1\/account\/payment-methods(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: paymentMethodsFake,
          total: 17,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
      HttpMethodsEnum.POST,
      HttpMethodsEnum.PUT,
    ],
    regex: /\/v1\/admin\/student\/scholarship(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: scholarhipsFake,
          total: 4,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
      HttpMethodsEnum.POST,
      HttpMethodsEnum.PUT,
    ],
    regex: /\/v1\/admin\/discounts\/discounts(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: [],
          total: 0,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
      HttpMethodsEnum.POST,
      HttpMethodsEnum.PUT,
    ],
    regex: /\/v1\/admin\/discounts\/rules(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: [],
          total: 0,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [],
    regex: /\/v1\/admin\/auth\/login/gim,
  },
  {
    enabled: true,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
      HttpMethodsEnum.POST,
      HttpMethodsEnum.PUT,
    ],
    regex: /\/v1\/admin\/configuration\/states(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: statesFake,
          total: statesFake.length,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
      HttpMethodsEnum.POST,
      HttpMethodsEnum.PUT,
    ],
    regex: /\/v1\/payments\/history(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: paymentsFake,
          total: paymentsFake.length,
        },
      },
    },
  },
  {
    enabled: true,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
      HttpMethodsEnum.POST,
      HttpMethodsEnum.PUT,
    ],
    regex: /\/v1\/admin\/accounting\/ledger-seats(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: ledgerSeatData,
          total: ledgerSeatData.length,
        },
      },
    },
  },
  {
    enabled: true,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
      HttpMethodsEnum.POST,
      HttpMethodsEnum.PUT,
    ],
    regex: /\/v1\/admin\/configuration\/countries(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: countriesFake,
          total: countriesFake.length,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
      HttpMethodsEnum.POST,
      HttpMethodsEnum.PUT,
    ],
    regex: /\/v1\/admin\/reports\/generate-expired-wallet(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: pastDueReportFake,
          total: pastDueReportFake.length,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
    ],

    regex:
      /\/v1\/admin\/reports\/fake\/collection-notices-concentrate(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: generalIcomeConcentrationListFake,
          total: generalIcomeConcentrationListFake.length,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
    ],
    regex:
      /\/v1\/admin\/reports\/fake-year\/collection-notices-concentrate\/fiscal-years(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: fiscalYearsListFake,
          total: fiscalYearsListFake.length,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
    ],

    regex: /\/v1\/admin\/external\/collections(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: externalCollections,
          total: externalCollections.length,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
    ],
    regex: /\/v1\/admin\/external\/invoices(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: externalInvoices,
          total: externalInvoices.length,
        },
      },
    },
  },
  {
    enabled: false,
    methods: [
      HttpMethodsEnum.DELETE,
      HttpMethodsEnum.GET,
      HttpMethodsEnum.PATCH,
    ],

    regex: /\/v1\/admin\/external\/payments(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: {
          items: externalPayments,
          total: externalPayments.length,
        },
      },
    },
  },
  {
    enabled: true,
    methods: [HttpMethodsEnum.GET],

    regex: /\/v1\/admin\/reports\/dynamic-dashboards\/detail(?=\/|$)/gim,
    data: {
      get: {
        status: true,
        message: null,
        data: adminReports.data,
      },
    },
  },
];
