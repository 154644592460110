import {
  Computed,
  DataAction,
  Payload,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { DEFAULT_LANGUAGE } from '@core/constants';
import { ExtendedBreadCrumb } from '@core/models';
import { NgxsOnInit, State } from '@ngxs/store';
import { values } from 'lodash';
import { Observable, map } from 'rxjs';

export class LayoutStateModel {
  public screenWidth!: number;
  public sidenavOpen!: boolean;
  public sidenavExpandedItems!: number[];
  public breadcrumbs: ExtendedBreadCrumb[];
  public language: string;
}

const defaults = {
  screenWidth: 1000,
  sidenavOpen: true,
  sidenavExpandedItems: [],
  breadcrumbs: [],
  language: DEFAULT_LANGUAGE,
};

@Persistence()
@StateRepository()
@State<LayoutStateModel>({
  name: 'layout',
  defaults,
})
@Injectable()
export class LayoutState
  extends NgxsDataRepository<LayoutStateModel>
  implements NgxsOnInit
{
  @Computed()
  public get breadcrumbs$(): Observable<ExtendedBreadCrumb[]> {
    return this.state$.pipe(
      map((layoutStateModel) => {
        return values(layoutStateModel.breadcrumbs);
      })
    );
  }

  @Computed()
  public get isMobile(): boolean {
    return this.snapshot.screenWidth < 992;
  }

  @Computed()
  public get isSidenavOpen(): boolean {
    return this.snapshot.sidenavOpen;
  }

  @Computed()
  public get isSidenavOpen$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.sidenavOpen));
  }

  @Computed()
  public get sidenavExpandedItems(): number[] {
    return this.snapshot.sidenavExpandedItems;
  }

  @Computed()
  public get language(): string {
    return this.snapshot.language;
  }

  @DataAction()
  public setBreadcrumbs(
    @Payload('breadcrumbs') breadcrumbs: ExtendedBreadCrumb[]
  ): void {
    this.ctx.setState((state) => ({
      ...state,
      breadcrumbs,
    }));
  }

  @DataAction()
  public setScreenWidth(@Payload('screenWidth') screenWidth: number): void {
    this.ctx.setState((state) => ({
      ...state,
      screenWidth,
    }));
  }

  @DataAction()
  public setSidenavExpandedItems(
    @Payload('sidenavExpandedItems') sidenavExpandedItems: number[]
  ): void {
    this.ctx.setState((state) => ({
      ...state,
      sidenavExpandedItems,
    }));
  }

  @DataAction()
  public toggleSidenav(): void {
    this.ctx.setState((state) => ({
      ...state,
      sidenavOpen: !state.sidenavOpen,
    }));
  }

  @DataAction()
  public setLanguage(@Payload('language') language: string): void {
    this.ctx.setState((state) => ({
      ...state,
      language,
    }));
  }
}
