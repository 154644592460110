import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentsCardRespondeModel, TPVPaymentModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TpvService extends Api<TPVPaymentModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<TPVPaymentModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/payments/tpv`;
  }

  public multiplePayment(
    data: Partial<TPVPaymentModel>
  ): Observable<PaymentsCardRespondeModel> {
    const endpoint = `${this.endpoint}/multiple`;
    const params = JSON.stringify(data);
    return this.http.post<PaymentsCardRespondeModel>(endpoint, params);
  }

  public reassignPayment(
    data: Partial<TPVPaymentModel>
  ): Observable<PaymentsCardRespondeModel> {
    const endpoint = `${this.endpoint}/reassign_payment_to_cn`;
    const params = JSON.stringify(data);
    return this.http.post<PaymentsCardRespondeModel>(endpoint, params);
  }
}
