import {
  Computed,
  DataAction,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { PaymentHistoryModel } from '@core/models';
import { PaymentHistoryService } from '@core/services';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Observable, first, map, tap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<PaymentHistoryModel>>({
  name: 'paymentHistory',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class PaymentHistoryState extends GenericState<PaymentHistoryModel> {
  override service: PaymentHistoryService;
  constructor(private paymentHistoryService: PaymentHistoryService) {
    super();
    this.service = paymentHistoryService;
  }

  @Computed()
  public get sortValue(): SortDescriptor | undefined {
    return this.snapshot.sort;
  }

  @DataAction()
  public saveSortValue(sortValue?: SortDescriptor): void {
    this.patchState({
      sort: sortValue,
    });
  }

  public getPaymentById(id: string): Observable<PaymentHistoryModel> {
    const snapshotItem = this.snapshot.items[id] as
      | PaymentHistoryModel
      | undefined;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!snapshotItem || !snapshotItem.collectionNotice.guardian) {
      return this.paymentHistoryService.find(id).pipe(
        tap((payment) => {
          this.patchState({
            items: { ...this.snapshot.items, [payment.id]: payment },
          });
        })
      );
    }
    return this.state$.pipe(
      first((paymentHistoryState) => {
        const payment = paymentHistoryState.items[id] as
          | PaymentHistoryModel
          | undefined;
        return !!(payment && payment.collectionNotice.guardian);
      }),
      map((paymentHistoryState) => {
        return paymentHistoryState.items[id];
      })
    );
  }
}
