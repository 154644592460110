import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LOGOUT_EXCLUDED_UNAUTHORIZED_ENDPOINTS } from '@core/constants';
import { AuthService } from '@core/services';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (httpError: any) => {
          if (httpError instanceof HttpErrorResponse) {
            const url = httpError.url || '';
            if (
              this.isApiCall(url) &&
              httpError.status === 401 &&
              this.unauthorizedEndpointHasToLogout(url)
            ) {
              this.authService.logout();
            }
          }
        },
      })
    );
  }

  private isApiCall(url: string): boolean {
    return url.includes(environment.apiUrl);
  }

  private unauthorizedEndpointHasToLogout(url: string) {
    return !LOGOUT_EXCLUDED_UNAUTHORIZED_ENDPOINTS.some((endpoint) =>
      url.includes(endpoint)
    );
  }
}
