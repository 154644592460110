import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { StudentScholarshipModel } from '@core/models';
import { StudentScholarshipService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<StudentScholarshipModel>>({
  name: 'studentScholarships',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class StudentScholarshipState extends GenericState<StudentScholarshipModel> {
  override service: StudentScholarshipService;
  constructor(private studentScholarshipService: StudentScholarshipService) {
    super();
    this.service = studentScholarshipService;
  }
}
