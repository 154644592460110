import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParameterModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ParametersService extends Api<ParameterModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<ParameterModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/parameters`;
  }

  public getParameterBySchool(
    schoolId: string,
    code: string
  ): Observable<ParameterModel> {
    const url = `${this.endpoint}/values/${schoolId}?code=${code}`;
    return this.http.get(url).pipe(
      map((result: any) => {
        return result.data.items[0];
      })
    );
  }
}
