import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FeatureFlagAccessModel,
  FeatureFlagCodes,
  PaymentMethodModel,
  SingleItemResponse,
  TokenizePaymentMethodModel,
  TokenizedCardResponseModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@rcsa-frontend/environments/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExternalPaymentMethodsService extends Api<PaymentMethodModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<PaymentMethodModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/external/account/payment-methods`;
  }

  public createPaymentMethod(data: any): Observable<PaymentMethodModel> {
    return this.http
      .post<SingleItemResponse<PaymentMethodModel>>(this.endpoint, data)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  public tokenizePayment(
    data: TokenizePaymentMethodModel
  ): Observable<TokenizedCardResponseModel> {
    const endpoint = `${this.endpoint}/tokenize`;
    return this.http
      .post<SingleItemResponse<TokenizedCardResponseModel>>(endpoint, data)
      .pipe(
        map((result) => {
          return result.data;
        })
      );
  }

  public paymentMethods(email: string): Observable<PaymentMethodModel[]> {
    return this.getAll({ email }).pipe(
      map((result) => {
        return result.data.items;
      })
    );
  }

  public checkFeatureFlagStatus(
    code: FeatureFlagCodes,
    schoolIds: string
  ): Observable<FeatureFlagAccessModel> {
    const url = `${environment.apiUrl}/v1/external/account/feature-flags-access/status/${code}/${schoolIds}`;
    return this.http.get<SingleItemResponse<FeatureFlagAccessModel>>(url).pipe(
      map((respose) => {
        return respose.data;
      })
    );
  }
}
