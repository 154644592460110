import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExchangeRateModel, SingleItemResponse } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExchangeRateService extends Api<ExchangeRateModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<ExchangeRateModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/payments/exchange-rate`;
  }

  public exchangeRate(data: ExchangeRateModel): Observable<ExchangeRateModel> {
    const endpoint = `${this.endpoint}${`?${new URLSearchParams(
      data as any
    ).toString()}`}`;
    return this.http.get<SingleItemResponse<ExchangeRateModel>>(endpoint).pipe(
      map((result) => {
        return result.data;
      })
    );
  }
}
