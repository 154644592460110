import { Injectable } from '@angular/core';
import { NotificationModel } from '@core/models';
import { LayoutState } from '@core/state/shared';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  NotificationService as NS,
  NotificationRef,
} from '@progress/kendo-angular-notification';
import { NotificationComponent } from '@shared/components';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notifications: NotificationRef[] = [];

  constructor(
    private notificationService: NS,
    private dialogService: DialogService,
    private layoutState: LayoutState
  ) {}

  public displayError(message: string): void {
    this.displayAlert(message, 'error');
  }

  public notify(notify: NotificationModel): void {
    const dialogRef = this.dialogService.open({
      title: notify.title,
      content: NotificationComponent,
      width: this.layoutState.isMobile ? '90%' : '35%',
      height: 'auto',
      cssClass: 'confirm-dialog',
    });

    const notificationComponent = dialogRef.content
      .instance as NotificationComponent;
    notificationComponent.notifySettings = notify;

    dialogRef.result.pipe(take(1)).subscribe((result: any) => {
      notify.afterClose ? notify.afterClose({ action: result.text }) : '';
    });
  }

  public displayAlert(
    message: string,
    style: 'none' | 'success' | 'warning' | 'error' | 'info',
    closable = true,
    hideAfter = 3000
  ): void {
    const notificationInstance = this.notificationService.show({
      content: message,
      animation: {
        type: 'slide',
        duration: 300,
      },
      position: {
        horizontal: 'right',
        vertical: 'bottom',
      },
      type: {
        style: style,
        icon: true,
      },
      closable,
      hideAfter,
    });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!notificationInstance) {
      return;
    }
    this.notifications.push(notificationInstance);
    setTimeout(() => {
      notificationInstance.hide();
      this.notifications = this.notifications.filter(
        (instance) => instance !== notificationInstance
      );
    }, hideAfter);
  }
}
