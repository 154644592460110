import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { BalancePaymentNoticeModel } from '@core/models/reports/balance-payment-notice.model';
import { BalancePaymentNoticeService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<BalancePaymentNoticeModel>>({
  name: 'balancePaymentNotice',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class BalancePaymentNoticeState extends GenericState<BalancePaymentNoticeModel> {
  override service: BalancePaymentNoticeService;
  constructor(balancePayments: BalancePaymentNoticeService) {
    super();
    this.service = balancePayments;
  }
}
