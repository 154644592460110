import { ChargebacksState } from './chargebacks/chargebacks.state';
import { PaymentAttemptsState } from './payment-attempts/payment-attempts.state';
import { PaymentFeesState } from './payment-fees/payment-fees.state';
import { PaymentHistoryReportState } from './payment-history-report/payment-history-report.state';
import { PaymentIssuesState } from './payment-issues/payment-issues.state';
import { PaymentsInValidationState } from './payments-in-validation/payments-in-validation.state';

export {
  ChargebacksState,
  PaymentAttemptsState,
  PaymentFeesState,
  PaymentHistoryReportState,
  PaymentIssuesState,
  PaymentsInValidationState,
};

export const PaymentsState = [
  ChargebacksState,
  PaymentIssuesState,
  PaymentHistoryReportState,
  PaymentAttemptsState,
  PaymentFeesState,
  PaymentsInValidationState,
];
