import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { CollectionNoticeModel } from '@core/models';
import { CollectionNoticeService } from '@core/services';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { Observable, first, map, tap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<CollectionNoticeModel>>({
  name: 'collectionNotice',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class CollectionNoticeState extends GenericState<CollectionNoticeModel> {
  override service: CollectionNoticeService;
  constructor(private collectionNoticeService: CollectionNoticeService) {
    super();
    this.service = collectionNoticeService;
  }

  public getCollectionNoticeById(
    id: string
  ): Observable<CollectionNoticeModel> {
    if (!(this.snapshot.items[id] as CollectionNoticeModel | undefined)) {
      return this.collectionNoticeService.find(id).pipe(
        tap((collectionNotice) => {
          this.patchState({
            items: {
              ...this.snapshot.items,
              [collectionNotice.id]: collectionNotice,
            },
          });
        })
      );
    }
    return this.state$.pipe(
      first(
        (state) => !!(state.items[id] as CollectionNoticeModel | undefined)
      ),
      map((collectionNoticeState) => {
        return collectionNoticeState.items[id];
      })
    );
  }
}
