import { CollectionNoticeStatus, NotificationType } from '@core/models';
import { environment } from '@env/environment';

export const ALLOWED_CONCEPTS_MSI: AllowedConceptKey[] =
  environment.allowedConceptsMSI;

export const CONCEPTS_WITH_INTEREST: string[] = [
  '1b485fe3-d80f-55ca-8c14-c166fcd613a5', // Colegiatura Mensual Bachillerato
  '358352b3-d32e-55e2-b339-ae0c182fe76b', // Colegiatura Anual 2 exhibiciones
  '73d3de91-79e1-5f58-818e-bfaaa68c5706', // Colegiatura Mensual
  '7adbe84e-f9e8-520e-9a33-7d6da2e122d5', // Colegiatura Anual 1 exhibición
];

export const ALLOWED_CATEGORY_CONCEPTS_TO_APPLY_REVERSE: AllowedConceptKey[] = [
  { IN: 'ee73edaf-45d2-516a-adb3-b4efeb8b66fa' },
  { RI: '967a7d9c-0d62-5a39-a139-12fc95b43a52' },
];

export const ALLOWED_CATEGORY_CONCEPTS_TO_APPLY_REFUND_REASON: AllowedConceptKey[] =
  [
    { IN: 'ee73edaf-45d2-516a-adb3-b4efeb8b66fa' },
    { RI: '967a7d9c-0d62-5a39-a139-12fc95b43a52' },
  ];

export const CATEGORY_CONCEPTS_TO_APPLY_SPLIT: AllowedConceptKey[] = [
  { RI: '967a7d9c-0d62-5a39-a139-12fc95b43a52' },
  { IN: 'ee73edaf-45d2-516a-adb3-b4efeb8b66fa' },
];
export const ALLOWED_BANKS_MSI = [
  'banco bilbao vizcaya argentaria s.a. (bbva)',
  'banco bilbao vizcaya argentaria, s.a.',
  'bbva',
  'bbva bancomer',
  'citibanamex',
  'banamex',
  'citi banamex',
  'bbva bancomer s.a.',
];

export const REQUIRE_CVV_BANKS = [
  'banco bilbao vizcaya argentaria s.a. (bbva)',
  'banco bilbao vizcaya argentaria, s.a.',
  'bbva',
  'bbva bancomer',
  'bbva bancomer s.a.',
];

export interface AllowedConceptKey {
  [name: string]: string;
}

export const COLLECTION_CASHIER_REJECTED_STATUS: CollectionNoticeCashierSatatus =
  {
    0: 'Sin Rechazo',
    1: 'Sí',
    2: 'Pendiente',
    3: 'Completado',
    4: 'Resuelto',
  };

export const CASHIER_REJECTED_STATUS_CLASS: CollectionNoticeCashierSatatus = {
  0: 'default',
  1: 'default',
  2: 'danger',
  3: 'success',
  4: 'info',
};

export interface CollectionNoticeCashierSatatus {
  [name: number]: string;
}

export const COLLECTION_CORE_SELECT_ITEMS = [
  { id: '73d3de91-79e1-5f58-818e-bfaaa68c5706', name: 'Colegiatura Mensual' },
  {
    id: '1b485fe3-d80f-55ca-8c14-c166fcd613a5',
    name: 'Colegiatura Mensual Bachillerato',
  },
  {
    id: '7adbe84e-f9e8-520e-9a33-7d6da2e122d5',
    name: 'Colegiatura Anual 1 exhibición',
  },
  {
    id: '358352b3-d32e-55e2-b339-ae0c182fe76b',
    name: 'Colegiatura Anual 2 exhibiciones',
  },
  { id: 'ee73edaf-45d2-516a-adb3-b4efeb8b66fa', name: 'Inscripción' },
  { id: '967a7d9c-0d62-5a39-a139-12fc95b43a52', name: 'Reinscripción' },
];

export const COLLECTION_NOTICE_CASHIERS_STATUS = {
  DEFAULT: 0,
  REJECTED: 2,
  APPROVED: 3,
};

export const ADMINISTRATIVE_RULES_FREQUENCY = new Map([
  ['monthly', 'Mensual'],
  ['semester', '2 exhibiciones'],
  ['annual', '1 exhibición'],
]);

export const PAYABLE_STATUS = [
  CollectionNoticeStatus.PENDING,
  CollectionNoticeStatus.EXPIRED,
];

export const COLLECTION_NOTICE_STATUS_ALERT_TYPES: Map<string, string> =
  new Map([
    [CollectionNoticeStatus.PAID, NotificationType.success],
    [CollectionNoticeStatus.BILLED, NotificationType.success],
    [CollectionNoticeStatus.PUBLIC_BILLED, NotificationType.success],
    [CollectionNoticeStatus.PENDING, NotificationType.process],
    [CollectionNoticeStatus.REFUND, NotificationType.error],
    [CollectionNoticeStatus.REJECTED, NotificationType.error],
    [CollectionNoticeStatus.CANCELLED, NotificationType.warning],
  ]);
