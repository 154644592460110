import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ADMIN_FRONTEND_PERMISSIONS } from '@core/constants';
import { getUpdatePermission } from '@core/functions';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-check-include-events',
  templateUrl: './check-include-events.component.html',
  styleUrls: ['./check-include-events.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckIncludeEventsComponent,
      multi: true,
    },
  ],
})
export class CheckIncludeEventsComponent implements ControlValueAccessor {
  @Input() label = 'Incluir Eventos';
  @Input() permission = getUpdatePermission(
    ADMIN_FRONTEND_PERMISSIONS.payments.includeEvents
  );
  @Output()
  public valueChange = new EventEmitter();
  public checked = false;
  public onTouched?: () => void;
  public onChange?: (event: boolean) => void;

  public onValueChange(event: Event) {
    const value = (event.target as HTMLInputElement).checked;
    if (this.onChange) {
      this.onChange(value);
    }
    this.valueChange.emit(value);
  }

  public registerOnChange(fn: (value: boolean | undefined) => void): void {
    this.onChange = fn;
  }

  public writeValue(checked: boolean): void {
    this.checked = checked;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
