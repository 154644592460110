import { Injectable } from '@angular/core';
import { RequestAuthSuperset } from '@core/models/superset/request-auth-superset.model';
import { SessionSupersetModel } from '@core/models/superset/session-superset.model';

import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthSupersetService extends Api<SessionSupersetModel> {
  override endpoint: string;

  constructor(apiService: ApiService<SessionSupersetModel>) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/auth/superset-jwt`;
  }

  public authSuperset(
    payload: RequestAuthSuperset
  ): Observable<SessionSupersetModel> {
    return this.post(payload);
  }
}
