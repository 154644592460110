export function getEditDaysPassed(updatedDate: string): string {
  if (!updatedDate) {
    return '';
  }
  const dateObject = new Date(updatedDate);
  const today = new Date();
  const difference = today.getTime() - dateObject.getTime();
  const differenceInDays = Math.round(difference / (1000 * 3600 * 24));
  let daysPassedString = '';
  switch (differenceInDays) {
    case 0:
      daysPassedString = 'Editado hoy';
      break;
    case 1:
      daysPassedString = 'Editado hace 1 día';
      break;
    default:
      daysPassedString = `Editado hace ${differenceInDays} días`;
      break;
  }

  return daysPassedString;
}
