import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  CommonFilters,
  PaginationModel,
  UserModel,
} from '@core/models';
import { UsersService } from '@core/services';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable, finalize, map, tap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<UserModel>>({
  name: 'user',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class UsersState extends GenericState<UserModel> {
  override service: UsersService;
  constructor(private usersService: UsersService) {
    super();
    this.service = usersService;
  }

  @Computed()
  public override get itemsByPageGrid$(): Observable<GridDataResult | null> {
    return this.state$.pipe(
      map((stateModel) => {
        if (!stateModel.pages[this.currentPage]) {
          return null;
        }
        const idsByPage = stateModel.pages[this.currentPage] || [];
        const data = idsByPage.map((id: string) => {
          return stateModel.items[id];
        });
        return <GridDataResult>{
          data: data,
          total: stateModel.totalItems,
        };
      })
    );
  }

  public deleteUser(id: string): Observable<CollectionResponse<UserModel>> {
    return this.usersService.delete(id);
  }

  public getUsersByPage(
    page = 1,
    filters: CommonFilters,
    force = false,
    params?: PaginationModel
  ): Observable<UserModel[]> {
    if (this.isPageLoaded(page) && !force) {
      this.patchState({
        currentPage: page,
      });
      return this.itemsByPage$;
    }
    this.toggleLoading();
    if (force) {
      this.patchState({
        pages: {},
      });
    }
    return this.usersService
      .getAll({
        is_admin: false,
        ...filters,
        page,
        limit: this.pageLimit,
        ...params,
      })
      .pipe(
        finalize(() => {
          this.patchState({
            isLoading: false,
          });
        }),
        tap((content): void => {
          const currentState = this.getState();
          const { items: users } = content.data;
          const objectToPatch: Partial<GenericStateModel<UserModel>> = {
            totalItems: content.data.total,
            currentPage: page,
            items: {
              ...currentState.items,
              ...this.formatMultiStoreData(users),
            },
            pages: {
              ...currentState.pages,
              [page]: users.map((user) => this.getStateId(user)),
            },
            isLoading: false,
          };
          this.patchState(objectToPatch);
        }),
        map((content) => {
          return content.data.items;
        })
      );
  }

  public toggleIsImpersonate(user: UserModel) {
    this.patchState({
      items: {
        ...this.snapshot.items,
        [this.getStateId(user)]: {
          ...user,
          isImpersonate: !user.isImpersonate,
        },
      },
    });
  }

  private formatMultiStoreData(items: UserModel[]): { [k: string]: UserModel } {
    const itemsFormatted: { [k: string]: UserModel } = {};
    items.forEach((item) => {
      itemsFormatted[this.getStateId(item)] = item;
    });
    return itemsFormatted;
  }

  private getStateId(user: UserModel) {
    return `${user.id}-${user.region.id}`;
  }
}
