import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  ConceptAssignmentModel,
  PaginationModel,
} from '@core/models';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ConceptsAssignmentService extends Api<ConceptAssignmentModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<ConceptAssignmentModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/schools/{school_id}/sub-concepts`;
  }
  public getAssignmentBySchool(
    schoolId: string,
    paginationData?: PaginationModel
  ): Observable<CollectionResponse<ConceptAssignmentModel>> {
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/schools/${schoolId}/sub-concepts`;
    return this.getAll(paginationData);
  }
  public create(
    data: ConceptAssignmentModel,
    schoolId: string
  ): Observable<ConceptAssignmentModel> {
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/schools/${schoolId}/sub-concepts`;
    return this.post(data);
  }
  public updateAssignment(
    data: ConceptAssignmentModel,
    schoolId: string
  ): Observable<ConceptAssignmentModel> {
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/schools/${schoolId}/sub-concepts`;
    return this.put(data);
  }
  public deleteAssignment(
    assignmentId: string,
    schoolId: string
  ): Observable<CollectionResponse<ConceptAssignmentModel>> {
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/schools/${schoolId}/sub-concepts`;
    return this.delete(assignmentId);
  }
  public export(params: PaginationModel, schoolId: string): Observable<Blob> {
    params.limit = 1000000;
    params.page = 1;
    this.endpoint = `${
      environment.apiUrl
    }/v1/admin/configuration/schools/${schoolId}/sub-concepts/export/excel${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get(this.endpoint, {
      responseType: 'blob',
    });
  }
  public findAssignment(
    subconceptId: string,
    schoolId: string
  ): Observable<ConceptAssignmentModel[]> {
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/schools/${schoolId}/sub-concepts`;
    return this.getAll({ sub_concept_id: subconceptId }).pipe(
      map((result) => {
        return result.data.items;
      })
    );
  }
}
