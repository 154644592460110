import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PERMISSIONS_MAP } from '@core/constants';
import { getLeafPermission, getUpdatePermission } from '@core/functions';
import { AuthorizationState } from '@core/state/shared';
import { PermissionsService } from '../permissions/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(
    private authorizationState: AuthorizationState,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute
  ) {}

  public loadPermissions(idRole: string) {
    this.permissionsService
      .getPermissionByRole(idRole)
      .subscribe((permissionsResponse) => {
        this.authorizationState.addPermissions(
          this.permissionsService.createPermissionsHash(
            permissionsResponse.data.items
          )
        );
      });
  }

  public hasPermission(permission: string) {
    const [permissionBase, permissionAction] = permission.split('.');
    let isAuthorized = true;
    if (permissionAction === 'update') {
      isAuthorized =
        PERMISSIONS_MAP[permissionBase].update.every((backendPermission) =>
          this.authorizationState.hasPermission(backendPermission)
        ) && isAuthorized;
    }
    if (permissionAction === 'list' || permissionAction === 'update') {
      isAuthorized =
        PERMISSIONS_MAP[permissionBase].list.every((backendPermission) =>
          this.authorizationState.hasPermission(backendPermission)
        ) && isAuthorized;
    }
    return isAuthorized;
  }

  public hasUpdatePermission() {
    const permission = getLeafPermission(this.route.snapshot),
      [permissionBase] = permission.split('.');

    return this.hasPermission(getUpdatePermission(permissionBase));
  }
}
