import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  PaginationModel,
  RejectionReason,
  ScholarshipFile,
  ScholarshipFileDetail,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScholarshipsFilesService extends Api<ScholarshipFile> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<ScholarshipFile>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/api/v1/admin-v2/scholarships/uploaded_files`;
  }

  public getUploadDetail(
    uploadId: string,
    params: PaginationModel
  ): Observable<CollectionResponse<ScholarshipFileDetail>> {
    const url = `${this.endpoint}/${uploadId}/detail${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get<CollectionResponse<ScholarshipFileDetail>>(url);
  }

  public updateScholarshipStatus(data: any): Observable<any> {
    const endpoint = `${environment.apiUrl}/api/v1/admin-v2/scholarships/approve_reject`;
    return this.http.post<any>(`${endpoint}`, data);
  }

  public getRejectionReasons(): Observable<RejectionReason[]> {
    const url = `${environment.apiUrl}/api/v1/admin-v2/scholarships/reason_for_rejected_scholarship_list`;
    return this.http.get<CollectionResponse<RejectionReason>>(url).pipe(
      map((res) => {
        return res.data.items;
      })
    );
  }
}
