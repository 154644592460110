import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isUnloggedGuard } from '@core/guards';
import { ExternalLayoutComponent } from '@widgets/apps-layout/components';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import(
        '@rcsa-frontend/app/features/authentication/authentication.module'
      ).then((m) => m.AuthenticationModule),
    canActivate: [isUnloggedGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import(
        '@rcsa-frontend/app/features/authentication/authentication.module'
      ).then((m) => m.AuthenticationModule),
    canActivate: [isUnloggedGuard],
  },
  {
    path: 'admin/app',
    loadChildren: () =>
      import('./features/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'admin/soporte',
    loadChildren: () =>
      import('@rcsa-frontend/app/widgets/support/support.module').then(
        (m) => m.SupportModule
      ),
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./features/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'soporte',
    loadChildren: () =>
      import('@rcsa-frontend/app/widgets/support/support.module').then(
        (m) => m.SupportModule
      ),
  },
  {
    path: 'externos',
    component: ExternalLayoutComponent,
    loadChildren: () =>
      import('./features/external/external.module').then(
        (m) => m.ExternalModule
      ),
  },
  { path: '**', redirectTo: '' },
];

const config = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
