import {
  DataAction,
  Payload,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';

export class GlobalStateModel {
  public activeModel!: string;
}

const defaults = {
  activeModel: '',
};

@StateRepository()
@State<GlobalStateModel>({
  name: 'global',
  defaults,
})
@Injectable()
export class GlobalState extends NgxsDataRepository<GlobalStateModel> {
  @DataAction()
  public activateModel(@Payload('modalName') modalName: string): void {
    this.ctx.setState((state) => ({
      ...state,
      activeModel: modalName,
    }));
  }
}
