import { ApiModel } from './generic-state.model';

export interface FeatureFlagModel extends ApiModel {
  module: string;
  name: string;
  code: string;
}

export interface FeatureFlagAccessModel extends ApiModel {
  featureFlag: FeatureFlagModel;
  access: FlagAccessModel[];
}

export interface FlagAccessModel {
  id: string;
  parentId: string;
  name: string;
  status: string;
  accessType: string;
  maintenanceText?: string;
}

export enum FeatureFlagCodes {
  MSI = 'payments-msi',
  SPEI = 'payments-spei',
  BITCOIN = 'payments-bitcoin',
  STORE = 'payments-store',
  FRIENDSHIP_TOURNAMENT = 'events-friendship',
  TCTD = 'payments-tc-td',
  SPLIT_COLLECTION_NOTICE = 'split-collection-notice',
  ADMINISTRATIVE_RULES = 'administrative-rules',
  SHOW_GROUP_FIELD = 'show-group-field',
  RVOE = 'configurations-rvoe',
}
