import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  BaseFilterCellComponent,
  FilterService,
} from '@progress/kendo-angular-grid';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss'],
})
export class DropdownFilterComponent extends BaseFilterCellComponent {
  @Input() public override filter: CompositeFilterDescriptor;
  @Input() public data: unknown[];
  @Input() public textField: string;
  @Input() public valueField: string;
  @Input() public filterField: string;
  @Output() public valueChange: EventEmitter<any> = new EventEmitter();

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public get defaultItem(): { [Key: string]: unknown } {
    return {
      [this.textField]: 'Selecciona un elemento...',
      [this.valueField]: null,
    };
  }

  public get selectedValue(): unknown {
    const filter = this.filterByField(this.valueField);
    return typeof filter === 'undefined' ? null : filter.value;
  }
  public onChange(value: unknown): void {
    this.applyFilter(
      value === null
        ? this.removeFilter(this.valueField)
        : this.updateFilter({
            field: this.filterField || this.valueField,
            operator: 'eq',
            value: value,
          })
    );
    this.valueChange.emit(value);
  }
}
