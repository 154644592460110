import { PaymentMethodsModel } from '@core/models';

export const SYNCFY_STORES: { name: string }[] = [
  { name: '7 Eleven' },
  { name: 'Banorte' },
  { name: 'BBVA Bancomer' },
  { name: 'Bodega Aurrera' },
  { name: 'Caja Cerano' },
  { name: 'Cali Max' },
  { name: 'Círculo K y extra' },
  { name: 'City club' },
  { name: 'Diestel corporativos' },
  { name: 'Farmacia La más barata' },
  { name: 'Farmacia San Francisco de Asís' },
  { name: 'Farmacias Benavides' },
  { name: 'Farmacias del Ahorro' },
  { name: 'Farmacias Roma' },
  { name: 'Farmacias Unión' },
  { name: 'Farmacias YZA' },
  { name: 'Farmacias Zapotlán' },
  { name: 'Farmatodo' },
  { name: 'HSBC' },
  { name: 'Kiosko' },
  { name: 'Merza' },
  { name: 'Sams Club' },
  { name: 'Santander' },
  { name: 'SMB Rural' },
  { name: 'Soriana' },
  { name: 'Super del norte' },
  { name: 'Telecomm' },
  { name: 'Walmart express' },
  { name: 'Walmart' },
];

export const AMOUNT_ALLOWED_TO_ADJUST = 50;

export const PAYMENT_STATUS = [
  {
    label: 'Pendiente',
    value: 'Pendiente',
  },
  {
    label: 'Pagado',
    value: 'Pagado',
  },
  {
    label: 'Devolución Pendiente',
    value: 'Devolución Pendiente',
  },
  {
    label: 'Devuelto',
    value: 'Devuelto',
  },
  {
    label: 'Rechazado',
    value: 'Rechazado',
  },
  {
    label: 'Pendiente Por Contracargo',
    value: 'Pendiente Por Contracargo',
  },
];

export const REFUND_REASONS = {
  SURCHARGE_ACCOUNT: 'cuenta de recargos',
  TUITION_ADVANCE: 'anticipos colegiatura',
  TUITION_IN_RI: 'anticipos in ri',
};

export const ALLOWED_ITEMS_FOR_RECONCILIATION = ['referencia', 'spei'];
export const ALLOWED_STATUS_FOR_RECONCILIATION = ['rechazado'];

export const PAYMENT_METHODS: PaymentMethodsModel[] = [
  {
    name: 'SPEI',
    paymentType: 'SPEI',
  },
  {
    name: 'TC/TD',
    paymentType: 'TC/TD',
  },
  {
    name: 'Referencia',
    paymentType: 'Referencia',
  },
  {
    name: 'TPV',
    paymentType: 'TPV',
  },
];
