import { GeneralIncomeConcentrationReportModel } from '@core/models/reports/general-income-concentration.model';

export const generalIcomeConcentrationListFake: GeneralIncomeConcentrationReportModel[] =
  [
    {
      id: '1',
      category: 'Colegiatura',
      noteTotal: '1234.89',
      noteValidation: '9123.01',
      months: [
        {
          label: 'Enero 2023',
          amount: '200',
        },
        {
          label: 'Febrero 2023',
          amount: '2300',
        },
        {
          label: 'Marzo 2023',
          amount: '2050',
        },
        {
          label: 'Abril 2023',
          amount: '2050',
        },
        {
          label: 'Mayo 2023',
          amount: '2050',
        },
        {
          label: 'Junio 2023',
          amount: '2050',
        },
        {
          label: 'Julio 2023',
          amount: '2050',
        },
        {
          label: 'Agosto 2023',
          amount: '2050',
        },
        {
          label: 'Septiemre 2023',
          amount: '2050',
        },
        {
          label: 'Octubre 2023',
          amount: '2050',
        },
        {
          label: 'Noviembre 2023',
          amount: '2050',
        },
        {
          label: 'Diciembre 2023',
          amount: '2050',
        },
      ],
    },
    {
      id: '1',
      category: 'Inscripción',
      noteTotal: '100.89',
      noteValidation: '8923.99',
      months: [
        {
          label: 'Enero 2023',
          amount: '200',
        },
        {
          label: 'Febrero 2023',
          amount: '2300',
        },
        {
          label: 'Marzo 2023',
          amount: '2050',
        },
        {
          label: 'Abril 2023',
          amount: '2050',
        },
        {
          label: 'Mayo 2023',
          amount: '2050',
        },
        {
          label: 'Junio 2023',
          amount: '2050',
        },
        {
          label: 'Julio 2023',
          amount: '2050',
        },
        {
          label: 'Agosto 2023',
          amount: '2050',
        },
        {
          label: 'Septiemre 2023',
          amount: '2050',
        },
        {
          label: 'Octubre 2023',
          amount: '2050',
        },
        {
          label: 'Noviembre 2023',
          amount: '2050',
        },
        {
          label: 'Diciembre 2023',
          amount: '2050',
        },
      ],
    },
    {
      id: '3',
      category: 'Reinscripción',
      noteTotal: '6734.89',
      noteValidation: '99123.01',
      months: [
        {
          label: 'Enero 2023',
          amount: '200',
        },
        {
          label: 'Febrero 2023',
          amount: '2300',
        },
        {
          label: 'Marzo 2023',
          amount: '2050',
        },
        {
          label: 'Abril 2023',
          amount: '2050',
        },
        {
          label: 'Mayo 2023',
          amount: '2050',
        },
        {
          label: 'Junio 2023',
          amount: '2050',
        },
        {
          label: 'Julio 2023',
          amount: '2050',
        },
        {
          label: 'Agosto 2023',
          amount: '2050',
        },
        {
          label: 'Septiemre 2023',
          amount: '2050',
        },
        {
          label: 'Octubre 2023',
          amount: '2050',
        },
        {
          label: 'Noviembre 2023',
          amount: '2050',
        },
        {
          label: 'Diciembre 2023',
          amount: '2050',
        },
      ],
    },
    {
      id: '4',
      category: 'Academia',
      noteTotal: '1234.89',
      noteValidation: '9123.01',
      months: [
        {
          label: 'Enero 2023',
          amount: '200',
        },
        {
          label: 'Febrero 2023',
          amount: '2300',
        },
        {
          label: 'Marzo 2023',
          amount: '2050',
        },
        {
          label: 'Abril 2023',
          amount: '2050',
        },
        {
          label: 'Mayo 2023',
          amount: '2050',
        },
        {
          label: 'Junio 2023',
          amount: '2050',
        },
        {
          label: 'Julio 2023',
          amount: '2050',
        },
        {
          label: 'Agosto 2023',
          amount: '2050',
        },
        {
          label: 'Septiemre 2023',
          amount: '2050',
        },
        {
          label: 'Octubre 2023',
          amount: '2050',
        },
        {
          label: 'Noviembre 2023',
          amount: '2050',
        },
        {
          label: 'Diciembre 2023',
          amount: '2050',
        },
      ],
    },
    {
      id: '5',
      category: 'Otro ingreso indirecto',
      noteTotal: '1234.89',
      noteValidation: '9123.01',
      months: [
        {
          label: 'Enero 2023',
          amount: '200',
        },
        {
          label: 'Febrero 2023',
          amount: '2300',
        },
        {
          label: 'Marzo 2023',
          amount: '2050',
        },
        {
          label: 'Abril 2023',
          amount: '2050',
        },
        {
          label: 'Mayo 2023',
          amount: '2050',
        },
        {
          label: 'Junio 2023',
          amount: '2050',
        },
        {
          label: 'Julio 2023',
          amount: '2050',
        },
        {
          label: 'Agosto 2023',
          amount: '2050',
        },
        {
          label: 'Septiemre 2023',
          amount: '2050',
        },
        {
          label: 'Octubre 2023',
          amount: '2050',
        },
        {
          label: 'Noviembre 2023',
          amount: '2050',
        },
        {
          label: 'Diciembre 2023',
          amount: '2050',
        },
      ],
    },
    {
      id: '8',
      category: 'Otro ingreso operativo',
      noteTotal: '31234.89',
      noteValidation: '10123.01',
      months: [
        {
          label: 'Enero 2023',
          amount: '200',
        },
        {
          label: 'Febrero 2023',
          amount: '2300',
        },
        {
          label: 'Marzo 2023',
          amount: '2050',
        },
        {
          label: 'Abril 2023',
          amount: '2050',
        },
        {
          label: 'Mayo 2023',
          amount: '2050',
        },
        {
          label: 'Junio 2023',
          amount: '2050',
        },
        {
          label: 'Julio 2023',
          amount: '2050',
        },
        {
          label: 'Agosto 2023',
          amount: '2050',
        },
        {
          label: 'Septiemre 2023',
          amount: '2050',
        },
        {
          label: 'Octubre 2023',
          amount: '2050',
        },
        {
          label: 'Noviembre 2023',
          amount: '2050',
        },
        {
          label: 'Diciembre 2023',
          amount: '2050',
        },
      ],
    },
  ];
