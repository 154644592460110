import { SortGridModel } from '@core/models';
import { SortDescriptor } from '@progress/kendo-data-query';

export function formatSorter(
  mapping: Map<string, string>,
  sortValue: SortDescriptor[],
  currentSort: 'desc' | 'asc'
): SortGridModel {
  return {
    sort_by: mapping.get(sortValue[0].field),
    sort_order: currentSort === 'desc' ? 'asc' : 'desc',
  };
}
