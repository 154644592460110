import {
  Computed,
  DataAction,
  Payload,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { COUNTRY_CODES } from '@core/constants';
import { SessionModel, SingleItemStore, UserModel } from '@core/models';
import { State } from '@ngxs/store';

export class SessionStateModel extends SingleItemStore<SessionModel> {}

const defaults: SessionStateModel = {
  id: undefined,
  props: {
    accessToken: undefined,
  },
};

@Persistence()
@StateRepository()
@State<SessionStateModel>({
  name: 'session',
  defaults,
})
@Injectable()
export class SessionState extends NgxsDataRepository<SessionStateModel> {
  @Computed()
  public get accessToken(): string {
    return this.snapshot.props.accessToken || '';
  }

  @Computed()
  public get email(): string {
    return this.snapshot.props.email || '';
  }

  @Computed()
  public get isAdmin(): boolean {
    return this.snapshot.props.isAdmin || false;
  }

  @Computed()
  public get country(): string {
    return this.snapshot.props.country || COUNTRY_CODES.MX;
  }

  @Computed()
  public get isSanSalvador(): boolean {
    return this.snapshot.props.country === COUNTRY_CODES.SV;
  }

  @Computed()
  public get zendesk(): string | undefined {
    return this.snapshot.props.zendesk;
  }
  @Computed()
  public get paymentProvider(): string | undefined {
    return this.snapshot.props.idPymntProvider;
  }

  @Computed()
  public get user(): UserModel | undefined {
    return this.getState().props.user;
  }

  @Computed()
  public get schoolsId(): string | undefined {
    return this.getState().props.schoolAccess;
  }

  @DataAction()
  public addSession(@Payload('session') session: SessionModel): void {
    this.ctx.patchState({
      props: session,
    });
  }

  @DataAction()
  public removeSession(): void {
    this.ctx.patchState({
      props: {
        accessToken: undefined,
        email: undefined,
        isAdmin: this.snapshot.props.isAdmin,
        zendesk: undefined,
      },
    });
  }

  @DataAction()
  public removeZendeskToken(): void {
    this.ctx.patchState({
      props: {
        ...this.snapshot.props,
        zendesk: undefined,
      },
    });
  }
}
