import { FiscalYear } from '@core/models';

export const fiscalYearsListFake: FiscalYear[] = [
  {
    id: '1',
    name: '2024',
    description: '2024',
  },
  {
    id: '2',
    name: '2023',
    description: '2023',
  },
];
