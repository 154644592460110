import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular-ivy';
import { __VERSION__ } from './__version__';

export function initErrorTracking() {
  Sentry.init({
    dsn: 'https://bab3e2ec5f81176d85b88ed6e469e1fb@o4506029304578048.ingest.sentry.io/4506029477855232',
    environment: environment.sentry.environment,
    ignoreErrors: [
      'ChunkLoadError', //Disabled because it is a cache issue and it is fixed by reloading
      'Http failure response for', //Disable HTTP errors because they are alredy tracked by the backend project
    ],
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],
    release: __VERSION__,
    // Session Replay
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  });
}
