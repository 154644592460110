import { TemplateRef } from '@angular/core';
import { DialogAction } from '@progress/kendo-angular-dialog';

export enum NotificationType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  process = 'process',
}

export interface NotificationModel {
  title: string;
  message: string | TemplateRef<any>;
  type: NotificationType;
  afterClose?: (action: NotificationResponse) => void;
  actions?: ActionsModel[];
}

export interface NotificationResponse {
  action: string;
}

export interface ActionsModel extends DialogAction {
  action: string;
}
