import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { InvoiceModel } from '@core/models';
import { InvoicesService } from '@core/services';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { Observable, finalize, switchMap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<InvoiceModel>>({
  name: 'invoices',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class InvoicesState extends GenericState<InvoiceModel> {
  override service: InvoicesService;

  constructor(private invoicesService: InvoicesService) {
    super();
    this.service = invoicesService;
  }

  public cancelInvoice(invoiceId: string): Observable<InvoiceModel[]> {
    this.toggleLoading();
    return this.invoicesService.cancel(invoiceId).pipe(
      finalize(() => {
        this.toggleLoading();
      }),
      switchMap((): Observable<InvoiceModel[]> => {
        return this.getAllByPage({ page: this.snapshot.currentPage }, true);
      })
    );
  }
}
