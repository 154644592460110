import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { StudentAdministrativeRuleModel } from '@core/models';
import { SignedAdministrativeRulesService } from '@core/services';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { GenericState, GenericStateModel } from '../../generic.state';

@StateRepository()
@State<GenericStateModel<StudentAdministrativeRuleModel>>({
  name: 'signedAdministrativeRules',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class SignedAdministrativeRulesState extends GenericState<StudentAdministrativeRuleModel> {
  override service: SignedAdministrativeRulesService;

  constructor(
    signedAdministrativeRulesService: SignedAdministrativeRulesService
  ) {
    super();
    this.service = signedAdministrativeRulesService;
  }
}
