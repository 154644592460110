import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';
import { CLASS_ALERT_TYPES, TRANSLATION_KEYS } from '@core/constants';
import {
  ActionsModel,
  NotificationModel,
  NotificationType,
} from '@core/models';
import { TranslateService } from '@ngx-translate/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent extends DialogContentBase {
  @Input() notifySettings: NotificationModel;

  public defaultActions: ActionsModel[] = [
    {
      text: this.translateService.instant(
        TRANSLATION_KEYS.BUTTONS.CANCEL_LABEL
      ),
      action: 'cancel',
    },
    { text: 'Confirmar', themeColor: 'primary', action: 'confirm' },
  ];
  public readonly notificationType = NotificationType;

  private alertTypeClassMapping: Map<string, string> = CLASS_ALERT_TYPES;

  constructor(
    private dialogRef: DialogRef,
    private translateService: TranslateService
  ) {
    super(dialogRef);
  }

  public get template(): TemplateRef<any> {
    return this.notifySettings.message as TemplateRef<any>;
  }

  public closeDialog(action = 'cancel'): void {
    this.dialogRef.close({ text: action });
  }

  public getActions(): ActionsModel[] {
    return this.notifySettings.actions
      ? this.notifySettings.actions
      : this.defaultActions;
  }

  public getAlertTypeClass(type: string): string {
    return this.alertTypeClassMapping.get(type) || '';
  }

  public isString(value: string | TemplateRef<any>): value is string {
    return typeof value === 'string';
  }
}
