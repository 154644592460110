import {
  Computed,
  DataAction,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import {
  MultiItemStore,
  PendingAdministrativeRuleModel,
  StudentAdministrativeRuleModel,
} from '@core/models';
import { AdministrativeRulesService } from '@core/services';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { PendingAdministrativeRulesState } from '@core/state/user';
import { formatMultiStoreData } from '@core/utils';
import { State } from '@ngxs/store';
import { values } from 'lodash';
import { Observable, forkJoin, map, switchMap, tap } from 'rxjs';

export class AdministrativeRulesStateModel extends MultiItemStore<StudentAdministrativeRuleModel> {}

@StateRepository()
@State<AdministrativeRulesStateModel>({
  name: 'administrativeRules',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
    limitPerPage: 5,
  },
})
@Injectable()
export class AdministrativeRulesState extends NgxsDataRepository<AdministrativeRulesStateModel> {
  constructor(
    private administrativeRulesService: AdministrativeRulesService,
    private pendingAdministrativeRulesState: PendingAdministrativeRulesState
  ) {
    super();
  }

  @Computed()
  public get getPendingAdministrativeRules(): Observable<
    StudentAdministrativeRuleModel[]
  > {
    return this.state$.pipe(
      map((administrativeRulesState) => {
        return values(administrativeRulesState.items).filter(
          (rule) => rule.status === 'pending'
        );
      })
    );
  }

  @DataAction()
  private resetItems(): void {
    this.patchState({
      items: formatMultiStoreData([]),
      totalItems: 0,
      pages: {},
    });
  }

  public acceptAdministrativeRules(
    administrativeRules: StudentAdministrativeRuleModel[]
  ): Observable<PendingAdministrativeRuleModel[]> {
    const observables: Observable<StudentAdministrativeRuleModel>[] =
      administrativeRules.map((childRule: StudentAdministrativeRuleModel) => {
        if (!childRule.automatic_charges) {
          delete childRule.payment_method_id;
        }
        childRule.status = 'uploaded';
        return this.administrativeRulesService.post(childRule);
      });

    return forkJoin(observables).pipe(
      tap(() => {
        this.resetItems();
      }),
      switchMap(() =>
        this.pendingAdministrativeRulesState.getPendingAdministrativeRules()
      )
    );
  }

  public getAdministrativeRules(
    ids: string[]
  ): Observable<StudentAdministrativeRuleModel[]> {
    return this.administrativeRulesService.getAdministrativeRules({ ids }).pipe(
      map((content) => {
        const { items: administrativeRules } = content.data;
        for (const rule of administrativeRules) {
          if (rule.status === 'pending') {
            rule.id = `${rule.student.id}_${rule.administrativeRule?.id}`;
          }
        }
        return content;
      }),
      tap((content): void => {
        const { items: administrativeRules } = content.data;
        const objectToPatch: Partial<AdministrativeRulesStateModel> = {
          totalItems: content.data.total,
          items: {
            ...formatMultiStoreData(administrativeRules),
          },
          isLoading: false,
        };
        this.patchState(objectToPatch);
      }),
      map((content) => {
        return content.data.items;
      })
    );
  }
}
