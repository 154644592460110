import { Injectable } from '@angular/core';
import { CollectionNoticeModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentIssuesService extends Api<CollectionNoticeModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<CollectionNoticeModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/collections/collection-notices/anomalies`;
  }
}
