import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BREADCRUMB_ROUTER_DATA } from '@core/constants';
import { ExtendedBreadCrumb } from '@core/models';
import { LayoutState } from '@core/state/shared';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  constructor(private router: Router, private layoutState: LayoutState) {}

  public init() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: ExtendedBreadCrumb[] = [];
        this.addBreadcrumb(root, [], breadcrumbs);
        this.layoutState.setBreadcrumbs(breadcrumbs);
      });
  }

  private addBreadcrumb(
    route: ActivatedRouteSnapshot | null,
    parentUrl: string[],
    breadcrumbs: ExtendedBreadCrumb[]
  ) {
    if (!route) {
      return;
    }
    const routeUrl = parentUrl.concat(route.url.map((url) => url.path));
    if (route.data[BREADCRUMB_ROUTER_DATA]) {
      const breadcrumb = {
        text: route.data[BREADCRUMB_ROUTER_DATA],
        url: `/${routeUrl.join('/')}`,
      };

      if (
        !breadcrumbs
          .map((item) => item.text)
          .includes(route.data[BREADCRUMB_ROUTER_DATA])
      ) {
        breadcrumbs.push(breadcrumb);
      }
    }

    this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
  }
}
