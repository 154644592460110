import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationModel, PaymentAttemptsModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentAttemptsService extends Api<PaymentAttemptsModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<PaymentAttemptsModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/payments/logs`;
  }

  public export(params: PaginationModel): Observable<Blob> {
    params.limit = 1000000;
    params.page = 1;
    const endpoint = `${this.endpoint}/export/excel${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }
}
