import { FormGroup } from '@angular/forms';

export function filterMultiselectValues(
  controlName: string,
  items: any[],
  form: FormGroup
): void {
  const currentValue = form.get(controlName)?.value;
  if (currentValue) {
    const mappedItems = items.map((item) => item.id);

    const newValue = currentValue.filter((itemId: string) =>
      mappedItems.includes(itemId)
    );

    form.patchValue({ [controlName]: newValue }, { emitEvent: true });
  }
}

export function multiselectDisabledItems(
  items: any[],
  index: number,
  controlName: any,
  form: FormGroup
): boolean {
  if (!form.get(controlName)?.value.includes('all')) {
    return false;
  }

  return items
    .map((item, index) => index)
    .slice(1)
    .includes(index);
}

export function setAllValue(value: any): Array<any> {
  if (Array.isArray(value) && value.includes('all')) {
    return [];
  }

  return value;
}
