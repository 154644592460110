import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { RegionModel } from '@core/models';
import { RegionsService } from '@core/services/regions/regions.service';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { CatalogsState } from '@core/state/shared';
import { State } from '@ngxs/store';
import { Observable, switchMap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<RegionModel>>({
  name: 'region',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class RegionState extends GenericState<RegionModel> {
  override service: RegionsService;
  constructor(
    private regionsService: RegionsService,
    private catalogsState: CatalogsState
  ) {
    super();
    this.service = regionsService;
  }

  public createRegion(region: RegionModel): Observable<RegionModel[]> {
    this.toggleLoading();
    return this.regionsService.post(region).pipe(
      switchMap((region: RegionModel): Observable<RegionModel[]> => {
        this.catalogsState.pushItem(region, 'regions');
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        return this.getAllByPage({ page: lastPage }, true);
      })
    );
  }
}
