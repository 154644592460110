import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  PaginationModel,
  SingleItemResponse,
  StorePaymentModel,
  StoresModel,
} from '@core/models';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentStoreService extends Api<StorePaymentModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<StorePaymentModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/payments/payment-management/store`;
  }
  public printReference(
    id: string,
    isAdmin = false
  ): Observable<StorePaymentModel> {
    const endpoint = isAdmin
      ? `${environment.apiUrl}/v1/admin/payments/payment-management/store`
      : `${this.endpoint}`;

    return this.apiService.get(`${endpoint}/${id}/pdf`).pipe(
      map((data: SingleItemResponse<StorePaymentModel>) => {
        return data.data;
      })
    );
  }
  public getAvailableStore(params: PaginationModel): Observable<StoresModel[]> {
    const endpoint = `${
      environment.apiUrl
    }/v1/payments/payment-stores${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;
    return this.http.get<CollectionResponse<StoresModel>>(endpoint).pipe(
      map((response) => {
        return response.data.items;
      })
    );
  }
}
