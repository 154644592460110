import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { DailyPaymentsReportModel } from '@core/models';
import { DailyPaymentsReportService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<DailyPaymentsReportModel>>({
  name: 'dailyPaymentsReport',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class DailyPaymentsReportState extends GenericState<DailyPaymentsReportModel> {
  override service: DailyPaymentsReportService;
  constructor(dailyPaymentsReportService: DailyPaymentsReportService) {
    super();
    this.service = dailyPaymentsReportService;
  }
}
