import {
  Computed,
  DataAction,
  Payload,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import {
  CollectionNoticeModel,
  ExternalPaymentParamsModel,
  ExternalPaymentsModel,
  PaymentMethodModel,
} from '@core/models';
import { ExternalCollectionsService } from '@core/services/admin';
import { State } from '@ngxs/store';
import { Observable, map } from 'rxjs';

const defaults: ExternalPaymentsModel = {
  collectionNotice: undefined,
  paymentMethod: undefined,
  isLoading: false,
  params: undefined,
};

@Persistence()
@StateRepository()
@State<ExternalPaymentsModel>({
  name: 'externalPayment',
  defaults,
})
@Injectable()
export class ExternalPaymentState extends NgxsDataRepository<ExternalPaymentsModel> {
  constructor(private externalCollectionsService: ExternalCollectionsService) {
    super();
  }

  @Computed()
  public get getExternalPayment(): ExternalPaymentsModel {
    return this.snapshot;
  }
  @Computed()
  public get params(): ExternalPaymentParamsModel | undefined {
    return this.snapshot.params;
  }
  @Computed()
  public get isLoading(): Observable<boolean> {
    return this.state$.pipe(
      map((state) => {
        return state.isLoading;
      })
    );
  }

  @Computed()
  public get collectionNotice$(): Observable<
    CollectionNoticeModel | undefined
  > {
    return this.state$.pipe(
      map((state) => {
        return state.collectionNotice;
      })
    );
  }

  @DataAction()
  public saveCollectionNotice(
    @Payload('collectionNotice') collectionNotice: CollectionNoticeModel
  ): void {
    this.ctx.patchState({
      ...{ collectionNotice },
    });
  }
  @DataAction()
  public savePaymentMethod(
    @Payload('paymentMethod') paymentMethod: PaymentMethodModel
  ): void {
    this.ctx.patchState({
      ...{ paymentMethod },
    });
  }
  @DataAction()
  public saveParams(
    @Payload('params') params: ExternalPaymentParamsModel
  ): void {
    this.ctx.patchState({
      ...{ params },
    });
  }

  @DataAction()
  public cleanFilter(): void {
    this.ctx.patchState({
      ...defaults,
    });
  }

  public toggleLoading(): void {
    this.patchState({
      isLoading: !this.snapshot.isLoading,
    });
  }

  public updateCollectionNotice(): void {
    this.externalCollectionsService
      .getExternalCollectionNotice(
        this.snapshot.params || ({} as ExternalPaymentParamsModel)
      )
      .subscribe((collectionNotice: CollectionNoticeModel) => {
        this.ctx.patchState({
          ...{ collectionNotice },
        });
      });
  }
}
