import { Injectable } from '@angular/core';
import { TRANSLATION_KEYS } from '@core/constants';
import {
  CollectionResponse,
  RoleModel,
  SingleItemResponse,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import {
  DialogAction,
  DialogCloseResult,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RolesService extends Api<RoleModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<RoleModel>,
    private translateService: TranslateService,
    private dialogService: DialogService
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/security/roles`;
  }

  public createRole(
    data: RoleModel
  ): Observable<CollectionResponse<RoleModel>> {
    return this.apiService.post(this.endpoint, JSON.stringify(data));
  }

  public deleteRole(id: string) {
    return this.dialogService
      .open({
        title: 'Eliminar rol',
        content: '¿Estás seguro de eliminar este rol?',
        actions: [
          {
            text: this.translateService.instant(
              TRANSLATION_KEYS.BUTTONS.CANCEL_LABEL
            ),
          },
          { text: 'Confirmar', themeColor: 'primary' },
        ],
        actionsLayout: 'end',
        width: 500,
        height: 248,
        cssClass: 'confirm-dialog',
      })
      .result.pipe(
        switchMap((result) => {
          if (
            !(result instanceof DialogCloseResult) &&
            (result as DialogAction).text === 'Confirmar'
          ) {
            return this.apiService.delete(`${this.endpoint}/${id}`);
          }
          return of(null);
        })
      );
  }

  public editRole(data: RoleModel): Observable<SingleItemResponse<RoleModel>> {
    return this.apiService.put(
      `${this.endpoint}/${data.id}`,
      JSON.stringify(data)
    );
  }

  public getRole(id: string): Observable<SingleItemResponse<RoleModel>> {
    return this.apiService.get(`${this.endpoint}/${id}`);
  }
}
