import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CollectionResponse, Scholarship } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScholarshipsTypeService extends Api<Scholarship> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<Scholarship>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint =
      this.endpoint = `${environment.apiUrl}/v1/admin/reports/scholarship-info/types`;
  }

  public getScholarshipsTypes(): Observable<Scholarship[]> {
    return this.http.get<CollectionResponse<Scholarship>>(this.endpoint).pipe(
      map((respose) => {
        return respose.data.items;
      })
    );
  }
}
