import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationModel, StudentScholarshipModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StudentScholarshipService extends Api<StudentScholarshipModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<StudentScholarshipModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/students/scholarship`;
  }

  public export(params: PaginationModel): Observable<Blob> {
    params.limit = 1000000;
    params.page = 1;
    const endpoint = `${this.endpoint}/export/excel${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public import(file: File, schoolId: string | undefined): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    if (schoolId) {
      formData.append('school_id', schoolId);
    }
    const endpoint = `${environment.apiUrl}/api/v1/admin-v2/scholarships/massive_upload`;
    return this.http.post(endpoint, formData);
  }
}
