import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  PaginationModel,
  PendingAdministrativeRuleModel,
  StudentAdministrativeRuleModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@rcsa-frontend/environments/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdministrativeRulesService extends Api<StudentAdministrativeRuleModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<StudentAdministrativeRuleModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/account/administrative-rules`;
  }

  public getAdministrativeRules(
    params: PaginationModel
  ): Observable<CollectionResponse<StudentAdministrativeRuleModel>> {
    const url = `${this.endpoint}/${`?${new URLSearchParams(
      params
    ).toString()}`}`;
    return this.http
      .get<CollectionResponse<StudentAdministrativeRuleModel>>(url)
      .pipe(
        map((respose) => {
          return respose;
        })
      );
  }

  public getAdministrativeRulesByStudentCycle(
    ids: string
  ): Observable<StudentAdministrativeRuleModel[]> {
    const url = `${this.endpoint}/indexByCycle${`?${new URLSearchParams({
      ids,
    }).toString()}`}`;

    return this.http
      .get<CollectionResponse<StudentAdministrativeRuleModel>>(url)
      .pipe(
        map((respose) => {
          return respose.data.items;
        })
      );
  }

  public getPendingAdministrativeRules(): Observable<
    PendingAdministrativeRuleModel[]
  > {
    const url = `${this.endpoint}/pending`;
    return this.http
      .get<CollectionResponse<PendingAdministrativeRuleModel>>(url)
      .pipe(
        map((respose) => {
          return respose.data.items;
        })
      );
  }
}
