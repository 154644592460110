<kendo-contextmenu
  [alignToAnchor]="true"
  [target]="target"
  showOn="click"
  (select)="action($event)"
>
  <ng-container *ngFor="let lang of languajes">
    <kendo-menu-item [text]="lang.code">
      <ng-template kendoMenuItemTemplate let-item="item">
        <div class="lang-desc">
          <img
            [src]="'assets/images/app/lang/' + lang.flagIcon + '.svg'"
            [alt]="lang.code"
            width="20"
            height="20"
          />
          {{ lang.name }}
        </div>
      </ng-template>
    </kendo-menu-item>
  </ng-container>
</kendo-contextmenu>
