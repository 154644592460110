import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { GenericState } from '@core/state';
import {
  DropDownFilterSettings,
  DropDownListComponent,
} from '@progress/kendo-angular-dropdowns';
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  distinctUntilChanged,
  finalize,
  take,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor, OnDestroy {
  @ViewChild('select') public select: DropDownListComponent;

  @Input()
  public clear = false;

  @Input()
  public formControl?: FormControl;

  @Input()
  public placeholder = '';

  @Input()
  public placeholderClass = '';

  @Input()
  public textField: string;

  @Input()
  public searchBy = '';

  @Input()
  public valueField: string;

  @Input()
  public currentState?: GenericState<any>;

  @Input()
  public searchProperty: string;

  @Output()
  public valueChange = new EventEmitter();

  public disabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  public items$: BehaviorSubject<any[]> = new BehaviorSubject([] as any[]);
  public onChange?: (value: string | undefined) => void;
  public onTouched?: () => void;
  public value?: string;
  public loading$: Subject<boolean> = new Subject<boolean>();
  private searchTerm$: Subject<string> = new Subject<string>();
  private _filterByService = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private cancelRequest$: Subject<boolean> = new Subject<boolean>();
  private searchTerm = '';

  public get allowFilter(): boolean {
    return this.items$.value.length > 0 || this.filterByService;
  }
  public get filterByService(): boolean {
    return this._filterByService;
  }

  @Input()
  public set filterByService(filterByService: boolean) {
    this._filterByService = filterByService;
    if (!filterByService) {
      return;
    }
    this.initSearchTerm();
  }

  @Input()
  public set data(data: any[]) {
    this.items$.next(data);
    if (data.length === 0) {
      this.value = undefined;
    }
  }
  @Input()
  public set loading(loading: boolean) {
    this.loading$.next(loading);
  }
  public clearValue() {
    this.value = undefined;
    this.onValueChange(this.value);
  }

  public getDropdownClasses() {
    const classes: string[] = [];
    if (this.clear && this.value) {
      classes.push('with-clear');
    }
    if (this.placeholderClass.length > 0) {
      classes.push(this.placeholderClass);
    }
    return classes;
  }

  public onValueChange(value: string | undefined) {
    if (this.onChange) {
      this.onChange(value);
    }
    this.valueChange.emit(value);
  }

  public registerOnChange(fn: (value: string | undefined) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled.next(isDisabled);
  }

  public writeValue(value: string | undefined): void {
    this.value = value;
  }

  public updateData(searhTerm: string) {
    if (!this._filterByService && !this.currentState) {
      return;
    }
    this.searchTerm$.next(searhTerm);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.searchTerm$.complete();
  }

  public onOpen() {
    this.select.filterText = this.searchTerm;
  }

  private initSearchTerm() {
    this.searchTerm$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        tap(() => {
          this.cancelRequest$.next(true);
        }),
        debounceTime(500)
      )
      .subscribe((searchTerm: string) => {
        if (!searchTerm.trim().length) {
          return;
        }
        this.loading$.next(true);
        this.searchTerm = searchTerm;
        this.getData(searchTerm);
      });
  }

  private getData(searchTerm: string): void {
    const params: any = {};
    params[this.searchProperty] = searchTerm;
    if (!this.currentState) {
      return;
    }
    this.currentState
      .getAllByPage(params, true)
      .pipe(
        takeUntil(this.cancelRequest$),
        take(1),
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe((result) => {
        this.items$.next(result);
      });
  }
}
