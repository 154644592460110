export interface FilterUsersModel {
  region?: string,
  school?: string,
  bussinesName?: string,
}

export enum FilterUserTypeEnum {
  REGION = 'region',
  SCHOOL = 'school',
  BUSSINES_NAME = 'bussinesName',
}
