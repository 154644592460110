import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ACCEPTANCE_LANGUAGE, DEFAULT_LANGUAGE } from '@core/constants';
import { SessionState } from '@core/state/shared';
import { Observable } from 'rxjs';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {
  private readonly ACCEPTANCE_LANGUAGE = ACCEPTANCE_LANGUAGE;
  constructor(private sessionState: SessionState) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('scholarships/massive_upload')) {
      return next.handle(req);
    }
    const contentTypeReq = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'Accept-Language':
          this.ACCEPTANCE_LANGUAGE.get(this.sessionState.country) ||
          DEFAULT_LANGUAGE,
      },
    });

    return next.handle(contentTypeReq);
  }
}
