import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router, Scroll } from '@angular/router';
import {
  ADMIN_DRAWER_ITEMS,
  ADMIN_EVENTS,
  EVENT_MODULES,
  RVOE_MENU_ITEM,
  USER_ADMINISTRATIVE_RULES,
  USER_DRAWER_ITEMS,
  USER_EVENTS,
} from '@core/constants';
import { getListPermission } from '@core/functions';
import {
  CurrentUserModel,
  ExtendedDrawerItem,
  FeatureFlagAccessModel,
  FeatureFlagCodes,
  RecursiveDrawerItem,
  VersionModel,
} from '@core/models';
import {
  AuthService,
  AuthorizationService,
  CatalogsService,
  FeatureFlagsAccessService,
} from '@core/services';
import { FeatureFlagsAccessService as FeatureFlagsAdminAccessService } from '@core/services/admin';
import { EventsState } from '@core/state/admin';
import {
  AlertsState,
  CurrentUserState,
  LayoutState,
  SessionState,
  VersionState,
} from '@core/state/shared';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import {
  DrawerItemExpandedFn,
  DrawerSelectEvent,
} from '@progress/kendo-angular-layout';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  map,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs';
import { __VERSION__ } from '../../../../../__version__';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  public avatarLetters$: Observable<string>;
  public isSidenavOpen$: Observable<boolean>;
  public isUnathorized$: Observable<boolean>;
  public versionApp: string = __VERSION__;
  public mobileQuery: MediaQueryList;
  public user$: Observable<CurrentUserModel | undefined>;
  public userName$: Observable<string>;
  public showVersionDialog$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private expandedItems: number[] = [];
  private ADMIN_DRAWER_ITEMS = ADMIN_DRAWER_ITEMS;
  private USER_DRAWER_ITEMS = USER_DRAWER_ITEMS;
  private versionCheck$: Observable<VersionModel> = new Observable();
  private versionTimelapse: number = 5 * 60 * 1000;
  private unsubscribe$: Subject<void> = new Subject();
  private lastVersion: string;
  private schoolIds: (string | undefined)[] | undefined;
  constructor(
    private authorizationService: AuthorizationService,
    private authService: AuthService,
    private currentUserState: CurrentUserState,
    public layoutState: LayoutState,
    private media: MediaMatcher,
    private router: Router,
    public sessionState: SessionState,
    public alertsState: AlertsState,
    private store: Store,
    private eventsState: EventsState,
    private changeDetectorRef: ChangeDetectorRef,
    private featureFlagAccessService: FeatureFlagsAccessService,
    private versionState: VersionState,
    private catalogsService: CatalogsService,
    private translate: TranslateService,
    private featureFlagsAdminAccessService: FeatureFlagsAdminAccessService
  ) {}

  public get menuItems(): ExtendedDrawerItem[] {
    const flattenedItems: ExtendedDrawerItem[] = [],
      recursiveItems: RecursiveDrawerItem[] = this.sessionState.isAdmin
        ? this.ADMIN_DRAWER_ITEMS
        : USER_DRAWER_ITEMS;
    let nextId = 0;

    const flattenMenuItem = (
      item: RecursiveDrawerItem,
      parent?: ExtendedDrawerItem
    ): boolean => {
      const flattenedItem: ExtendedDrawerItem = {
        id: nextId,
        route: parent
          ? `${parent.route}${item.route ? `/${item.route}` : ''}`
          : `${this.sessionState.isAdmin ? '/admin/app' : '/app'}/${
              item.route
            }`,
        text: item.text,
      };
      let permission = false;

      if (item.actionEvent) {
        flattenedItem.actionEvent = item.actionEvent;
      }

      if (item.cssClass) {
        flattenedItem.cssClass = item.cssClass;
      }

      if (parent) {
        flattenedItem.parentId = parent.id;
      }
      if (
        this.router.url === (flattenedItem.route as string) &&
        !item.children
      ) {
        flattenedItem.selected = true;
      }

      nextId++;

      if (item.children) {
        item.children.forEach((item) => {
          permission = flattenMenuItem(item, flattenedItem) || permission;
        });
      } else {
        flattenedItem.isLeaf = true;
        permission = this.sessionState.isAdmin ? this.isPermitted(item) : true;
      }

      if (permission || !this.sessionState.isAdmin) {
        flattenedItems.push(flattenedItem);
      }
      return permission || !this.sessionState.isAdmin;
    };

    recursiveItems.forEach((item) => {
      flattenMenuItem(item);
    });

    this.addLogoutItem(flattenedItems);

    return flattenedItems;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: UIEvent) {
    const window = event.target as Window;
    this.layoutState.setScreenWidth(window.innerWidth);
  }

  public ngOnInit(): void {
    this.schoolIds = this.currentUserState.currentUserStudentsSchools?.filter(
      (school) => school !== undefined
    );

    this.isSidenavOpen$ = this.layoutState.isSidenavOpen$;
    this.isUnathorized$ = this.router.events.pipe(
      filter((event) => event instanceof Scroll),
      map((event) =>
        (event as Scroll).routerEvent.url.includes('sin-autorizacion')
      )
    );
    this.versionCheck$ = timer(0, this.versionTimelapse).pipe(
      takeUntil(this.unsubscribe$),
      switchMap(() => this.versionState.getVersion())
    );
    this.user$ = this.currentUserState.currentUser$;

    this.avatarLetters$ = this.user$.pipe(
      map((user) =>
        user
          ? `${user.name ? user.name.charAt(0) : ''}${
              user.lastName ? user.lastName.charAt(0) : ''
            }`.toLocaleUpperCase()
          : ''
      )
    );

    this.userName$ = this.user$.pipe(
      map((user) =>
        user ? `${user.name || ''} ${user.lastName || ''}`.trim() : ''
      )
    );

    this.layoutState.setScreenWidth(window.innerWidth);
    this.expandedItems = [...this.layoutState.sidenavExpandedItems];

    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);

    this.mobileQueryListener();
    this.checkVersion();
    this.catalogsService.reloadCatalogs();
    if (!this.sessionState.isAdmin) {
      this.fetchEventFlag();
    } else {
      this.fetchRVOEFlag();
      this.fetchAdminEventsFlag();
    }
    this.translate.setDefaultLang(this.layoutState.language);
  }

  public ngOnDestroy() {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public isItemExpanded: DrawerItemExpandedFn = (
    item: ExtendedDrawerItem
  ): boolean => {
    return this.expandedItems.indexOf(item.id as number) >= 0;
  };

  public reloadVersion(): void {
    this.showVersionDialog$.next(false);
    this.versionState.setVersion(this.lastVersion);
    window.location.reload();
  }

  public onMenuItemSelect(ev: DrawerSelectEvent): void {
    const current = ev.item.id;
    if (this.expandedItems.includes(current)) {
      this.expandedItems = this.expandedItems.filter((id) => id !== current);
    } else {
      this.expandedItems.push(current);
    }
    this.layoutState.setSidenavExpandedItems([...this.expandedItems]);
    if (ev.item.actionEvent) {
      ev.item.actionEvent();
      this.collapseDrawerInMobile();
    } else if (ev.item.route && ev.item.isLeaf) {
      this.navigate(ev);
    }
  }

  private navigate(menu: DrawerSelectEvent) {
    if (!(menu.originalEvent.ctrlKey || menu.originalEvent.metaKey)) {
      this.store.dispatch(new Navigate([menu.item.route]));
      this.collapseDrawerInMobile();
      return;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree([menu.item.route])
    );
    window.open(url, '_blank');
    this.collapseDrawerInMobile();
  }

  private addLogoutItem(items: ExtendedDrawerItem[]) {
    const myAccount = items.find(
        (item) => item.text === 'MI CUENTA'
      ) as ExtendedDrawerItem,
      logoutItem: ExtendedDrawerItem = {
        actionEvent: () => {
          this.authService.logout();
        },
        cssClass: 'menu-logout',
        parentId: myAccount.id,
        text: 'Cerrar sesión',
      };
    items.push(logoutItem);
  }

  private collapseDrawerInMobile() {
    if (this.mobileQuery.matches) {
      this.layoutState.toggleSidenav();
    }
  }

  private isPermitted(item: RecursiveDrawerItem) {
    if (item.permission) {
      return this.authorizationService.hasPermission(
        getListPermission(item.permission)
      );
    }
    return true;
  }

  private mobileQueryListener = () => {
    if (this.mobileQuery.matches && this.layoutState.isSidenavOpen) {
      this.layoutState.toggleSidenav();
    }
  };

  private fetchEvents(): void {
    this.eventsState.cleanState();
    this.eventsState
      .getAllByPage({ page: 1, pageSize: 1000 })
      .pipe(
        map((events) => {
          return events.map((event) => {
            return {
              text: event.name,
              route: `${event.code}`,
              children: EVENT_MODULES,
            };
          });
        })
      )
      .subscribe((events) => {
        if (events.length === 0) {
          return;
        }
        const eventsModules = ADMIN_EVENTS;
        eventsModules.children = [...events] as never[];
        if (
          this.ADMIN_DRAWER_ITEMS.includes(eventsModules as RecursiveDrawerItem)
        ) {
          return;
        }
        this.ADMIN_DRAWER_ITEMS.splice(3, 0, eventsModules);
        this.changeDetectorRef.detectChanges();
      });
  }

  private fetchEventFlag(): void {
    const index = this.USER_DRAWER_ITEMS.indexOf(USER_EVENTS);
    if (index !== -1) {
      this.USER_DRAWER_ITEMS.splice(index, 1);
    }
    if (!this.schoolIds?.length) {
      return;
    }
    this.featureFlagAccessService
      .checkStatus(FeatureFlagCodes.FRIENDSHIP_TOURNAMENT, `${this.schoolIds}`)
      .subscribe((response: FeatureFlagAccessModel) => {
        this.handleFlag(response);
      });
  }
  private fetchAdministrativeRuleFlag(): void {
    if (!this.schoolIds?.length) {
      return;
    }
    this.featureFlagAccessService
      .checkStatus(FeatureFlagCodes.ADMINISTRATIVE_RULES, `${this.schoolIds}`)
      .subscribe((response: FeatureFlagAccessModel) => {
        this.handleAdministrativeRuleFlag(response);
      });
  }
  private fetchRVOEFlag(): void {
    const schoolIds = this.sessionState.schoolsId;
    if (schoolIds === '-') {
      this.handleREVOEFeatureFlag({} as FeatureFlagAccessModel, true);
    }
    if (!schoolIds) {
      return;
    }
    this.featureFlagsAdminAccessService
      .checkStatus(FeatureFlagCodes.RVOE, schoolIds)
      .subscribe((response: FeatureFlagAccessModel) => {
        this.handleREVOEFeatureFlag(response);
      });
  }

  private fetchAdminEventsFlag(): void {
    const schoolIds = this.sessionState.schoolsId;
    const index = this.ADMIN_DRAWER_ITEMS.indexOf(ADMIN_EVENTS);
    if (index !== -1) {
      this.ADMIN_DRAWER_ITEMS.splice(index, 1);
    }
    if (schoolIds === '-') {
      this.handleAdminEventsFeatureFlag({} as FeatureFlagAccessModel, true);
      return;
    }
    if (!schoolIds) {
      return;
    }
    this.featureFlagsAdminAccessService
      .checkStatus(FeatureFlagCodes.FRIENDSHIP_TOURNAMENT, schoolIds)
      .subscribe((response: FeatureFlagAccessModel) => {
        this.handleAdminEventsFeatureFlag(response);
      });
  }

  private handleFlag(response: FeatureFlagAccessModel) {
    const status = response.access.map((item) => item.status);
    if (
      !status.includes('active') ||
      this.USER_DRAWER_ITEMS.includes(USER_EVENTS)
    ) {
      return;
    }
    this.USER_DRAWER_ITEMS.splice(3, 0, USER_EVENTS);
    this.changeDetectorRef.detectChanges();
  }

  private handleAdministrativeRuleFlag(response: FeatureFlagAccessModel): void {
    const status = response.access.map((item) => item.status);
    const accountMenu = this.USER_DRAWER_ITEMS.find(
      (menu) => menu.text.toLowerCase() === 'mi cuenta'
    );
    if (
      !status.includes('active') ||
      (accountMenu?.children &&
        accountMenu.children.includes(USER_ADMINISTRATIVE_RULES))
    ) {
      return;
    }
    accountMenu?.children
      ? accountMenu.children.splice(1, 0, USER_ADMINISTRATIVE_RULES)
      : '';
    this.changeDetectorRef.detectChanges();
  }

  private handleREVOEFeatureFlag(
    response: FeatureFlagAccessModel,
    omitRespose = false
  ): void {
    const configMenu = this.ADMIN_DRAWER_ITEMS.find(
      (menu) => menu.route?.toLowerCase() === 'configuracion'
    );
    if (!omitRespose) {
      const status = response.access.map((item) => item.status);
      if (
        !status.includes('active') ||
        (configMenu?.children && configMenu.children.includes(RVOE_MENU_ITEM))
      ) {
        return;
      }
    }
    configMenu?.children
      ? configMenu.children.splice(0, 0, RVOE_MENU_ITEM)
      : '';
    this.changeDetectorRef.detectChanges();
  }

  private handleAdminEventsFeatureFlag(
    response: FeatureFlagAccessModel,
    omitRespose = false
  ): void {
    if (!omitRespose) {
      const status = response.access.map((item) => item.status);
      if (!status.includes('active')) {
        return;
      }
    }
    this.fetchEvents();
  }

  private checkVersion(): void {
    this.versionCheck$.subscribe((version) => {
      this.lastVersion = version.version;
      this.showVersionDialog$.next(
        this.versionState.version != version.version
      );
    });
  }
}
