import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { SectionModel } from '@core/models';
import { SectionsService } from '@core/services';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<SectionModel>>({
  name: 'section',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class SectionState extends GenericState<SectionModel> {
  override service: SectionsService;
  constructor(private sectionsService: SectionsService) {
    super();
    this.service = sectionsService;
  }
}
