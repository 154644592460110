export function downloadFile(fileName: string, blob: Blob) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function downloadFromSource(
  fileName: string,
  content: string,
  sourceType: string
): void {
  const source = `${sourceType},${content}`;
  const link = document.createElement('a');
  link.href = source;
  link.download = `${fileName}`;
  link.click();
}

export function calcBase64Size(base64: string): number {
  const sizeInBytes = 4 * Math.ceil(base64.length / 3) * 0.5624896334383812;
  return sizeInBytes;
}
