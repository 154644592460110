import {
  Computed,
  DataAction,
  Payload,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { BackEndPermissionMap, PermissionModel } from '@core/models';
import { NgxsOnInit, State } from '@ngxs/store';
import { Observable, map } from 'rxjs';

export class AuthorizationStateModel {
  public permissions!: BackEndPermissionMap;
}

@Persistence()
@StateRepository()
@State<AuthorizationStateModel>({
  name: 'authorization',
})
@Injectable()
export class AuthorizationState
  extends NgxsDataRepository<AuthorizationStateModel>
  implements NgxsOnInit
{
  @Computed()
  public get isReady$(): Observable<boolean> {
    return this.state$.pipe(
      map((permissionsState) => !!permissionsState.permissions)
    );
  }

  @DataAction()
  public addPermissions(
    @Payload('permissions') permissions: BackEndPermissionMap
  ): void {
    this.ctx.patchState({
      permissions,
    });
  }

  public hasPermission(permission: string): boolean {
    return !!this.snapshot.permissions[permission];
  }

  public hasUpdatePermission(permissionName: string): boolean {
    const permission: PermissionModel | undefined = this.snapshot.permissions[
      permissionName
    ] as PermissionModel | undefined;
    if (permission) {
      return this.hasPermission(
        `${permission.moduleName}.${permission.moduleAction}.update`
      );
    }
    return false;
  }
}
