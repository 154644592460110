import { Injectable } from '@angular/core';
import { PaymentHistoryModel } from '@core/models';
import { environment } from '@env/environment';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentHistoryService extends Api<PaymentHistoryModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<PaymentHistoryModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/payments/history`;
  }
}
