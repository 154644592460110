import { Persistence, StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { FiscalRegimeModel } from '@core/models';
import { FiscalRegimeService } from '@core/services';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@Persistence()
@StateRepository()
@State<GenericStateModel<FiscalRegimeModel>>({
  name: 'fiscalRegime',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
    limitPerPage: 0,
  },
})
@Injectable()
export class FiscalRegimesState extends GenericState<FiscalRegimeModel> {
  override service: FiscalRegimeService;
  constructor(private fiscalRegimesService: FiscalRegimeService) {
    super();
    this.service = fiscalRegimesService;
  }
}
