import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { ADMIN_UNAUTHORIZED_ROUTE, USER_DEFAULT_ROUTE } from '@core/constants';
import { getLeafPermission } from '@core/functions';
import { AuthorizationService } from '@core/services';
import { AuthorizationState, SessionState } from '@core/state/shared';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { first, map } from 'rxjs';

export const permissionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  const authorizationService = inject(AuthorizationService),
    authorizationState = inject(AuthorizationState),
    permission = getLeafPermission(route),
    sessionState = inject(SessionState),
    store = inject(Store);
  let isAuthorized = true;

  return authorizationState.isReady$.pipe(
    first((isReady) => isReady),
    map(() => {
      if (permission) {
        isAuthorized = authorizationService.hasPermission(permission);
      }

      if (!isAuthorized) {
        store.dispatch(
          new Navigate([
            sessionState.isAdmin
              ? ADMIN_UNAUTHORIZED_ROUTE
              : USER_DEFAULT_ROUTE,
          ])
        );
      }

      return isAuthorized;
    })
  );
};
