<kendo-contextmenu
  [alignToAnchor]="true"
  [target]="target"
  showOn="click"
  (select)="action($event)"
>
  <ng-container *ngFor="let option of menuOptions">
    <kendo-menu-item
      text="{{ option.title }}"
      *appHasPermission="option.permission ?? ''"
    >
      <ng-template kendoMenuItemTemplate let-item="item">
        <div>
          <i [ngClass]="[option.icon]"></i>
          {{ item.text }}
        </div>
      </ng-template>
    </kendo-menu-item>
  </ng-container>
</kendo-contextmenu>
