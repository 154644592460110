<div>
  <button
    class="squared-button non-printable"
    (click)="layoutState.toggleSidenav()"
  >
    <i class="ph" [ngClass]="(isSidenavOpen$ | async) ? 'ph-x' : 'ph-list'"></i>
  </button>
  <img
    class="logo print-logo"
    src="assets/images/common/logo.svg"
    width="290"
    height="56"
    alt="Red de colegios Semper Altius Red prepa Anáhuac"
  />
  <img
    *ngIf="isAdmin"
    class="hub non-printable"
    src="assets/images/common/hub-admin.svg"
    width="74"
    height="35"
    alt="HUB+ admin"
  />
  <img
    *ngIf="!isAdmin"
    class="hub non-printable"
    src="assets/images/common/hub.svg"
    width="68"
    height="34"
    alt="HUB+"
  />
</div>

<div class="right-navbar non-printable">
  <ng-container *ngIf="ENABLED_SWITCHER_APPLICATION">
    <button #targetSwitcherMenu class="squared-button support">
      <i class="ph ph-squares-four"></i>
    </button>
    <app-switcher-menu
      [menuOptions]="SwitcherOptions"
      [target]="targetSwitcherMenu"
    ></app-switcher-menu>
  </ng-container>
  <button #language class="squared-button support">
    <img
      [src]="'assets/images/app/lang/' + languageIcon + '.svg'"
      [alt]="layoutState.language"
      width="27"
      height="20"
    />
  </button>
  <app-language [target]="language" (language)="setLanguage($event)">
  </app-language>

  <button #target class="squared-button support">
    <i class="ph ph-question"></i>
  </button>
  <app-support-menu
    [link]="isAdmin ? '/admin/soporte' : '/soporte'"
    [target]="target"
  ></app-support-menu>
  <button (click)="logout()" class="logout-button">
    <span>Cerrar sesión</span><i class="ph ph-sign-out"></i>
  </button>
</div>
