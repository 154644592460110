import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ACCEPTANCE_LANGUAGE, DEFAULT_LANGUAGE } from '@core/constants';
import { SessionState } from '@core/state/shared';
import { Observable } from 'rxjs';
import { __VERSION__ } from '../../../__version__';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly endpointsToSkipAuth: string[] = ['/assets/i18n/'];
  private readonly ACCEPTANCE_LANGUAGE = ACCEPTANCE_LANGUAGE;
  constructor(private sessionState: SessionState) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes('version.json') ||
      this.shouldSkipAuth(request.url)
    ) {
      return next.handle(request);
    }

    const token = this.sessionState.accessToken;
    let customRequest = request;
    if (token) {
      // Clone the request and add the Authorization header
      customRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'X-App-Version': __VERSION__,
          'Accept-Language':
            this.ACCEPTANCE_LANGUAGE.get(this.sessionState.country) ||
            DEFAULT_LANGUAGE,
        },
      });
    }

    return next.handle(customRequest);
  }

  private shouldSkipAuth(url: string): boolean {
    return this.endpointsToSkipAuth.some((endpoint) => url.includes(endpoint));
  }
}
