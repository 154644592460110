import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { AuditLogModel } from '@core/models';
import { AuditLogsService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<AuditLogModel>>({
  name: 'auditLogs',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class AuditLogsState extends GenericState<AuditLogModel> {
  override service: AuditLogsService;

  constructor(private auditLogsService: AuditLogsService) {
    super();
    this.service = auditLogsService;
  }
}
