import { CollectionNoticeModel } from '@core/models';
const CORE_ITEMS = ['Colegiatura', 'Inscripción', 'Reinscripción', 'Academia'];

export function getNextSortedCollectionNotices(
  currentCollectionNotice: CollectionNoticeModel,
  collectionNotices: CollectionNoticeModel[]
): CollectionNoticeModel[] {
  const currentIndex = collectionNotices.indexOf(currentCollectionNotice);
  if (
    currentIndex === -1 ||
    !CORE_ITEMS.includes(currentCollectionNotice.concept.conceptCategory.name)
  ) {
    return [];
  }

  return collectionNotices
    .slice(currentIndex + 1)
    .filter((item) => {
      return (
        item.studentSchoolRelationId ===
          currentCollectionNotice.studentSchoolRelationId &&
        CORE_ITEMS.includes(item.concept.conceptCategory.name)
      );
    })
    .sort(
      (a, b) =>
        new Date(a.belongsToDate).getTime() -
        new Date(b.belongsToDate).getTime()
    );
}

export function hasExpiredItems(
  currentCollectionNotice: CollectionNoticeModel,
  collectionNotices: CollectionNoticeModel[]
): boolean {
  return collectionNotices.some(
    (item) =>
      item.studentSchoolRelationId ===
      currentCollectionNotice.studentSchoolRelationId
  );
}
