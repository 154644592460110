import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getAllWithoutId } from '@core/functions';
import {
  ChargesByCategoryReportModel,
  CollectionResponse,
  PaginationModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChargeByCategoryReportService extends Api<ChargesByCategoryReportModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<ChargesByCategoryReportModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/reports/generate-consolidated-charges-by-categories`;
  }

  public export(params: PaginationModel): Observable<Blob> {
    params.limit = 100000;
    const endpoint = `${this.endpoint}/export${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public override getAll(
    paginationData?: PaginationModel | undefined
  ): Observable<CollectionResponse<ChargesByCategoryReportModel>> {
    return getAllWithoutId<ChargesByCategoryReportModel>(
      this.apiService,
      this.endpoint,
      paginationData
    );
  }
}
