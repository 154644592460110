import { Injectable } from '@angular/core';
import { FormTokenizerData, FormTokenizerErrors } from '@core/models';
import { environment } from '@env/environment';
declare let FTCaptures: any;

@Injectable({
  providedIn: 'root',
})
export class SyncfyTokenizerService {
  private routeId = environment.tokenizerRouteId;
  private tokenizerEnv = environment.tokenizerEnvironment;

  private syncfyTokenizer = FTCaptures.init(
    this.routeId,
    this.tokenizerEnv,
    // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
    function () {}
  );

  public async tokenize() {
    try {
      const tokenizationResult = this.syncfyTokenizer.tokenize(this.routeId);
      return tokenizationResult;
    } catch (error) {
      return null;
    }
  }

  public async validate(): Promise<{
    error: boolean;
    result: FormTokenizerErrors | null;
  }> {
    try {
      const validationResults = await this.syncfyTokenizer.validate(
        this.routeId
      );

      if (validationResults.hasErrors) {
        return this.handleErrors(validationResults.fields);
      }

      return { error: false, result: null };
    } catch (error) {
      return { error: true, result: null };
    }
  }

  public initForm(data: FormTokenizerData[]): void {
    data.forEach((field) => {
      this.initField(field);
    });
  }

  private initField(data: FormTokenizerData): void {
    this.syncfyTokenizer.field(`#${data.id}`, data);
  }

  private handleErrors(fields: any): {
    error: boolean;
    result: FormTokenizerErrors;
  } {
    const errorMessages: {
      [key: string]: { required: string; error: boolean };
    } = Object.entries(JSON.parse(JSON.stringify(fields))).reduce(
      (acc, [key, value]) => {
        if (
          value &&
          typeof value === 'object' &&
          'isvalid' in value &&
          !value.isvalid
        ) {
          acc[key] = { required: 'El campo es requerido', error: true };
        }
        return acc;
      },
      {} as { [key: string]: { required: string; error: boolean } }
    );

    return { error: true, result: errorMessages };
  }
}
