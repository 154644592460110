import {
  Computed,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import {
  CurrentUserModel,
  FeatureFlagAccessModel,
  FeatureFlagCodes,
  GuardianModel,
  SingleItemStore,
  StudentModel,
} from '@core/models';
import { FeatureFlagsAccessService, ProfileService } from '@core/services';
import { ProfileService as AdminProfileService } from '@core/services/admin';
import { State } from '@ngxs/store';
import { Observable, map, tap } from 'rxjs';

export class CurrentUserStateModel extends SingleItemStore<
  CurrentUserModel | undefined
> {}

@Persistence()
@StateRepository()
@State<CurrentUserStateModel>({
  name: 'currentUser',
})
@Injectable()
export class CurrentUserState extends NgxsDataRepository<CurrentUserStateModel> {
  constructor(
    private profileService: ProfileService,
    private adminProfileService: AdminProfileService,
    private featureFlag: FeatureFlagsAccessService
  ) {
    super();
  }

  @Computed()
  public get currentUserStudentsSchools(): (string | undefined)[] | undefined {
    return this.snapshot.props?.students.map(
      (student: StudentModel) =>
        student.school?.id || student.schoolNextCycle?.id
    );
  }

  @Computed()
  public get currentUser(): CurrentUserModel | undefined {
    return this.snapshot.props;
  }

  @Computed()
  public get currentUser$(): Observable<CurrentUserModel | undefined> {
    return this.state$.pipe(
      map((currentUserState) => {
        return currentUserState.props;
      })
    );
  }

  @Computed()
  public get userEmail(): string {
    return this.snapshot.props?.email || '';
  }

  @Computed()
  public get userRole(): string {
    return this.snapshot.props?.role || '';
  }

  public getFeatureFlag(
    featureFlagCode: FeatureFlagCodes
  ): FeatureFlagAccessModel | undefined {
    return this.snapshot.props?.featureFlags &&
      this.snapshot.props.featureFlags.length
      ? this.snapshot.props.featureFlags.find(
          (ff) => ff.featureFlag.code === featureFlagCode
        )
      : undefined;
  }

  public featureFlagStatus(featureFlagCode: FeatureFlagCodes): boolean {
    const featureFlag = this.getFeatureFlag(featureFlagCode);
    if (!featureFlag) {
      return false;
    }
    const status = this.getFeatureFlag(featureFlagCode)?.access.map(
      (access) => access.status
    );
    if (status?.includes('inactive') || status?.includes('maintenance')) {
      return false;
    }
    return true;
  }

  public getCurrentUser(): Observable<CurrentUserModel> {
    return this.profileService.get().pipe(
      tap((currentUser: CurrentUserModel) => {
        this.patchState({ props: currentUser });
      })
    );
  }

  public getCurrentUserStudents(): Observable<StudentModel[]> {
    return this.state$.pipe(
      map((currentUserStateModel) => {
        return currentUserStateModel.props?.students || [];
      })
    );
  }

  public getCurrentUserGuardians(): Observable<GuardianModel[]> {
    return this.state$.pipe(
      map((currentUserStateModel) => {
        return currentUserStateModel.props?.guardians || [];
      })
    );
  }

  public getFullName(): Observable<string> {
    return this.state$.pipe(
      map((currentUserState) => {
        return currentUserState.props
          ? `${currentUserState.props.name} ${currentUserState.props.lastName} ${currentUserState.props.secondLastName}`
          : '';
      })
    );
  }

  public updateAdminUser(data: CurrentUserModel): Observable<CurrentUserModel> {
    return this.adminProfileService.update(data).pipe(
      tap((currentUser: CurrentUserModel) => {
        this.patchState({ props: currentUser });
      })
    );
  }

  public updateCurrentUser(
    data: Partial<CurrentUserModel>
  ): Observable<CurrentUserModel> {
    return this.profileService.update(data).pipe(
      tap((currentUser: CurrentUserModel) => {
        this.patchState({ props: currentUser });
      })
    );
  }

  public saveFeatureFlags(
    featureFlag: FeatureFlagAccessModel | undefined
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (featureFlag) {
      this.ctx.setState((state: any) => {
        const currentFeatureFlags = state.props?.featureFlags || [];
        return {
          ...state,
          props: {
            ...state.props,
            featureFlags: [...currentFeatureFlags, featureFlag],
          },
        };
      });
    }
  }
}
