import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { IncomeConcentrationReportModel } from '@core/models/reports/income-concentration.model';
import { IncomeConcentrationReportService } from '@core/services/admin/reports/income-concentration/income-concentration.service';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<IncomeConcentrationReportModel>>({
  name: 'IncomeConcentrationReport',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class IncomeConcentrationReportState extends GenericState<IncomeConcentrationReportModel> {
  override service: IncomeConcentrationReportService;
  constructor(
    incomeConcentrationReportService: IncomeConcentrationReportService
  ) {
    super();
    this.service = incomeConcentrationReportService;
  }
}
