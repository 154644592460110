import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import {
  ADMIN_UNLOGGED_DEFAULT_ROUTE,
  USER_UNLOGGED_DEFAULT_ROUTE,
} from '@core/constants';
import { isLoggedInFn } from '@core/functions';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

export const isLoggedGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  const isLoggedIn = isLoggedInFn();
  const pathToNavigate = router.url.includes('/admin')
    ? ADMIN_UNLOGGED_DEFAULT_ROUTE
    : USER_UNLOGGED_DEFAULT_ROUTE;
  return !isLoggedIn ? store.dispatch(new Navigate([pathToNavigate])) : true;
};
