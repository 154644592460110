import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { PaymentMethodModel } from '@core/models';
import { PaymentMethodsService } from '@core/services/payment-methods/payment-methods.service';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { Observable, finalize, map, pipe, switchMap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<PaymentMethodModel>>({
  name: 'paymentMethod',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class PaymentMethodState extends GenericState<PaymentMethodModel> {
  override service: PaymentMethodsService;
  constructor(private paymentMethodsService: PaymentMethodsService) {
    super();
    this.service = paymentMethodsService;
  }

  public createPaymentMethod(
    payment: PaymentMethodModel,
    tokenize?: boolean
  ): Observable<PaymentMethodModel> {
    const service = tokenize
      ? this.paymentMethodsService.createPaymentMethod(payment)
      : this.paymentMethodsService.post(payment);
    return service.pipe(
      finalize(() => {
        this.patchState({
          isLoading: false,
        });
      }),
      pipe((result): Observable<PaymentMethodModel> => {
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        this.getAllByPage({ page: lastPage }, true);
        return result;
      })
    );
  }

  public updatePaymentMethod(
    payment: PaymentMethodModel
  ): Observable<PaymentMethodModel[]> {
    this.toggleLoading();
    return this.paymentMethodsService.updatePayment(payment).pipe(
      finalize(() => {
        this.patchState({
          isLoading: false,
        });
      }),
      switchMap((): Observable<PaymentMethodModel[]> => {
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        return this.getAllByPage({ page: lastPage }, true);
      })
    );
  }

  public getPaymentMethodById(id: string): Observable<PaymentMethodModel> {
    return this.state$.pipe(
      map((businessStateModel) => {
        return businessStateModel.items[id];
      })
    );
  }

  public deletePaymentMethod(id: string): Observable<PaymentMethodModel[]> {
    return this.paymentMethodsService.delete(id).pipe(
      finalize(() => {
        this.patchState({
          isLoading: false,
        });
      }),
      switchMap((): Observable<PaymentMethodModel[]> => {
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        return this.getAllByPage({ page: lastPage }, true);
      })
    );
  }
}
