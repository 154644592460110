import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { CollectionResponse, RoleModel } from '@core/models';
import { RolesService } from '@core/services';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { formatMultiStoreData } from '@core/utils/format-multi-store-data.util';
import { State } from '@ngxs/store';
import { Observable, map, tap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<RoleModel>>({
  name: 'role',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class RoleState extends GenericState<RoleModel> {
  override service: RolesService;
  constructor(private rolesService: RolesService) {
    super();
    this.service = rolesService;
  }

  public getAllRoles(): Observable<CollectionResponse<RoleModel>> {
    return this.rolesService.getAll({ limit: 100 }).pipe(
      tap((content): void => {
        const { items: roles } = content.data;
        this.patchState({
          totalItems: content.data.total,
          currentPage: 1, // Current page should come from backend
          items: formatMultiStoreData(roles),
          pages: {
            1: roles.map((role) => role.id), // Current page should come from backend
          },
        });
      })
    );
  }

  public getRole(id: string) {
    if (!(this.snapshot.items[id] as RoleModel | undefined)) {
      this.toggleLoading();
      this.rolesService.getRole(id).subscribe({
        next: ({ data }) => {
          this.patchState({
            items: { ...this.snapshot.items, [id]: data },
            isLoading: false,
          });
        },
        error: () => {
          this.patchState({ isLoading: false });
        },
      });
    }
  }

  public roleById$(id: string): Observable<RoleModel | undefined> {
    return this.state$.pipe(
      map((roleStateModel) => {
        return roleStateModel.items[id];
      })
    );
  }
}
