import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getAllWithoutId } from '@core/functions';
import { CollectionResponse, PaginationModel } from '@core/models';
import { GeneralIncomeConcentrationReportModel } from '@core/models/reports/general-income-concentration.model';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeneralIncomeConcentrationReportService extends Api<GeneralIncomeConcentrationReportModel> {
  override endpoint: string;
  private readonly LIST_GENERAL_INCOME_CONCENTRATION_REPORT_URI = `/v1/admin/reports/collection-notices-concentrate`;
  constructor(
    protected override readonly apiService: ApiService<GeneralIncomeConcentrationReportModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}${this.LIST_GENERAL_INCOME_CONCENTRATION_REPORT_URI}`;
  }

  public export(params: PaginationModel): Observable<Blob> {
    params.limit = 100000;
    const endpoint = `${
      environment.apiUrl
    }/v1/admin/reports/collection-notices-concentrate/export${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public override getAll(
    paginationData?: PaginationModel | undefined
  ): Observable<CollectionResponse<GeneralIncomeConcentrationReportModel>> {
    return getAllWithoutId<GeneralIncomeConcentrationReportModel>(
      this.apiService,
      this.endpoint,
      paginationData
    );
  }
}
