import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  EventCategoryModel,
  EventModel,
  PaginationModel,
  SummarySchoolModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventsService extends Api<EventModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<EventModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/events/management`;
  }

  public getEventcategories(
    eventCode: string,
    params: PaginationModel
  ): Observable<EventCategoryModel[]> {
    const url = `${
      this.endpoint
    }/${eventCode}/categories/${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;
    return this.http.get<CollectionResponse<EventCategoryModel>>(url).pipe(
      map((respose) => {
        return respose.data.items;
      })
    );
  }

  public getEventDashboard(params: PaginationModel): Observable<any> {
    const url = `${
      environment.apiUrl
    }/v1/admin/collections/collection-notices/dashboard${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;
    return this.http.get<any>(url).pipe(
      map((respose) => {
        return respose;
      })
    );
  }

  public updateAgreedAmount(
    data: Partial<SummarySchoolModel>
  ): Observable<boolean> {
    const url = `${environment.apiUrl}/v1/admin/collections/schools/${data.schoolId}/balance-committed`;
    return this.http.put(url, data).pipe(map(() => true));
  }
}
