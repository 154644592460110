import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiModel,
  CatalogModel,
  CollectionNoticeApproveModel,
  CollectionNoticeModel,
  CollectionNoticePaymentResponse,
  CollectionNoticeRejectionHistoryModel,
  CollectionNoticeRejectionModel,
  CollectionNoticeRemoveParams,
  CollectionResponse,
  ComboboxDataModel,
  CoreCollectionNotice,
  PaginationModel,
  PaymentDetailModel,
  ReconciliationModel,
  SingleItemResponse,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { CatalogsState } from '@core/state/shared';
import { environment } from '@env/environment';
import { Observable, first, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CollectionNoticesService extends Api<CollectionNoticeModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<CollectionNoticeModel>,
    private catalogsState: CatalogsState,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/collections/collection-notices`;
  }

  public authorize(
    collectionNoticeId: string,
    collectionNoticeRejectionId: string,
    params: { authorizing_text: string }
  ): Observable<any> {
    return this.http.put(
      `${this.endpoint}/${collectionNoticeId}/reasons_for_rejection/${collectionNoticeRejectionId}`,
      {
        ...params,
      }
    );
  }

  public createCore(data: CoreCollectionNotice): Observable<any> {
    const endpoint = `${this.endpoint}/async`;
    return this.http.post(endpoint, data);
  }

  public export(
    params: PaginationModel,
    inBackground: boolean
  ): Observable<Blob> {
    params.limit = 1000000;
    const endpoint = `${this.endpoint}/export/excel${
      inBackground ? '' : ''
    }${`?${new URLSearchParams(params as any).toString()}`}`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public getAreas(): Observable<CatalogModel[]> {
    if (this.catalogsState.getItemsLength('areas') === 0) {
      const url = `${environment.apiUrl}/v1/admin/collections/reason_for_rejection_areas`;
      this.http
        .get<{ data: { items: CatalogModel[] } }>(url)
        .pipe(first())
        .subscribe((response: { data: { items: CatalogModel[] } }) => {
          this.catalogsState.setCatalogItems(response.data.items, 'areas');
        });
    }
    return this.catalogsState.getItems('areas');
  }

  public getPaymentCollectionNotices(
    params: PaginationModel
  ): Observable<CollectionNoticePaymentResponse<CollectionNoticeModel>> {
    params.limit = 100000;
    const url = `${this.endpoint}/payment${`?${new URLSearchParams(
      params
    ).toString()}`}`;
    return this.http
      .get<CollectionNoticePaymentResponse<CollectionNoticeModel>>(url)
      .pipe(
        map((respose) => {
          return respose;
        })
      );
  }

  public getRejectionHistory(
    collectionNoticeId: string
  ): Observable<CollectionNoticeRejectionHistoryModel[]> {
    return this.http
      .get<{ data: { items: CollectionNoticeRejectionHistoryModel[] } }>(
        `${this.endpoint}/${collectionNoticeId}/reasons_for_rejection`
      )
      .pipe(map((response) => response.data.items));
  }

  public getRejectionOptions(
    collectionNoticeId: string
  ): Observable<CollectionNoticeRejectionModel[]> {
    return this.http
      .get<{ data: { items: CollectionNoticeRejectionModel[] } }>(
        `${this.endpoint}/${collectionNoticeId}/reasons_for_rejection/catalog?limit=1000`
      )
      .pipe(map((response) => response.data.items));
  }

  public reject(
    collectionNoticeId: string,
    params: { reason_for_rejection_id: string; reporting_text: string }
  ): Observable<any> {
    return this.http.post(
      `${this.endpoint}/${collectionNoticeId}/reasons_for_rejection`,
      {
        ...params,
      }
    );
  }

  public remove(params: CollectionNoticeRemoveParams): Observable<any> {
    return this.http.post(
      `${this.endpoint}/${params.collection_notice}/delete`,
      {
        description: params.description,
        reference: params.reference,
      }
    );
  }
  public cancelCollectionNotice(
    id: string,
    params?: CollectionNoticeRemoveParams
  ): Observable<any> {
    return this.http.delete(`${this.endpoint}/${id}`, {
      body: params,
    });
  }

  public sendVoucher(data: {
    email: string;
    collectionNoticeId: string;
  }): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/admin/collections/collection-notices/${data.collectionNoticeId}/send-payment-email`;
    return this.http.post(apiUrl, { email: data.email } as Partial<ApiModel>);
  }

  public solve(
    collectionNoticeId: string,
    collectionNoticeRejectionId: string,
    params: { area_id: string; solving_text: string }
  ): Observable<any> {
    return this.http.put(
      `${this.endpoint}/${collectionNoticeId}/reasons_for_rejection/${collectionNoticeRejectionId}/solve`,
      {
        ...params,
      }
    );
  }

  public updateCollectionNotice(
    collectionNoticeId: string,
    data: CollectionNoticeApproveModel
  ): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/admin/collections/collection-notices/${collectionNoticeId}`;
    return this.http.put(apiUrl, data as Partial<ApiModel>);
  }

  public recalculateCollectionNotice(
    collectionNoticeId: string
  ): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/admin/discounts/apply/discounts_scholarships`;
    return this.http.post(apiUrl, {
      collection_notice_id: collectionNoticeId,
      ignore_lock: true,
    });
  }

  public updateCollectionNoticeAmount(
    collectionNoticeId: string,
    data: Partial<CollectionNoticeModel>
  ): Observable<any> {
    const apiUrl = `${this.endpoint}/updateFinalAmount/${collectionNoticeId}`;
    return this.http.put(apiUrl, data as Partial<ApiModel>);
  }

  public creditNote(data: {
    rfcId: string;
    collectionNoticeId: string;
    isPUGInvoice: boolean;
  }): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/admin/invoicing/collection_notices/${data.collectionNoticeId}/credit_note`;
    const obj = {
      rfc_public: data.isPUGInvoice,
      ...(!data.isPUGInvoice && { rfc_id: data.rfcId }),
    };
    return this.http.put(apiUrl, obj as Partial<ApiModel>);
  }

  public applyManualPayment(
    collectionNoticeId: string,
    paymentType: string
  ): Observable<PaymentDetailModel> {
    const apiUrl = `${environment.apiUrl}/v1/payments/payment-management/${paymentType}/external/${collectionNoticeId}`;
    return this.http.put(apiUrl, {}).pipe(
      map((result) => {
        return result as PaymentDetailModel;
      })
    );
  }

  public getCollectionNoticeByReference(
    reference: string
  ): Observable<CollectionNoticeModel> {
    const params: PaginationModel = { reference, page: 1, limit: 1 };
    const url = `${this.endpoint}${`?${new URLSearchParams(
      params
    ).toString()}`}`;
    return this.http
      .get<CollectionNoticePaymentResponse<CollectionNoticeModel>>(url)
      .pipe(
        map((response) => {
          return response.data.items[0];
        })
      );
  }

  public reverse(collectionNoticeId: string): Observable<boolean> {
    const apiUrl = `${this.endpoint}/${collectionNoticeId}/reverse`;
    return this.http
      .post(apiUrl, {} as Partial<ApiModel>)
      .pipe(map(() => true));
  }

  public getAllCollectionNoticeByReference(
    reference: string
  ): Observable<CollectionNoticeModel[]> {
    const params: PaginationModel = {
      reference,
      page: 1,
      status: 'Vencido,Pendiente',
    };
    const url = `${this.endpoint}${`?${new URLSearchParams(
      params
    ).toString()}`}`;
    return this.http
      .get<CollectionNoticePaymentResponse<CollectionNoticeModel>>(url)
      .pipe(
        map((response) => {
          return response.data.items;
        })
      );
  }

  public refundPayment(
    collectionNoticeId: string,
    data: Partial<CollectionNoticeModel>
  ): Observable<boolean> {
    const apiUrl = `${environment.apiUrl}/v1/admin/payments/payment-management/return-payment/${collectionNoticeId}`;
    return this.http.put(apiUrl, data).pipe(map(() => true));
  }

  public sendCRMNotification(
    collectionNoticeId: string,
    data: {
      source: string;
    }
  ): Observable<any> {
    const apiUrl = `${this.endpoint}/${collectionNoticeId}/notify-payment-crm`;
    return this.http.post(apiUrl, data);
  }

  public conciliate(data: ReconciliationModel): Observable<any> {
    const conciliationData = data.conciliation_data;
    data.conciliation_data.voucher_net_amount = parseFloat(
      conciliationData.voucher_net_amount
    );
    data.conciliation_data.voucher_payment_date =
      conciliationData.voucher_payment_date
        ? conciliationData.voucher_payment_date.toISOString().split('T')[0]
        : [null];

    return this.http.put(`${this.endpoint}/conciliate`, data);
  }

  public paymentInValidation(
    collectionNoticeId: string,
    data: {
      source: string;
      dt_further_payment_validation: string;
    }
  ): Observable<any> {
    const apiUrl = `${this.endpoint}/${collectionNoticeId}/further-payment-validation`;
    return this.http.post(apiUrl, data);
  }

  public removePaymentInValidation(
    collectionNoticeId: string
  ): Observable<SingleItemResponse<CollectionNoticeModel>> {
    const apiUrl = `${this.endpoint}/${collectionNoticeId}/remove-further-payment-validation`;
    return this.http.put<SingleItemResponse<CollectionNoticeModel>>(apiUrl, {});
  }

  public splitCollectionNotice(
    data: { months: string; billing_start_date: string },
    collectionNoticeId: string
  ): Observable<any> {
    const apiUrl = `${this.endpoint}/${collectionNoticeId}/split`;
    return this.http.put(apiUrl, data);
  }

  public getRefundReasons(source: string): Observable<ComboboxDataModel[]> {
    const apiUrl = `${environment.apiUrl}/v1/admin/payments/devolution/catalog?cn_source=${source}`;
    return this.http.get<CollectionResponse<ComboboxDataModel>>(apiUrl).pipe(
      map((result) => {
        return result.data.items;
      })
    );
  }
}
