import { Injectable } from '@angular/core';
import { MessageService } from '@progress/kendo-angular-l10n';

@Injectable()
export class KendoSpanishService extends MessageService {
  /**
   * If a translation is missing, look for it in https://github.com/telerik/kendo-angular-messages/tree/develop/messages and add it
   */
  private messages: { [key: string]: string } = {
    'kendo.calendar.nextButtonTitle': 'Navegar a la siguiente vista',
    'kendo.calendar.parentViewButtonTitle': 'Navegar a la vista padre',
    'kendo.calendar.prevButtonTitle': 'Navegar a la vista previa',
    'kendo.calendar.today': 'Hoy',
    'kendo.combobox.clearTitle': 'Limpiar',
    'kendo.combobox.noDataText': 'No hay datos disponibles',
    'kendo.datepicker.nextButtonTitle': 'Navegar a la siguiente vista',
    'kendo.datepicker.parentViewButtonTitle': 'Navegar a la vista padre',
    'kendo.datepicker.prevButtonTitle': 'Navegar a la vista previa',
    'kendo.datepicker.today': 'Hoy',
    'kendo.datepicker.toggle': 'Mostrar/ocultar calendario',
    'kendo.dialog.closeTitle': 'Cerrar',
    'kendo.dropdownlist.noDataText': 'No hay datos disponibles',
    'kendo.fileselect.dropFilesHere': 'Suelta los archivos aquí para cargarlos',
    'kendo.fileselect.invalidFileExtension': 'Tipo de archivo no permitido',
    'kendo.fileselect.invalidMaxFileSize': 'Archivo muy grande',
    'kendo.fileselect.invalidMinFileSize': 'Archivo muy pequeño',
    'kendo.fileselect.remove': 'Quitar',
    'kendo.fileselect.select': 'Seleccionar archivos...',
    'kendo.grid.detailCollapse': 'Colapsar detalles',
    'kendo.grid.detailExpand': 'Expandir detalles',
    'kendo.grid.noRecords': 'No hay datos disponibles.',
    'kendo.grid.pagerFirstPage': 'Ir a la primera página',
    'kendo.grid.pagerItems': 'registros',
    'kendo.grid.pagerItemsPerPage': 'Registros por página',
    'kendo.grid.pagerLastPage': 'Ir a la última página',
    'kendo.grid.pagerNextPage': 'Ir a la página siguiente',
    'kendo.grid.pagerOf': 'de',
    'kendo.grid.pagerPage': 'Página',
    'kendo.grid.pagerPreviousPage': 'Ir a la página anterior',
    'kendo.multiselect.clearTitle': 'Limpiar',
    'kendo.multiselect.noDataText': 'No hay datos disponibles',
    'kendo.multiselecttree.clearTitle': 'Limpiar',
    'kendo.multiviewcalendar.nextButtonTitle': 'Navegar a la siguiente vista',
    'kendo.multiviewcalendar.parentViewButtonTitle': 'Navegar a la vista padre',
    'kendo.multiviewcalendar.prevButtonTitle': 'Navegar a la vista previa',
    'kendo.multiviewcalendar.today': 'Hoy',
    'kendo.notification.closeTitle': 'Cerrar',
    'kendo.numerictextbox.decrement': 'Disminuir valor',
    'kendo.numerictextbox.increment': 'Incrementar valor',
    'kendo.pdfviewer.downloadTitle': 'Descargar',
    'kendo.pdfviewer.fitToPage': 'Ajustar a la página',
    'kendo.pdfviewer.fitToWidth': 'Ajustar al ancho',
    'kendo.pdfviewer.pagerFirstPage': 'Ir a la primera página',
    'kendo.pdfviewer.pagerInputLabel': 'Escribe un número de página',
    'kendo.pdfviewer.pagerInputTitle': 'Número de página',
    'kendo.pdfviewer.pagerLastPage': 'Ir a la última página',
    'kendo.pdfviewer.pagerNextPage': 'Ir a la página siguiente',
    'kendo.pdfviewer.pagerOf': 'de',
    'kendo.pdfviewer.pagerPage': 'Página',
    'kendo.pdfviewer.pagerPreviousPage': 'Ir a la página anterior',
    'kendo.pdfviewer.panningTitle': 'Habilitar paneo',
    'kendo.pdfviewer.printTitle': 'Imprimir',
    'kendo.pdfviewer.searchCloseTitle': 'Cerrar',
    'kendo.pdfviewer.searchInputPlaceholder': 'Buscar',
    'kendo.pdfviewer.searchMatchCaseTitle': 'Distinguir mayúsculas/minúsculas',
    'kendo.pdfviewer.searchMatchesOf': 'de',
    'kendo.pdfviewer.searchNextMatchTitle': 'Siguiente resultado',
    'kendo.pdfviewer.searchPreviousMatchTitle': 'Resultado anterior',
    'kendo.pdfviewer.searchTitle': 'Buscar',
    'kendo.pdfviewer.selectionTitle': 'Habilitar selección',
    'kendo.pdfviewer.zoomInputPlaceholder': 'Elegir nivel de zoom',
    'kendo.pdfviewer.zoomInTitle': 'Acercar',
    'kendo.pdfviewer.zoomOutTitle': 'Alejar',
    'kendo.textbox.clear': 'Limpiar',
  };

  public override get(key: string): string {
    return this.messages[key];
  }
}
