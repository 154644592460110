import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { CollectionNoticeModel } from '@core/models';
import { CollectionNoticesService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<CollectionNoticeModel>>({
  name: 'adminCollectionNotices',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class CollectionNoticesState extends GenericState<CollectionNoticeModel> {
  override service: CollectionNoticesService;
  constructor(private collectionNoticesService: CollectionNoticesService) {
    super();
    this.service = collectionNoticesService;
  }
}
