import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdministrativeRuleHistoryModel,
  AdministrativeRulesFrecuencyModel,
  PaginationModel,
  SingleItemResponse,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdministrativeRuleHistoryService extends Api<AdministrativeRuleHistoryModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<AdministrativeRuleHistoryModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/students/administrativeRules`;
  }
  public exportAdministrativeRuleHistory(
    params: PaginationModel
  ): Observable<Blob> {
    params.limit = 1000000;
    params.page = 1;
    const endpoint = `${this.endpoint}/export${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public updatePaymentFrecuency(
    data: AdministrativeRulesFrecuencyModel
  ): Observable<boolean> {
    const endpoint = `${environment.apiUrl}/v1/admin/students/administrative-rules/${data.administrative_rule_id}/change-frequency`;
    return this.http.patch(endpoint, data).pipe(map(() => true));
  }

  public show(id: string): Observable<AdministrativeRuleHistoryModel> {
    const endpoint = `${environment.apiUrl}/v1/admin/students/administrative-rules/${id}`;
    return this.http
      .get<SingleItemResponse<AdministrativeRuleHistoryModel>>(endpoint)
      .pipe(map((response) => response.data));
  }
}
