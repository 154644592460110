import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BillingLogModel,
  CollectionResponse,
  InvoiceModel,
  InvoicingDataModel,
  PaginationModel,
  SingleItemResponse,
  StampingParamsModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { pickBy } from 'lodash';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService extends Api<InvoiceModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<InvoiceModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/invoicing/invoices`;
  }

  public stamp(
    schoolId: string,
    startDate: string,
    endDate: string
  ): Observable<InvoiceModel> {
    const endpoint = `${environment.apiUrl}/v1/admin/invoicing/payments/general/stamp`;
    const params = JSON.stringify({
      school_id: schoolId,
      dt_start: startDate,
      dt_end: endDate,
    });
    return this.apiService.post(endpoint, params).pipe(
      map((data: CollectionResponse<InvoiceModel>) => {
        if (Array.isArray(data.data)) {
          return data.data[0];
        }
        return data.data;
      })
    );
  }

  public cancel(
    invoiceId: string,
    withCreditNote = false
  ): Observable<InvoiceModel> {
    const endpoint = withCreditNote
      ? `${environment.apiUrl}/v1/admin/invoicing/invoices/${invoiceId}/cancel_with_credit_note`
      : `${environment.apiUrl}/v1/admin/invoicing/invoices/${invoiceId}/cancel`;

    return this.apiService.put(endpoint).pipe(
      map((data: SingleItemResponse<InvoiceModel>) => {
        return data.data;
      })
    );
  }

  public export(params: PaginationModel): Observable<Blob> {
    params.limit = 1000000;
    params.page = 1;
    const endpoint = `${
      this.endpoint
    }/history/export/excel${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public exportZip(params: PaginationModel): Observable<Blob> {
    params.limit = 1000000;
    params.page = 1;
    const endpoint = `${
      this.endpoint
    }/history/export/zip${`?${new URLSearchParams(params as any).toString()}`}`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public exportPDF(id: string): Observable<Blob> {
    const endpoint = `${this.endpoint}/${id}/pdf`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public restamp(invoiceId: string): Observable<InvoiceModel> {
    const endpoint = `${environment.apiUrl}/v1/admin/invoicing/invoices/${invoiceId}/restamp`;
    return this.apiService.put(endpoint).pipe(
      map((data: SingleItemResponse<InvoiceModel>) => {
        if (Array.isArray(data.data)) {
          return data.data[0];
        }
        return data.data;
      })
    );
  }

  public generateCreditNote(data: {
    collectionNoticeId: string;
    rfcId: string;
    isGeneralPublic: boolean;
    devolution_reason_id?: string;
  }): Observable<boolean> {
    let params: any = data.isGeneralPublic ? { rfc_id: data.rfcId } : {};
    params = data.devolution_reason_id
      ? { ...params, devolution_reason_id: data.devolution_reason_id }
      : params;
    const apiUrl = `${environment.apiUrl}/v1/admin/invoicing/collection_notices/${data.collectionNoticeId}/credit_note`;
    return this.http.put(apiUrl, params).pipe(map(() => true));
  }

  public getLogDetail(invoiceId: string): Observable<BillingLogModel[]> {
    const apiUrl = `${this.endpoint}/${invoiceId}/logs`;
    return this.http.get(apiUrl).pipe(
      map((result) => {
        return result as BillingLogModel[];
      })
    );
  }

  public getAvailableRFCs(invoiceId: string): Observable<InvoicingDataModel[]> {
    const params = { invoice_id: invoiceId };

    const endpoint = `${
      environment.apiUrl
    }/v1/admin/invoicing/available-rfc${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get<CollectionResponse<InvoicingDataModel>>(endpoint).pipe(
      map((data) => {
        return data.data.items;
      })
    );
  }

  public getInvoiceDataByGuardian(
    guardianId: string
  ): Observable<InvoicingDataModel[]> {
    const params = { guardian_id: guardianId };
    const endpoint = `${
      environment.apiUrl
    }/v1/account/invoicing/invoicing-data${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get<CollectionResponse<InvoicingDataModel>>(endpoint).pipe(
      map((data) => {
        return data.data.items;
      })
    );
  }

  public stampCollectionNotice(
    collectionNoticeId: string,
    rfcId: string,
    stampDate: string | null
  ): Observable<InvoiceModel> {
    const endpoint = `${environment.apiUrl}/v1/admin/invoicing/collection_notices/${collectionNoticeId}/stamp`;
    let params: StampingParamsModel = {
      rfc_id: rfcId,
      stamp_date: stampDate,
    };
    params = pickBy(params, (value) => value !== null) as StampingParamsModel;
    return this.apiService.post(endpoint, JSON.stringify(params)).pipe(
      map((data: CollectionResponse<InvoiceModel>) => {
        if (Array.isArray(data.data)) {
          return data.data[0];
        }
        return data.data;
      })
    );
  }
}
