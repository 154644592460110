import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { ParameterModel } from '@core/models';
import { ParametersService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { Observable, finalize, switchMap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<ParameterModel>>({
  name: 'parameters',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class ParametersState extends GenericState<ParameterModel> {
  override service: ParametersService;
  constructor(private parametersService: ParametersService) {
    super();
    this.service = parametersService;
  }

  public createParameter(
    parameter: ParameterModel
  ): Observable<ParameterModel[]> {
    this.toggleLoading();
    return this.parametersService.post(parameter).pipe(
      finalize(() => {
        this.toggleLoading();
      }),
      switchMap((): Observable<ParameterModel[]> => {
        this.toggleLoading();
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        return this.getAllByPage({ page: lastPage }, true);
      })
    );
  }

  public updateParameter(
    parameter: ParameterModel,
    parameterId: string
  ): Observable<ParameterModel> {
    this.toggleLoading();
    return this.parametersService.update(parameter, parameterId).pipe(
      finalize(() => {
        this.toggleLoading();
      })
    );
  }

  public deleteParameter(id: string): Observable<ParameterModel[]> {
    this.toggleLoading();
    return this.parametersService.delete(id).pipe(
      finalize(() => {
        this.toggleLoading();
      }),
      switchMap((): Observable<ParameterModel[]> => {
        return this.getAllByPage({ page: this.snapshot.currentPage }, true);
      })
    );
  }
}
