import { CHILD_OF_ALUMNI_OPTIONS } from '@core/constants';

const OPTIONS = CHILD_OF_ALUMNI_OPTIONS;
export function isChildOfAlumni(id: number) {
  const item = OPTIONS.find((item) => item.id == id);
  if (item) {
    return item.value;
  }

  return 'No especificado';
}
