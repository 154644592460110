import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRangeModel } from '@core/models';
import { DateRangePopupComponent } from '@progress/kendo-angular-dateinputs';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true,
    },
  ],
})
export class DateRangeComponent implements OnInit {
  @Input() format = 'dd/MMM/yyyy';
  @Input() text = 'Rango de Fechas';
  @Input() clearable = false;
  @Input() showCurrentMonth = false;
  @Output()
  public valueChange: EventEmitter<DateRangeModel> = new EventEmitter();
  public disabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public startDate: Date | undefined;
  public endDate: Date | undefined;
  public dateRange: DateRangeModel = {
    startDate: undefined,
    endDate: undefined,
    value: '',
  };

  constructor(private datePipe: DatePipe) {}

  @Input()
  public set disabled(disabled: boolean) {
    this.disabled$.next(disabled);
  }

  public ngOnInit(): void {
    if (this.showCurrentMonth) {
      this.setCurrentMonth();
    }
  }

  public onValueChange(popup: DateRangePopupComponent) {
    this.setRangeDate();

    if (!(this.dateRange.startDate && this.dateRange.endDate)) {
      return;
    }
    popup.toggle();
    this.valueChange.emit(this.dateRange);
  }

  public clear(event: PointerEvent) {
    this.dateRange = {
      startDate: undefined,
      endDate: undefined,
      value: '',
    };
    this.startDate = undefined;
    this.endDate = undefined;
    this.valueChange.emit(this.dateRange);
    event.stopPropagation();
  }

  private setCurrentMonth(): void {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    this.startDate = firstDayOfMonth;
    this.endDate = lastDayOfMonth;
    this.setRangeDate();
    this.valueChange.emit(this.dateRange);
  }

  private setRangeDate(): void {
    this.dateRange.startDate =
      this.datePipe.transform(this.startDate, this.format) || '';
    this.dateRange.endDate =
      this.datePipe.transform(this.endDate, this.format) || '';

    this.dateRange.value = `${
      this.datePipe.transform(this.startDate, this.format) || ''
    }  -  ${this.datePipe.transform(this.endDate, this.format) || ''}`;
  }
}
