import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConceptCategoryModel, ConceptModel } from '@core/models/concept.model';
import { environment } from '@env/environment';
import { values } from 'lodash';
import { Observable, map } from 'rxjs';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ConceptService extends Api<ConceptModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<ConceptModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/concepts/concepts`;
  }

  public getCoreConcepts(): Observable<ConceptModel[]> {
    return this.getAll({ page: 1, limit: 1000 }).pipe(
      map((response) => {
        const coreItems = [
          'inscripción',
          'reinscripción',
          'colegiatura',
          'category',
        ];
        return values(response.data.items).filter((item) =>
          coreItems.some((string) =>
            item.conceptCategory.name.toLowerCase().includes(string)
          )
        );
      })
    );
  }

  public getCoreConceptCategories(): Observable<ConceptCategoryModel[]> {
    return this.http
      .get<{ data: { items: ConceptCategoryModel[] } }>(
        `${environment.apiUrl}/v1/admin/concepts/categories?limit=200`
      )
      .pipe(
        map((response) => {
          const coreItems = ['inscripción', 'reinscripción', 'colegiatura'];
          return values(response.data.items).filter((item) =>
            coreItems.some((string) => item.name.toLowerCase().includes(string))
          );
        })
      );
  }
}
