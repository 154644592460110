import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  StudentConceptsModel,
  StudentModel,
  StudentRecurrentModel,
} from '@core/models';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService extends Api<StudentModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<StudentModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/account/family-configuration`;
  }

  public getFamilyConcepts(): Observable<StudentConceptsModel[]> {
    const endpoint = `${environment.apiUrl}/v1/account/concepts-by-student`;
    return this.http
      .get<CollectionResponse<StudentConceptsModel>>(endpoint)
      .pipe(
        map((result: any) => {
          return result.data;
        })
      );
  }
  public getRecurrentConfigurations(): Observable<StudentRecurrentModel[]> {
    const endpoint = `${environment.apiUrl}/v1/account/recurrent-charges`;
    return this.http
      .get<CollectionResponse<StudentRecurrentModel>>(endpoint)
      .pipe(
        map((result) => {
          return result.data.items;
        })
      );
  }
}
