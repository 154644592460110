import { FrontEndPermission, FrontEndPermissionMap } from '@core/models';

export const ADMIN_FRONTEND_PERMISSIONS = {
  accounting: 'accounting',
  billing: {
    generalPublic: 'billing-general-public-invoices',
    invoices: 'billing-invoices',
    refunds: 'billing-refunds',
    restamp: 'admin-invoicing',
  },
  collection: {
    authorization: 'collection-authorization',
    condone: 'collection-notices-condone',
    coreGeneration: 'core-collection-generation',
    deletion: 'collection-deletion',
    generation: 'collection-generation',
    recalculate: 'collection-notices-recalculate',
    rejection: 'collection-rejection',
    solving: 'collection-solving',
    toggleVisibility: 'collection-visibility',
    tpv: 'collection-tpv',
    view: 'collection-view',
    review: 'collection-review',
    dryRun: 'collection-dryrun',
    adjustAmount: 'collection-notices-adjust-amount',
    creditNote: 'collection-notices-credit-note',
    anomalies: 'collection-notices-anomalies',
    failedRecurringPayment: 'admin-reports-payment-fails',
    accessories: 'admin-collections',
    relatedPayments: 'collection-accessories',
    deleteScholarships: 'collections-scholarship',
    reverse: 'collection-notices-reverse',
    refund: 'collection-notices-refund',
    notifyCRM: 'collection-notices-notify-crm',
    cancel: 'collection-notices-deletion',
    paymentValidation: 'further-payment-validation',
    split: 'collection-notices-split',
    updateAmount: 'update-amount',
    updateStatus: 'update-status',
    snapshot: 'snapshot-collection-notices',
    financialEntries: 'collections-financial-entries',
    generateAllCycle: 'collection-notices-massive',
  },
  concepts: {
    assignment: 'concepts-assignment',
    concepts: 'concepts',
    deletion: 'concepts-deletion',
  },
  external: {
    collectionNotices: 'external-collection-notices',
    billing: 'external-billing',
    payments: 'external-payments',
    resendEmail: 'external-resend-email',
  },
  configuration: {
    administrativeRules: 'configuration-rules',
    business: 'configuration-business',
    cycles: 'configuration-cycles',
    deleteStudents: 'delete-students',
    families: 'configuration-families',
    parameters: 'configuration-parameters',
    prices: 'configuration-prices',
    regions: 'configuration-regions',
    rvoe: 'configuration-rvoes',
    schools: 'configuration-schools',
    sections: 'configuration-sections',
    mergeStudents: 'configuration-merge-students',
    paymentFrequency: 'admin-configuration',
    featureFlags: 'feature-flags',
    students: 'families-student-parameter',
    familyParameter: 'configuration-families-parameter',
    childNumber: 'configuration-families-child-number',
  },
  default: 'default',
  discounts: {
    administration: 'discounts-admin',
    rules: 'discounts-rules',
  },
  reports: {
    automaticCollectionGeneration: 'reports-automatic-collection-generation',
    collectionGeneration: 'reports-collection-notice-generation',
    reports: 'reports',
    inRiCollectionsReport: 'admin-reports',
    ordersReport: 'admin-orders',
    scholarshipReport: 'scholarship-report',
    pastDueReport: 'past-due-collection-notice',
    incomeConcentration: 'income-concentration',
    generalIncomeConcentration: 'general-income-concentration',
    balance: 'balance-payment',
    chargesByCategory: 'charges-by-categories',
    reportManager: 'report-manager',
    accountState: 'account-state',
    dailyPayments: 'daily-payment-report',
  },
  scholarships: 'scholarships',
  scholarshipsUpload: 'scholarships-upload',
  scholarshipsApprove: 'scholarships-approve',
  security: {
    administrators: 'security-admins',
    audit: 'users-logs',
    crm: 'crm-logs',
    impersonate: 'security-impersonate',
    roles: 'security-roles',
    users: 'security-users',
    familyLog: 'security-family-log',
  },
  abandonedPayments: 'admin-payments',
  paymentStatus: 'admin-payments',
  discountCoupons: {
    catalog: 'discounts-administration',
    history: 'discount-coupons-history',
    apply: 'discount-coupons-apply',
    remove: 'discount-coupons-remove',
  },
  events: {
    crud: 'events-crud',
    update: 'events-update',
    dashboard: 'events-dashboard',
    collections: 'events-collections',
    payments: 'events-payments',
    invoices: 'events-invoices',
    committedAmount: 'events-committed-amount',
  },
  payments: {
    attempts: 'admin-payments',
    history: 'payments',
    reassign: 'payments-tpv-move',
    reconciliation: 'payments-reconciliation',
    chargebacks: 'payments-chargebacks',
    paymentsDetail: 'payments-external',
    adjustAmount: 'adjust-payment-amount',
    includeEvents: 'history-include-event',
    showTotal: 'history-show-totals',
    showCommissions: 'history-show-commissions',
    showAccountingEntries: 'history-show-accounting-entries',
    fees: 'payments-commissions-report',
    validationInProcess: 'payments-futher-payment-validation',
    tvpReport: 'admin-payments-tpv',
    adjust: 'collection-notices-adjust-to-payment',
    partialRefunds: {
      surchargeAccount: 'refund-surcharges',
      tuitionInRi: 'refund-advance-payment-ri-in',
      tuitionAdvance: 'refund-advance-payment-tuition',
    },
    downloadReference: 'collection-notices-download-reference',
    downloadZip: 'invoices-download-all-by-school',
  },
  ledgerAccountDetail: 'ledger-seats-show-details',
  switcher: {
    hubAdmin: 'hub-admin',
    hubPayments: 'hub-payments',
    hubReports: 'hub-reports',
  },
  ledgerAccountZip: 'admin-ledger-seats-split',
};

export const ADMIN_DEFAULT_PERMISSIONS: FrontEndPermission = {
  list: [
    'account.profile.list',
    'account.users.list',
    'admin-auth.login.list',
    'admin.modules.list',
    'concepts.concepts.list',
  ],
  update: [
    'account.profile.update',
    'account.users.update',
    'admin-auth.login.update',
  ],
};

export const ADMIN_PERMISSIONS_MAP: FrontEndPermissionMap = {
  [ADMIN_FRONTEND_PERMISSIONS.accounting]: {
    list: ['admin-accounting.ledger-seats.list'],
    update: ['admin-accounting.ledger-seats.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.billing.generalPublic]: {
    list: ['admin-invoicing.invoices-general.update'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.billing.invoices]: {
    list: ['admin-invoicing.invoices.list'],
    update: ['admin-invoicing.invoices.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.billing.refunds]: {
    list: ['admin-invoicing.returns.list'],
    update: ['admin-invoicing.returns.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.authorization]: {
    list: ['admin-collections.collection-notices-approve.update'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.condone]: {
    list: ['admin-collections.collection-notices-condone.update'],
    update: ['admin-collections.collection-notices-condone.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.coreGeneration]: {
    list: [],
    update: ['admin-collections.collection-notices-create-core.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.deletion]: {
    list: [
      'admin-collections.collection-notices.list',
      'admin-collections.collection-notices-deletion.update',
    ],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.generation]: {
    list: [
      'admin-collections.collection-notices.list',
      'admin-collections.collection-notices.update',
    ],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.recalculate]: {
    list: ['admin-collections.collection-notices-recalculate.update'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.rejection]: {
    list: ['admin-collections.collection-notices-reject.list'],
    update: ['admin-collections.collection-notices-reject.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.solving]: {
    list: ['admin-collections.collection-notices-resolve.update'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.toggleVisibility]: {
    list: ['admin-collections.collection-notices-show-hide.update'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.tpv]: {
    list: ['admin-payments.tpv.list', 'admin-payments.tpv.update'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.view]: {
    list: ['admin-collections.collection-notices.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.review]: {
    list: ['admin-collections.collection-notices-revision.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.dryRun]: {
    list: ['admin-collections.collection-notices-dry.list'],
    update: ['admin-collections.collection-notices-dry.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.concepts.assignment]: {
    list: ['admin-configuration.school-sub-concepts.list'],
    update: ['admin-configuration.school-sub-concepts.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.concepts.concepts]: {
    list: ['admin-concepts.concepts.list'],
    update: ['admin-concepts.concepts.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.concepts.deletion]: {
    list: ['admin-concepts.concepts-deletion.update'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.administrativeRules]: {
    list: ['admin-configuration.rules.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.business]: {
    list: ['admin-configuration.business.list'],
    update: ['admin-configuration.business.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.cycles]: {
    list: ['admin-configuration.cycles.list'],
    update: ['admin-configuration.cycles.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.deleteStudents]: {
    list: ['admin-configuration.families-remove-student.update'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.families]: {
    list: ['admin-configuration.families.list'],
    update: ['admin-configuration.families.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.parameters]: {
    list: [
      'admin-configuration.parameter-catalog.list',
      'admin-configuration.parameters.list',
    ],
    update: ['admin-configuration.parameters.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.prices]: {
    list: ['admin-configuration.catalog-prices.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.regions]: {
    list: ['admin-configuration.regions.list'],
    update: ['admin-configuration.regions.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.rvoe]: {
    list: ['admin-configuration.rvoes.list'],
    update: ['admin-configuration.rvoes.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.sections]: {
    list: ['admin-configuration.sections.list'],
    update: ['admin-configuration.sections.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.schools]: {
    list: ['admin-configuration.schools.list'],
    update: ['admin-configuration.schools.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.discounts.administration]: {
    list: ['admin-discounts.discounts.list'],
    update: ['admin-discounts.discounts.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.discounts.rules]: {
    list: ['admin-discounts.rules.list'],
    update: ['admin-discounts.rules.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.history]: {
    list: ['admin-payments.history.list'],
    update: ['admin-payments.history.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.automaticCollectionGeneration]: {
    list: ['admin-reports.automatic-collection-notice-logs.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.collectionGeneration]: {
    list: ['admin-reports.collection-notice-generation.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.chargesByCategory]: {
    list: ['admin-reports.charges-by-categories.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.reportManager]: {
    list: ['admin-reports.report-manager.list'],
    update: ['admin-reports.report-manager.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.accountState]: {
    list: ['admin-reports.account-status.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.reports]: {
    list: ['admin-reports.report.list'],
    update: ['admin-reports.report.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.balance]: {
    list: ['admin-reports.balance-payment.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.scholarships]: {
    list: ['admin-discounts.scholarship.list'],
    update: ['admin-discounts.scholarship.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.scholarshipsUpload]: {
    list: ['admin-discounts.scholarship-upload.list'],
    update: ['admin-discounts.scholarship-upload.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.scholarshipsApprove]: {
    list: ['admin-discounts.scholarship-approve-reject.list'],
    update: ['admin-discounts.scholarship-approve-reject.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.security.administrators]: {
    list: ['admin-security.administrators.list'],
    update: ['admin-security.administrators.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.security.audit]: {
    list: ['admin-security.users-logs.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.security.crm]: {
    list: ['admin-security.users-logs-crm.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.security.impersonate]: {
    list: [
      'admin-security.administrators.list',
      'admin-security.users.list',
      'admin-security.users-impersonate.update',
    ],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.security.roles]: {
    list: ['admin-security.permissions.list', 'admin-security.roles.list'],
    update: [
      'admin-security.permissions.update',
      'admin-security.roles.update',
    ],
  },
  [ADMIN_FRONTEND_PERMISSIONS.security.users]: {
    list: ['admin-security.users.list'],
    update: ['admin-security.users.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.adjustAmount]: {
    list: ['admin-collections.collection-notices-adjust-amount.update'],
    update: ['admin-collections.collection-notices-adjust-amount.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.paymentsDetail]: {
    list: ['admin-payments.payments-external.list'],
    update: ['admin-payments.payments-external.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.abandonedPayments]: {
    list: ['admin-payments.payments-abandoned.list'],
    update: ['admin-payments.payments-abandoned.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.creditNote]: {
    list: ['admin-collections.collection-notices-credit-note.update'],
    update: ['admin-collections.collection-notices-credit-note.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.paymentStatus]: {
    list: ['admin-payments.payments-external.update'],
    update: ['admin-payments.payments-external.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.anomalies]: {
    list: ['admin-collections.collection-notices-anomalies.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.failedRecurringPayment]: {
    list: ['admin-reports.recurrent-payment-fails.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.discountCoupons.catalog]: {
    list: ['admin-discount-coupons.discount-coupons.list'],
    update: ['admin-discount-coupons.discount-coupons.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.discountCoupons.history]: {
    list: ['admin-discount-coupons.discount-coupons-history.list'],
    update: ['admin-discount-coupons.discount-coupons-history.list'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.discountCoupons.apply]: {
    list: ['admin-discount-coupons.discount-coupons-apply.update'],
    update: ['admin-discount-coupons.discount-coupons-apply.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.mergeStudents]: {
    list: [],
    update: ['admin-configuration.families-student-merge.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.security.familyLog]: {
    list: ['admin-security.users-logs-family.list'],
    update: ['admin-security.users-logs-family.list'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.paymentFrequency]: {
    list: ['admin-configuration.student-change-frequency.update'],
    update: ['admin-configuration.student-change-frequency.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.featureFlags]: {
    list: ['admin-configuration.feature-flags.list'],
    update: ['admin-configuration.feature-flags.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.inRiCollectionsReport]: {
    list: ['admin-reports.collection-notice-in-ri.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.ordersReport]: {
    list: ['admin-payments.orders.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.pastDueReport]: {
    list: ['admin-reports.past-due-collection-notice.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.billing.restamp]: {
    list: ['admin-invoicing.invoices-stamp.update'],
    update: ['admin-invoicing.invoices-stamp.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.events.crud]: {
    list: ['admin-event.event.list'],
    update: ['admin-event.event.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.events.dashboard]: {
    list: ['admin-event.event-dashboard.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.events.collections]: {
    list: ['admin-event.event-collection-notices.list'],
    update: ['admin-event.event-collection-notices.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.events.payments]: {
    list: ['admin-event.event-payments.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.events.invoices]: {
    list: ['admin-event.event-invoices.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.accessories]: {
    list: [
      'admin-collections.collection-notices-adjust-amount-discount.update',
    ],
    update: [
      'admin-collections.collection-notices-adjust-amount-discount.update',
    ],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.attempts]: {
    list: ['admin-payments.payments-attempts.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.relatedPayments]: {
    list: ['admin-collections.collection-notices-related-payments.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.deleteScholarships]: {
    list: ['admin-collections.collection-notices-remove-scholarship.update'],
    update: ['admin-collections.collection-notices-remove-scholarship.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.reverse]: {
    list: ['admin-collections.collection-notices-reverse.update'],
    update: ['admin-collections.collection-notices-reverse.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.refund]: {
    list: ['admin-collections.collection-notices-refund.update'],
    update: ['admin-collections.collection-notices-refund.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.reassign]: {
    list: ['admin-payments.payments-tpv-move.update'],
    update: ['admin-payments.payments-tpv-move.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.reconciliation]: {
    list: [],
    update: ['admin-conciliation.conciliation-reference.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.chargebacks]: {
    list: ['admin-payments.payments-chargeback.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.discountCoupons.remove]: {
    list: [],
    update: [
      'admin-collections.collection-notices-remove-discount-coupons.update',
    ],
  },
  [ADMIN_FRONTEND_PERMISSIONS.events.committedAmount]: {
    list: [],
    update: ['admin-event.event-hotel-stay-committed-amount.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.adjustAmount]: {
    list: [],
    update: [
      'admin-collections.collection-notices-adjust-payment-amount.update',
    ],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.partialRefunds.surchargeAccount]: {
    list: [],
    update: ['admin-payments.payments-refund-type-surcharges.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.partialRefunds.tuitionInRi]: {
    list: [],
    update: [
      'admin-payments.payments-refund-type-advance-payment-ri-in.update',
    ],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.partialRefunds.tuitionAdvance]: {
    list: [],
    update: [
      'admin-payments.payments-refund-type-advance-payment-tuition.update',
    ],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.notifyCRM]: {
    list: [],
    update: ['admin-collections.collection-notices-notify-crm.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.cancel]: {
    list: [],
    update: ['admin-collections.collection-notices-deletion.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.paymentValidation]: {
    list: [],
    update: [
      'admin-collections.collection-notices-further-payment-validation.update',
    ],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.split]: {
    list: [],
    update: ['admin-collections.collection-notices-split.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.includeEvents]: {
    list: [],
    update: ['admin-payments.history-include-event.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.showTotal]: {
    list: [],
    update: ['admin-payments.history-show-totals.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.showCommissions]: {
    list: [],
    update: ['admin-payments.history-show-commissions.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.showAccountingEntries]: {
    list: [],
    update: ['admin-payments.history-show-accounting-entries.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.fees]: {
    list: ['admin-payments.payments-commissions-report.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.validationInProcess]: {
    list: ['admin-payments.payments-further-payment-validation.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.updateAmount]: {
    list: [],
    update: ['admin-collections.collection-notices-allow-custom-amount.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.updateStatus]: {
    list: [],
    update: [
      'admin-collections.collection-notices-remove-further-payment-validation.update',
    ],
  },
  [ADMIN_FRONTEND_PERMISSIONS.ledgerAccountDetail]: {
    list: [],
    update: ['admin-accounting.ledger-seats-show-details.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.students]: {
    list: [],
    update: ['admin-configuration.families-student-parameter.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.adjust]: {
    list: [],
    update: ['admin-collections.collection-notices-adjust-to-payment.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.snapshot]: {
    list: [],
    update: ['admin-collections.snapshot-collection-notices.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.scholarshipReport]: {
    list: ['admin-reports.scholarship-report.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.incomeConcentration]: {
    list: ['admin-reports.income-concentration.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.generalIncomeConcentration]: {
    list: ['admin-reports.general-income-concentration.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.tvpReport]: {
    list: ['admin-payments.payments-tpv.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.downloadReference]: {
    list: [],
    update: ['admin-collections.collection-notices-download-reference.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.switcher.hubAdmin]: {
    list: ['admin-configuration.admin-switcher-hub-admin.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.switcher.hubPayments]: {
    list: ['admin-configuration.admin-switcher-hub-payments.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.switcher.hubReports]: {
    list: ['admin-configuration.admin-switcher-hub-reports.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.reports.dailyPayments]: {
    list: ['admin-reports.daily-payment-report.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.familyParameter]: {
    list: [],
    update: ['admin-configuration.families-parameter.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.external.collectionNotices]: {
    list: ['admin-collections.collection-notices-external.list'],
    update: ['admin-collections.collection-notices-external.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.external.payments]: {
    list: ['admin-payments.payments-collection-notices-external.list'],
    update: ['admin-payments.payments-collection-notices-external.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.external.billing]: {
    list: ['admin-invoicing.invoices-collection-notices-external.list'],
    update: ['admin-invoicing.invoices-collection-notices-external.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.ledgerAccountZip]: {
    list: [],
    update: ['admin-accounting.ledger-seats-split-export.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.configuration.childNumber]: {
    list: [],
    update: ['admin-configuration.families-child-number.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.financialEntries]: {
    list: ['admin-collections.collections-financial-entries.list'],
    update: [],
  },
  [ADMIN_FRONTEND_PERMISSIONS.external.resendEmail]: {
    list: [],
    update: [
      'admin-collections.collection-notices-external-resend-email.update',
    ],
  },
  [ADMIN_FRONTEND_PERMISSIONS.collection.generateAllCycle]: {
    list: [],
    update: ['admin-collections.collection-notices-massive.update'],
  },
  [ADMIN_FRONTEND_PERMISSIONS.payments.downloadZip]: {
    list: [],
    update: ['admin-invoicing.invoices-download-all-by-school.update'],
  },
};

export const ADMIN_UNAUTHORIZED_ROUTE = 'admin/app/sin-autorizacion';

export const PERMISSION_ROUTER_DATA = 'permission';

export const SUPERADMIN_ROLE_ID = '2';

export const USER_FRONTEND_PERMISSIONS = {};

export const USER_PERMISSIONS_MAP: FrontEndPermissionMap = {};

export const PERMISSIONS_MAP: FrontEndPermissionMap = {
  [ADMIN_FRONTEND_PERMISSIONS.default]: ADMIN_DEFAULT_PERMISSIONS,
  ...ADMIN_PERMISSIONS_MAP,
  ...USER_PERMISSIONS_MAP,
};

export const ROLES_NAME = {
  SUPER_ADMIN: 'superadmin',
  CASHIER: 'cashier',
};
