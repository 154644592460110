import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PaginationModel,
  PaymentHistoryReportModel,
  SingleItemResponse,
} from '@core/models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentHistoryService {
  private endpoint: string;

  constructor(private http: HttpClient) {
    this.endpoint = `${environment.apiUrl}/v1/admin/payments/history`;
  }

  getAll(
    paginationData: PaginationModel
  ): Observable<SingleItemResponse<PaymentHistoryReportModel>> {
    try {
      const endpoint = `${this.endpoint}${`?${new URLSearchParams(
        paginationData as any
      ).toString()}`}`;

      const response =
        this.http.get<SingleItemResponse<PaymentHistoryReportModel>>(endpoint);
      return response;
    } catch (e: any) {
      throw new Error(
        {
          ...e.response?.data,
          config: e.config,
        },
        e.response?.status || 500
      );
    }
  }

  public export(params: PaginationModel): Observable<Blob> {
    params.limit = 100000;
    const endpoint = `${this.endpoint}/export/excel${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }
}
