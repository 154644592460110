import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IdempotencyKeyModel,
  PaymentCardModel,
  PaymentsCardRespondeModel,
  SingleItemResponse,
} from '@core/models';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentCardService extends Api<PaymentsCardRespondeModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<PaymentsCardRespondeModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/payments/payment-management/cards`;
  }

  public paymentCard(
    data: PaymentCardModel
  ): Observable<PaymentsCardRespondeModel> {
    return this.post(data);
  }

  public multiplePayment(
    data: PaymentCardModel,
    idempotencyKey: string
  ): Observable<PaymentsCardRespondeModel> {
    const headers = new HttpHeaders({
      'Idempotency-Key': idempotencyKey,
    });
    const endpoint = `${this.endpoint}/multiple`;
    const params = JSON.stringify(data);
    return this.http.post<PaymentsCardRespondeModel>(endpoint, params, {
      headers,
    });
  }

  public generateIdempotency(): Observable<IdempotencyKeyModel> {
    const url = `${environment.apiUrl}/v1/payments/generate-idempotency`;
    return this.http
      .get<SingleItemResponse<IdempotencyKeyModel>>(url)
      .pipe(map((response) => response.data));
  }
}
