import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VersionModel } from '@core/models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class VersionService {
  private endpoint: string;
  constructor(private http: HttpClient) {
    this.endpoint = `${environment.portalUrl}/${environment.versionUrl}`;
  }

  public getVersion(): Observable<VersionModel> {
    return this.http.get<VersionModel>(this.endpoint);
  }
}
