import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DailyPaymentResponseReportModel,
  DailyPaymentsReportModel,
  SingleItemResponse,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DailyPaymentsReportService extends Api<DailyPaymentsReportModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<DailyPaymentsReportModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/payments/daily-payment-reports`;
  }

  public export(idReport: string): Observable<DailyPaymentResponseReportModel> {
    const endpoint = `${this.endpoint}/${idReport}`;
    return this.http
      .get<SingleItemResponse<DailyPaymentResponseReportModel>>(endpoint)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }
}
