import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CollectionResponse, SingleItemResponse } from '@core/models';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService<T> {
  constructor(private http: HttpClient) {}

  getAll(endpoint: string): Observable<CollectionResponse<T>> {
    try {
      const response = this.http.get<CollectionResponse<T>>(`${endpoint}`);
      return response;
    } catch (error) {
      return this.generateErrorResponse(error);
    }
  }

  get(endpoint: string): Observable<SingleItemResponse<T>> {
    try {
      const response = this.http.get<SingleItemResponse<T>>(`${endpoint}`);
      return response;
    } catch (error) {
      return this.generateErrorResponse(error);
    }
  }

  public post(
    endpoint: string,
    data?: string
  ): Observable<CollectionResponse<T>> {
    try {
      return this.http.post<CollectionResponse<T>>(`${endpoint}`, data);
    } catch (error) {
      return this.generateErrorResponse(error);
    }
  }

  public put(
    endpoint: string,
    data?: string
  ): Observable<SingleItemResponse<T>> {
    try {
      return this.http.put<SingleItemResponse<T>>(`${endpoint}`, data);
    } catch (error) {
      return this.generateErrorResponse(error);
    }
  }

  public delete(endpoint: string): Observable<CollectionResponse<T>> {
    try {
      const response = this.http.delete<CollectionResponse<T>>(`${endpoint}`);
      return response;
    } catch (error) {
      return this.generateErrorResponse(error);
    }
  }

  async endpointCall(
    method: string,
    endpoint: string,
    data?: string
  ): Promise<T | T[]> {
    try {
      let response = null;
      if (method === 'get') {
        response = await lastValueFrom(this.http.get<T | T[]>(`${endpoint}`));
      } else if (method === 'post') {
        response = await lastValueFrom(
          this.http.post<T | T[]>(`${endpoint}`, data)
        );
      } else if (method === 'patch') {
        response = await lastValueFrom(
          this.http.patch<T | T[]>(`${endpoint}`, data)
        );
      } else if (method === 'put') {
        response = await lastValueFrom(
          this.http.put<T | T[]>(`${endpoint}`, data)
        );
      } else if (method === 'delete') {
        response = await lastValueFrom(
          this.http.delete<T | T[]>(`${endpoint}`)
        );
      }

      return this.handleRequest(response);
    } catch (error) {
      return this.generateErrorResponse(error);
    }
  }

  protected handleRequest(response: any): T | T[] {
    return response.data ?? response;
  }

  protected generateErrorResponse(e: any): any {
    throw new Error(
      {
        ...e.response?.data,
        config: e.config,
      },
      e.response?.status || 500
    );
  }
}
