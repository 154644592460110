import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ADMIN_FRONTEND_PERMISSIONS } from '@core/constants';

import { RequestAuthSuperset } from '@core/models/superset/request-auth-superset.model';
import { SessionSupersetModel } from '@core/models/superset/session-superset.model';
import { AuthSupersetService } from '@core/services';
import { SessionState } from '@core/state/shared';
import { ContextMenuSelectEvent } from '@progress/kendo-angular-menu';
import { SwitcherOptionModel } from '@widgets/switcher-menu/models/switcher-menu-option-model';
import { take } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-switcher-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class SwitcherMenuComponent {
  @Input()
  public menuOptions: SwitcherOptionModel[];

  @Input()
  public target: HTMLElement;

  @Output() itemSelected: EventEmitter<SwitcherOptionModel> =
    new EventEmitter();

  constructor(
    private authSupersetService: AuthSupersetService,
    public sessionState: SessionState
  ) {}

  public action(event: ContextMenuSelectEvent): void {
    const option = this.menuOptions[Number(event.index)];

    if (option.key === ADMIN_FRONTEND_PERMISSIONS.switcher.hubReports) {
      this.goToSuperset(option);
      return;
    }

    if (option.isLink) {
      window.open(option.link, option.isOpenNewTab ? '_blank' : '_self');
    }

    if (option.isAction && option.action) {
      this.itemSelected.emit(option);
    }
  }

  private goToSuperset(option: SwitcherOptionModel): void {
    const sessionUser = this.sessionState.user;
    const payLoad: RequestAuthSuperset = {
      id: '',
      name: sessionUser?.name,
      email: sessionUser?.email,
      user_id: sessionUser?.id,
      role_id: sessionUser?.role?.id,
    };
    this.authSupersetService
      .authSuperset(payLoad)
      .pipe(take(1))
      .subscribe((sesion: SessionSupersetModel) => {
        window.open(
          `${option.link}?jwt=${sesion.jwtToken}`,
          option.isOpenNewTab ? '_blank' : '_self'
        );
      });
  }
}
