<div [ngSwitch]="type">
  <kendo-numerictextbox
    *ngSwitchCase="'PERCENTAGE'"
    [(ngModel)]="value"
    (valueChange)="onValueChange($event)"
    format="#\%"
    [step]="1"
  ></kendo-numerictextbox>

  <kendo-numerictextbox
    *ngSwitchCase="'NUMERIC'"
    [(ngModel)]="value"
    (valueChange)="onValueChange($event)"
    format="#"
    [step]="1"
  ></kendo-numerictextbox>

  <kendo-switch
    *ngSwitchCase="'BOOLEAN'"
    [(ngModel)]="value"
    (valueChange)="onValueChange(value ? true : false)"
    onLabel="Si"
    offLabel="No"
    class="k-switch-size"
  >
  </kendo-switch>
  <kendo-textbox
    *ngSwitchDefault
    [(ngModel)]="value"
    (valueChange)="onValueChange($event)"
  ></kendo-textbox>
</div>
