import { Injectable } from '@angular/core';
import { getAllWithoutId } from '@core/functions';
import {
  CollectionResponse,
  PaginationModel,
  PaymentOrdersReportModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentOrdersReportService extends Api<PaymentOrdersReportModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<PaymentOrdersReportModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/payments/orders`;
  }

  public override getAll(
    paginationData?: PaginationModel | undefined
  ): Observable<CollectionResponse<PaymentOrdersReportModel>> {
    return getAllWithoutId<PaymentOrdersReportModel>(
      this.apiService,
      this.endpoint,
      paginationData
    );
  }
}
