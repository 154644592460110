import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GuardianModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountUsersService extends Api<GuardianModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<GuardianModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/account/users`;
  }

  public getConfig(): Observable<any> {
    const endpoint = `${environment.apiUrl}/v1/account/family-configuration`;
    return this.http.get(endpoint);
  }
}
