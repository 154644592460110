import { Injectable } from '@angular/core';
import { RvoeModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class RvoeService extends Api<RvoeModel> {
  override endpoint: string;

  constructor(protected override readonly apiService: ApiService<RvoeModel>) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/schools/{school_id}/rvoes

`;
  }
  public buildStringEndpoint(schoolId: string): void {
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/schools/${schoolId}/rvoes

`;
  }
}
