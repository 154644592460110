import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { InvoicingDataModel } from '@core/models';
import { InvoicingDataService } from '@core/services';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { Observable, finalize, first, map, switchMap, tap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<InvoicingDataModel>>({
  name: 'invoicingData',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class InvoicingDataState extends GenericState<InvoicingDataModel> {
  override service: InvoicingDataService;
  constructor(private invoicingDataService: InvoicingDataService) {
    super();
    this.service = invoicingDataService;
  }

  public createInvoicingData(
    invoicingData: InvoicingDataModel
  ): Observable<InvoicingDataModel> {
    this.toggleLoading();
    return this.invoicingDataService.post(invoicingData).pipe(
      tap(() => {
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        this.getAllByPage({ page: lastPage }, true).subscribe();
      })
    );
  }

  public getInvoicingDataById(id: string): Observable<InvoicingDataModel> {
    if (!(this.snapshot.items[id] as InvoicingDataModel | undefined)) {
      this.invoicingDataService.find(id).subscribe((item) => {
        this.patchState({ items: { ...this.snapshot.items, [id]: item } });
      });
    }
    return this.state$.pipe(
      first((invoicingDataStateModel) => !!invoicingDataStateModel.items[id]),
      map((invoicingDataStateModel) => {
        return invoicingDataStateModel.items[id];
      })
    );
  }

  public deleteIvoicePayment(id: string): Observable<InvoicingDataModel[]> {
    this.toggleLoading();
    return this.invoicingDataService.delete(id).pipe(
      finalize(() => {
        this.toggleLoading();
      }),
      switchMap((): Observable<InvoicingDataModel[]> => {
        return this.getAllByPage({ page: this.snapshot.currentPage }, true);
      })
    );
  }

  public updateInvoicingData(
    payment: InvoicingDataModel
  ): Observable<InvoicingDataModel[]> {
    this.toggleLoading();
    return this.invoicingDataService.updateIvoiceData(payment).pipe(
      finalize(() => {
        this.toggleLoading();
      }),
      switchMap((): Observable<InvoicingDataModel[]> => {
        return this.getAllByPage({ page: this.snapshot.currentPage }, true);
      })
    );
  }
}
