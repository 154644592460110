/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  InvoicingDataModel,
  SingleItemResponse,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoicingDataService extends Api<InvoicingDataModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<InvoicingDataModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/account/invoicing-data`;
  }
  public updateIvoiceData(
    data: InvoicingDataModel
  ): Observable<SingleItemResponse<InvoicingDataModel>> {
    const endpoint = `${this.endpoint}/${data.id}`;
    return this.apiService.put(endpoint, JSON.stringify(data));
  }

  public getDefaultInvoicingData(): Observable<InvoicingDataModel | null> {
    return this.getAll({ page: 1, limit: 5000 }).pipe(
      map((data: CollectionResponse<InvoicingDataModel>) => {
        return data.data.items.find((item) => item.isDefault === 1) || null;
      })
    );
  }

  public getAutomaticBilling(): Observable<any> {
    const endpoint = `${environment.apiUrl}/v1/account/invoicing/config/automatic_stamping`;
    return this.apiService.get(endpoint);
  }

  public updateAutomaticBilling(data: {
    set_automatic_stamping: boolean;
  }): Observable<any> {
    const endpoint = `${environment.apiUrl}/v1/account/invoicing/config/automatic_stamping`;
    return this.apiService.post(endpoint, JSON.stringify(data));
  }
}
