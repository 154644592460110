import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { AccountStateModel } from '@core/models/account-state.model';
import { AccountStateService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<AccountStateModel>>({
  name: 'AccountState',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class AccountState extends GenericState<AccountStateModel> {
  override service: AccountStateService;

  constructor(accountStateService: AccountStateService) {
    super();
    this.service = accountStateService;
  }
}
