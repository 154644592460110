<div class="dropdown-list-container">
  <kendo-dropdownlist
    #select
    [(ngModel)]="value"
    [data]="(items$ | async) || []"
    [disabled]="!!(disabled | async)"
    [filterable]="allowFilter"
    [kendoDropDownFilter]="filterSettings"
    [loading]="!!(loading$ | async)"
    [ngClass]="getDropdownClasses()"
    [textField]="textField"
    [valueField]="valueField"
    [valuePrimitive]="true"
    (valueChange)="onValueChange($event)"
    (filterChange)="updateData($event)"
    (open)="onOpen()"
  ></kendo-dropdownlist>
  <button
    *ngIf="value && clear"
    class="clear-button"
    [disabled]="!!(disabled | async)"
    title="Limpiar"
    (click)="clearValue()"
  >
    <i class="ph-bold ph-x"></i>
  </button>
</div>
