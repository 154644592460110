import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PaginationModel,
  ParameterConfigurationModel,
  SingleItemResponse,
  StudentModel,
} from '@core/models';
import { UpdateChildNumberRequest } from '@core/models/update-child-number-request.model';
import { UpdateChildNumberResponse } from '@core/models/update-child-number-response.model';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StudentsService extends Api<StudentModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<StudentModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/students`;
  }

  public exportStudents(
    params: PaginationModel,
    isGeneralReport: boolean
  ): Observable<Blob> {
    params.limit = 1000000;
    params.page = 1;
    const endpoint = isGeneralReport
      ? `${this.endpoint}/export/excel`
      : `${environment.apiUrl}/v1/admin/payments/recurrent-payments/families/export`;

    return this.http.get(
      `${endpoint}?${new URLSearchParams(params as any).toString()}`,
      {
        responseType: 'blob',
      }
    );
  }

  public mergeStudents(data: any): Observable<any> {
    const endpoint = `${this.endpoint}/collection-notice/merge`;
    return this.http.patch(endpoint, data);
  }

  public updateFamilySettings(data: {
    expired_days: string;
    family_id: string;
  }): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/admin/families/configurations/create`;
    return this.http.post(apiUrl, data);
  }

  public updateFamilyChildNumber(
    data: UpdateChildNumberRequest
  ): Observable<SingleItemResponse<UpdateChildNumberResponse>> {
    const apiUrl = `${environment.apiUrl}/v1/admin/families/configurations/change-child-number`;
    return this.http.post<SingleItemResponse<UpdateChildNumberResponse>>(
      apiUrl,
      data
    );
  }

  public getFamilySettings(
    familyId: string
  ): Observable<ParameterConfigurationModel> {
    const apiUrl = `${environment.apiUrl}/v1/admin/families/configurations/${familyId}`;
    return this.http
      .get<SingleItemResponse<ParameterConfigurationModel>>(apiUrl)
      .pipe(
        map((result) => {
          return result.data;
        })
      );
  }
}
