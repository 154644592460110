import { PaymentMethodModel } from './payment-method.model';

export enum PaymentTypeEnum {
  TC_TD_CARD = 'tc_td_card',
  SPEI = 'spei',
  MONEY = 'money',
  BITCOIN = 'bitcoin',
}

export interface PaymentTypeModel {
  id: number;
  name: string;
  type: PaymentTypeEnum;
  icons: Array<string>;
}
export interface PaymentMonthsModel {
  total: number;
  desc: string;
}

export class PaymentMethodComboBox {
  id: string;
  description: string;
  processorName?: string;
  type?: string;
  bank: string;
  isValidated?: boolean;

  constructor(item: PaymentMethodModel) {
    this.id = item.id;
    this.description = `${item.bank} - ${item.cardNumber}`;
    this.processorName = item.processorName;
    this.type = item.type;
    this.bank = item.bank;
    this.isValidated = item.isValidated === 1;
  }
}

export interface PaymentSelectMethod {
  paymentType?: PaymentTypeModel;
  paymentMethod?: PaymentMethodComboBox;
  months?: number;
  cvv?: string;
  store_code?: string;
}
