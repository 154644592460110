import { inject } from '@angular/core';
import { ApiModel, CollectionResponse, PaginationModel } from '@core/models';
import { AuthService } from '@core/services';
import { ApiService } from '@core/services/api/api.service';
import { Observable, map } from 'rxjs';

export function arrayToMap<T>(
  items: T[],
  identityKey: string,
  initialEntities: { [id: string]: T } = {}
) {
  const entities = items.reduce((_entities: { [id: string]: T }, item: T) => {
    return {
      ..._entities,
      [(item as any)[identityKey]]: item,
    };
  }, initialEntities);
  return entities;
}

export function getAllWithoutId<T extends ApiModel>(
  apiService: ApiService<T>,
  endpoint: string,
  paginationData?: PaginationModel | undefined
): Observable<CollectionResponse<T>> {
  return apiService
    .getAll(
      `${endpoint}${
        paginationData
          ? `?${new URLSearchParams(paginationData as any).toString()}`
          : ''
      }`
    )
    .pipe(
      map((data) => {
        data.data.items.forEach((item, index) => {
          item.id = `${
            index +
            (paginationData?.limit || 0) * ((paginationData?.page || 0) - 1)
          }`;
        });
        return data;
      })
    );
}

export const isLoggedInFn = (): boolean => {
  const authService = inject(AuthService);
  return authService.isLoggedIn();
};

export function loadScript(id: string, url: string, head = true, async = true) {
  let otherScript: HTMLScriptElement,
    otherScripts: HTMLCollectionOf<HTMLScriptElement>,
    newScript: HTMLScriptElement;

  if (!document.getElementById(id)) {
    newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    newScript.async = async;
    newScript.src = url;
    newScript.id = id;
    otherScripts = document.getElementsByTagName('script');
    otherScript = head
      ? otherScripts[0]
      : otherScripts[otherScripts.length - 1];
    otherScript.parentNode?.insertBefore(newScript, otherScript);
  }
}
