import { DatePipe, Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { merge, values } from 'lodash';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FiltersServiceService {
  private path: string;
  constructor(
    private datePipe: DatePipe,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.path = this.location.path();
  }

  public formatFilters(
    mapping: Map<string, string>,
    filters: (CompositeFilterDescriptor | FilterDescriptor)[]
  ) {
    this.location.replaceState(this.path);
    const formattedFilters = filters.map((filter: any) => {
      const field = mapping.get(filter.field) || filter.field;

      if (filter.value === undefined) {
        return false;
      }

      const value =
        filter.value instanceof Date
          ? this.datePipe.transform(filter.value, 'yyyy-MM-dd')
          : filter.value;

      return { [field]: value };
    });

    const queryString = formattedFilters
      .map((obj) =>
        Object.entries(obj)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join('&')
      )
      .join('&');

    const basePath = this.router.url.split('?')[0];
    this.location.replaceState(basePath, queryString);
    return merge({}, ...values(formattedFilters));
  }

  public applyFilters(
    filterMapping: Map<string, string>
  ): Observable<CompositeFilterDescriptor> {
    return this.activatedRoute.queryParams.pipe(
      switchMap((params) => {
        return of({
          logic: 'and',
          filters: this.mapFilters(params, filterMapping),
        } as CompositeFilterDescriptor);
      })
    );
  }

  private mapFilters(queryParams: any, mapping: Map<string, string>): any {
    try {
      const filters = [];
      const processedKeys: Set<string> = new Set<string>();

      for (const [key, value] of mapping) {
        if (Object.prototype.hasOwnProperty.call(queryParams, value)) {
          filters.push({
            field: key,
            operator: 'contains',
            value: queryParams[value],
          });
          processedKeys.add(value);
        }
      }

      for (const key in queryParams) {
        if (!processedKeys.has(key) && !mapping.has(key)) {
          filters.push({
            field: key,
            operator: 'contains',
            value: queryParams[key],
          });
        }
      }

      return filters;
    } catch (error) {
      return [];
    }
  }
}
