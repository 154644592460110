import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { CollectionNoticeModel } from '@core/models';
import { DiscountCouponsHistoryService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<CollectionNoticeModel>>({
  name: 'DiscountCouponsHistory',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class DiscountCouponsHistoryState extends GenericState<CollectionNoticeModel> {
  override service: DiscountCouponsHistoryService;

  constructor(
    private discountCouponsHistoryService: DiscountCouponsHistoryService
  ) {
    super();
    this.service = discountCouponsHistoryService;
  }

  public buildStringEndpoint(couponId: string): void {
    this.service.buildStringEndpoint(couponId);
  }
}
