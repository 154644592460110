import { CountryModel } from '@core/models';

export const countriesFake: CountryModel[] = [
  {
    id: 'f91afebd-3ad7-ea11-a813-000d3a33f11e',
    name: 'México',
  },
  {
    id: '2aa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Afganistán',
  },
  {
    id: '2ca9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Albania',
  },
  {
    id: '2ea9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Alemania',
  },
  {
    id: '30a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Andorra',
  },
  {
    id: '32a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Angola',
  },
  {
    id: '34a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Antigua Y Barbuda',
  },
  {
    id: '36a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Arabia Saudita',
  },
  {
    id: '38a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Argelia',
  },
  {
    id: '3aa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Argentina',
  },
  {
    id: '3ea9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Armenia',
  },
  {
    id: '40a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Australia',
  },
  {
    id: '42a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Austria',
  },
  {
    id: '44a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Azerbaiyán',
  },
  {
    id: '46a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Bahamas',
  },
  {
    id: '48a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Bangladés',
  },
  {
    id: '4aa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Barbados',
  },
  {
    id: '4ca9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Baréin',
  },
  {
    id: '4ea9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Bélgica',
  },
  {
    id: '50a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Belice',
  },
  {
    id: '53a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Benin',
  },
  {
    id: '55a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Bielorrusia',
  },
  {
    id: '57a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Bolivia',
  },
  {
    id: '59a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Bosnia Y Herzegovina',
  },
  {
    id: '5ba9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Botsuana',
  },
  {
    id: '5da9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Brasil',
  },
  {
    id: '5fa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Brunei',
  },
  {
    id: '61a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Bulgaria',
  },
  {
    id: '63a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Burkina Faso',
  },
  {
    id: '65a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Burundi',
  },
  {
    id: '67a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Bután',
  },
  {
    id: '69a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Cabo Verde',
  },
  {
    id: '6ba9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Camboya',
  },
  {
    id: '6da9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Camerún',
  },
  {
    id: '6fa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Canadá',
  },
  {
    id: '71a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Catar',
  },
  {
    id: '73a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Chad',
  },
  {
    id: '75a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Chile',
  },
  {
    id: '77a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'China',
  },
  {
    id: '79a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Chipre',
  },
  {
    id: '7ba9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Colombia',
  },
  {
    id: '7da9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Comoras',
  },
  {
    id: '7fa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Corea Del Norte',
  },
  {
    id: '81a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Corea Del Sur',
  },
  {
    id: '83a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Costa De Marfil',
  },
  {
    id: '85a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Costa Rica',
  },
  {
    id: '87a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Croacia',
  },
  {
    id: '89a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Cuba',
  },
  {
    id: '8ba9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Dinamarca',
  },
  {
    id: '8da9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Dominica',
  },
  {
    id: '8fa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Ecuador',
  },
  {
    id: '91a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Egipto',
  },
  {
    id: '93a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'El Salvador',
  },
  {
    id: '95a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Emiratos Arabes Unidos',
  },
  {
    id: '97a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Eritrea',
  },
  {
    id: '99a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Eslovaquia',
  },
  {
    id: '9ba9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Eslovenia',
  },
  {
    id: '9da9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'España',
  },
  {
    id: '9fa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Estados Unidos',
  },
  {
    id: 'a1a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Estonia',
  },
  {
    id: 'a3a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Etiopía',
  },
  {
    id: 'a5a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Filipinas',
  },
  {
    id: 'a7a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Finlandia',
  },
  {
    id: 'a9a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Fiyi',
  },
  {
    id: 'aba9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Francia',
  },
  {
    id: 'ada9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Gabón',
  },
  {
    id: 'afa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Gambia',
  },
  {
    id: 'b1a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Georgia',
  },
  {
    id: 'b3a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Ghana',
  },
  {
    id: 'b5a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Granada',
  },
  {
    id: 'b7a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Grecia',
  },
  {
    id: 'b9a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Guatemala',
  },
  {
    id: 'bba9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Guinea',
  },
  {
    id: 'bda9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Guinea Ecuatorial',
  },
  {
    id: 'bfa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Guinea-Bissau',
  },
  {
    id: 'c1a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Guyana',
  },
  {
    id: 'c3a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Haití',
  },
  {
    id: 'c5a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Honduras',
  },
  {
    id: 'c7a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Hungría',
  },
  {
    id: 'c9a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'India',
  },
  {
    id: 'cba9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Indonesia',
  },
  {
    id: 'cda9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Irán',
  },
  {
    id: 'cfa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Iraq',
  },
  {
    id: 'd1a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Irlanda',
  },
  {
    id: 'd3a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Islandia',
  },
  {
    id: 'd5a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Islas Marshall',
  },
  {
    id: 'd7a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Islas Salomón',
  },
  {
    id: 'd9a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Israel',
  },
  {
    id: 'dba9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Italia',
  },
  {
    id: 'dda9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Jamaica',
  },
  {
    id: 'dfa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Japón',
  },
  {
    id: 'e1a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Jordania',
  },
  {
    id: 'e3a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Kazajistán',
  },
  {
    id: 'e5a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Kenia',
  },
  {
    id: 'e7a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Kirguistán',
  },
  {
    id: 'e9a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Kiribati',
  },
  {
    id: 'eba9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Kuwait',
  },
  {
    id: 'eda9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Laos',
  },
  {
    id: 'efa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Lesoto',
  },
  {
    id: 'f1a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Letonia',
  },
  {
    id: 'f3a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Líbano',
  },
  {
    id: 'f5a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Liberia',
  },
  {
    id: 'f7a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Libia',
  },
  {
    id: 'f9a9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Liechtenstein',
  },
  {
    id: 'fba9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Lituania',
  },
  {
    id: 'fda9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Luxemburgo',
  },
  {
    id: 'ffa9c174-47db-ea11-a820-000d3a33f0ae',
    name: 'Madagascar',
  },
  {
    id: '01aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Malasia',
  },
  {
    id: '03aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Malaui',
  },
  {
    id: '05aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Maldivas',
  },
  {
    id: '07aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Mali',
  },
  {
    id: '09aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Malta',
  },
  {
    id: '0baac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Marruecos',
  },
  {
    id: '0daac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Mauricio',
  },
  {
    id: '0faac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Mauritania',
  },
  {
    id: 'f91afebd-3ad7-ea11-a813-000d3a33f11e',
    name: 'México',
  },
  {
    id: '13aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Micronesia',
  },
  {
    id: '15aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Moldavia',
  },
  {
    id: '17aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Mónaco',
  },
  {
    id: '19aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Mongolia',
  },
  {
    id: '1baac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Montenegro',
  },
  {
    id: '1daac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Mozambique',
  },
  {
    id: '1faac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Myanmar (Birmania)',
  },
  {
    id: '21aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Namibia',
  },
  {
    id: '23aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Nauru',
  },
  {
    id: '25aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Nepal',
  },
  {
    id: '27aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Nicaragua',
  },
  {
    id: '29aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Níger',
  },
  {
    id: '2baac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Nigeria',
  },
  {
    id: '2daac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Noruega',
  },
  {
    id: '2faac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Nueva Zelanda',
  },
  {
    id: '31aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Omán',
  },
  {
    id: '33aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Países Bajos',
  },
  {
    id: '35aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Pakistán',
  },
  {
    id: '37aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Palaos',
  },
  {
    id: '39aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Palestina',
  },
  {
    id: '3baac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Panamá',
  },
  {
    id: '3daac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Papúa Nueva Guinea',
  },
  {
    id: '3faac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Paraguay',
  },
  {
    id: '41aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Perú',
  },
  {
    id: '43aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Polonia',
  },
  {
    id: '45aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Portugal',
  },
  {
    id: '47aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Puerto Rico',
  },
  {
    id: '49aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Reino Unido',
  },
  {
    id: '4baac174-47db-ea11-a820-000d3a33f0ae',
    name: 'República Centroafricana',
  },
  {
    id: '4daac174-47db-ea11-a820-000d3a33f0ae',
    name: 'República Checa',
  },
  {
    id: '4faac174-47db-ea11-a820-000d3a33f0ae',
    name: 'República De Macedonia',
  },
  {
    id: '51aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'República Del Congo',
  },
  {
    id: '53aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'República Democrática Del Congo',
  },
  {
    id: '55aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'República Dominicana',
  },
  {
    id: '57aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'República Saharaui',
  },
  {
    id: '59aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Ruanda',
  },
  {
    id: '5baac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Rumania',
  },
  {
    id: '5daac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Rusia',
  },
  {
    id: '5faac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Samoa',
  },
  {
    id: '61aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'San Cristóbal Y Nieves',
  },
  {
    id: '63aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'San Marino',
  },
  {
    id: '65aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'San Vicente Y Las Granadinas',
  },
  {
    id: '67aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Santa Lucía',
  },
  {
    id: '69aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Santo Tomé Y Príncipe',
  },
  {
    id: '6baac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Senegal',
  },
  {
    id: '6daac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Serbia',
  },
  {
    id: '6faac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Seychelles',
  },
  {
    id: '71aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Sierra Leona',
  },
  {
    id: '73aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Singapur',
  },
  {
    id: '75aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Siria',
  },
  {
    id: '77aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Somalia',
  },
  {
    id: '79aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Sri Lanka',
  },
  {
    id: '7baac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Suazilandia',
  },
  {
    id: '7daac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Sudáfrica',
  },
  {
    id: '7faac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Sudán Del Norte',
  },
  {
    id: '81aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Sudán Del Sur',
  },
  {
    id: '83aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Suecia',
  },
  {
    id: '85aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Suiza',
  },
  {
    id: '87aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Surinam',
  },
  {
    id: '89aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Tailandia',
  },
  {
    id: '8baac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Taiwan',
  },
  {
    id: '8daac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Tanzania',
  },
  {
    id: '8faac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Tayikistán',
  },
  {
    id: '91aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Timor Oriental',
  },
  {
    id: '94aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Togo',
  },
  {
    id: '96aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Tonga',
  },
  {
    id: '98aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Trinidad Y Tobago',
  },
  {
    id: '9aaac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Túnez',
  },
  {
    id: '9caac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Turkmenistán',
  },
  {
    id: '9eaac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Turquía',
  },
  {
    id: 'a0aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Tuvalu',
  },
  {
    id: 'a2aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Ucrania',
  },
  {
    id: 'a4aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Uganda',
  },
  {
    id: 'a6aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Uruguay',
  },
  {
    id: 'a8aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Uzbekistán',
  },
  {
    id: 'aaaac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Vanuatu',
  },
  {
    id: 'acaac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Vaticano',
  },
  {
    id: 'aeaac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Venezuela',
  },
  {
    id: 'b0aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Vietnam',
  },
  {
    id: 'b2aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Yemen',
  },
  {
    id: 'b4aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Yibuti',
  },
  {
    id: 'b6aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Zambia',
  },
  {
    id: 'b8aac174-47db-ea11-a820-000d3a33f0ae',
    name: 'Zimbabue',
  },
];
