import {
  Computed,
  DataAction,
  Payload,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { CommonFilters } from '@core/models';
import { State } from '@ngxs/store';

const defaults: CommonFilters = {
  business_id: undefined,
  region_id: undefined,
  school_id: undefined,
  section_id: undefined,
  grade_id: undefined,
  classroom_id: undefined,
  end_date: undefined,
  start_date: undefined,
  cycle_id: undefined,
  fiscal_year: undefined,
  academic_year_id: undefined,
};

@Persistence()
@StateRepository()
@State<CommonFilters>({
  name: 'filter',
  defaults,
})
@Injectable()
export class FilterState extends NgxsDataRepository<CommonFilters> {
  @Computed()
  public get getFilter(): CommonFilters {
    return this.snapshot;
  }

  @Computed()
  public get isEmpty(): boolean {
    const filter = this.snapshot;
    return Object.values(filter).every(
      (value) => value === undefined || value === null
    );
  }

  @DataAction()
  public saveFilter(@Payload('filter') filter: CommonFilters): void {
    this.ctx.patchState({
      ...filter,
    });
  }

  @DataAction()
  public cleanFilter(): void {
    this.ctx.patchState({
      ...defaults,
    });
  }
}
