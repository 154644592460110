<div class="row text-center k-mb-5 noty-icon">
  <i [ngClass]="getAlertTypeClass(notifySettings.type)" class="ph"></i>
</div>
<ng-container *ngIf="isString(notifySettings.message); else templateContent">
  {{ notifySettings.message }}
</ng-container>
<ng-template #templateContent>
  <ng-container *ngTemplateOutlet="template"></ng-container>
</ng-template>
<kendo-dialog-actions layout="end">
  <button
    *ngFor="let button of getActions()"
    (click)="closeDialog(button.action)"
    kendoButton
    [themeColor]="button.themeColor || 'base'"
  >
    {{ button.text }}
  </button>
</kendo-dialog-actions>
