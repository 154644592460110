<kendo-listview
  [kendoListViewBinding]="data"
  [pageable]="pageable ? pagerSettings : false"
  [pageSize]="5"
  containerClass="k-d-flex k-flex-col k-flex-nowrap"
  class="slim-pager"
>
  <ng-template
    kendoListViewItemTemplate
    let-dataItem="dataItem"
    let-index="index"
  >
    <div class="list-item" [class.dark]="index % 2 === 0">
      <span>{{ dataItem[textField] }}</span>
      <button
        type="button"
        role="switch"
        aria-checked="false"
        (click)="onActionClicked(dataItem[valueField])"
      >
        <i [class]="actionIcon"></i>
      </button>
    </div>
  </ng-template>
</kendo-listview>
