import { Computed, DataAction } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { ApiModel, MultiItemStore, PaginationModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { formatMultiStoreData } from '@core/utils/format-multi-store-data.util';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { omit, values } from 'lodash';
import { Observable, finalize, map, tap } from 'rxjs';
import { MULTI_ITEM_STORE_DEFAULTS } from './initial-state';

export class GenericStateModel<T extends ApiModel> extends MultiItemStore<T> {}

export abstract class GenericState<
  T extends ApiModel
> extends NgxsDataRepository<GenericStateModel<T>> {
  abstract service: Api<T>;

  constructor() {
    super();
  }

  @Computed()
  public get items$(): Observable<T[]> {
    return this.state$.pipe(
      map((stateModel) => {
        return values(stateModel.items);
      })
    );
  }

  @Computed()
  public get data$(): Observable<any> {
    return this.state$.pipe(
      map((stateModel) => {
        return stateModel.data;
      })
    );
  }

  @Computed()
  public get itemsByPageGrid$(): Observable<GridDataResult | null> {
    return this.state$.pipe(
      map((stateModel) => {
        if (!stateModel.pages[this.currentPage]) {
          return null;
        }
        const idsByPage = stateModel.pages[this.currentPage] || [];
        const data = idsByPage.map((id: string) => {
          return stateModel.items[id];
        });
        return <GridDataResult>{
          data: data,
          total: stateModel.totalItems,
        };
      })
    );
  }

  @Computed()
  public get itemsByPage$(): Observable<T[]> {
    return this.state$.pipe(
      map((stateModel) => {
        const idsByPage = stateModel.pages[this.currentPage] || [];
        return idsByPage.map((id: string) => {
          return stateModel.items[id];
        });
      })
    );
  }

  @Computed()
  public get pageLimit(): number {
    return this.snapshot.limitPerPage;
  }

  @Computed()
  public get currentPage(): number {
    return this.snapshot.currentPage;
  }

  @Computed()
  public get isLoading(): Observable<boolean> {
    return this.state$.pipe(
      map((stateModel) => {
        return stateModel.isLoading;
      })
    );
  }

  @Computed()
  public get totalItems$(): Observable<number> {
    return this.state$.pipe(
      map((stateModel) => {
        return stateModel.totalItems;
      })
    );
  }

  @DataAction()
  public cleanState(): void {
    this.ctx.patchState({ ...MULTI_ITEM_STORE_DEFAULTS });
  }

  @DataAction()
  public updateItem(item: T): void {
    this.patchState({
      items: {
        ...this.snapshot.items,
        [item.id]: item,
      },
    });
  }

  public getItemById(id: string): Observable<T> {
    return this.state$.pipe(
      map((stateModel) => {
        return stateModel.items[id];
      })
    );
  }

  public toggleLoading(): void {
    this.patchState({
      isLoading: !this.snapshot.isLoading,
    });
  }
  public setLimit(total: number): void {
    this.patchState({
      limitPerPage: total,
    });
  }

  public isPageLoaded(page: number): boolean {
    return !!this.snapshot.pages[page];
  }

  public getAllByPage(params: PaginationModel, force = false): Observable<T[]> {
    const page = params.page || 1;
    this.patchState({
      currentPage: params.page,
    });

    if (this.isPageLoaded(page) && !force) {
      return this.itemsByPage$;
    }
    this.toggleLoading();

    return this.service
      .getAll({
        ...params,
        limit: params.limit ?? this.pageLimit,
      })
      .pipe(
        finalize(() => {
          this.patchState({
            isLoading: false,
          });
        }),
        tap((content): void => {
          const currentState = this.getState();
          const { items } = content.data;
          const currentPages = force ? {} : currentState.pages;
          const objectToPatch: Partial<GenericStateModel<T>> = {
            totalItems: content.data.total,
            data: omit(content.data, 'items'),
            currentPage: page,
            items: {
              ...currentState.items,
              ...formatMultiStoreData(items),
            },
            pages: {
              ...currentPages,
              [page]: items.map((item) => item.id),
            },
            isLoading: false,
          };
          this.patchState(objectToPatch);
        }),
        map((content) => {
          return content.data.items;
        })
      );
  }
}
