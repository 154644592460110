import {
  ExternalCollectionNoticeModel,
  ExternalInvoiceModel,
  ExternalPaymentModel,
} from '@core/models';

export const externalCollections: ExternalCollectionNoticeModel[] = [];

export const externalPayments: ExternalPaymentModel[] = [];

export const externalInvoices: ExternalInvoiceModel[] = [];
