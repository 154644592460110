import {
  Computed,
  DataAction,
  Payload,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { VersionModel } from '@core/models';
import { VersionService } from '@core/services';
import { NgxsOnInit, State } from '@ngxs/store';
import { Observable } from 'rxjs';

export class VersionStateModel {
  public version: string;
}

@Persistence()
@StateRepository()
@State<VersionStateModel>({
  name: 'version',
})
@Injectable()
export class VersionState
  extends NgxsDataRepository<VersionStateModel>
  implements NgxsOnInit
{
  constructor(private versionService: VersionService) {
    super();
  }

  @Computed()
  public get version(): string {
    return this.snapshot.version;
  }

  @DataAction()
  public setVersion(@Payload('version') version: string): void {
    this.ctx.setState((state) => ({
      ...state,
      version,
    }));
  }

  public getVersion(): Observable<VersionModel> {
    return this.versionService.getVersion();
  }
}
