<div class="container-grid-column">
  <div kendoTooltip [hidden]="true">
    <div class="" title="Buscar en columnas">
      <button
        kendoButton
        (click)="onHideColumnSearch()"
        iconClass="ph ph-magnifying-glass"
        [themeColor]="showSearchColumn ? 'primary' : 'base'"
      ></button>
    </div>
  </div>
  <div kendoTooltip>
    <div class="" title="Ocultar columnas">
      <button
        kendoButton
        #anchor
        (click)="onToggle()"
        iconClass="ph ph-square-split-horizontal"
        [themeColor]="existsHideColumns ? 'primary' : 'base'"
      ></button>
    </div>
  </div>
</div>
<kendo-popup
  [anchor]="anchor.element"
  *ngIf="show"
  [anchorAlign]="anchorAlign"
  [popupAlign]="popupAlign"
>
  <div class="content">
    <div class="container-columns">
      <ng-container *ngFor="let item of this.columns; let i = index">
        <div class="row">
          <div class="col-12 col-lg-12">
            <input
              type="checkbox"
              kendoCheckBox
              [checked]="item.isSelected"
              (change)="onChange($event, item, i)"
            />
            <kendo-label class="k-ml-5" [text]="item.label"></kendo-label>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row mt-2">
      <div class="col-6 col-lg-6">
        <button themeColor="primary" kendoButton (click)="onApply()">
          Aplicar
        </button>
      </div>
      <div class="col-6 col-lg-6">
        <button kendoButton (click)="onSetDefaulValues()">Limpiar</button>
      </div>
    </div>
  </div>
</kendo-popup>
