import { Injectable } from '@angular/core';
import { CountryModel } from '@core/models';
import { environment } from '@env/environment';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CountriesService extends Api<CountryModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<CountryModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/countries`;
  }
}
