<div
  class="check-events d-flex align-items-center"
  *appHasPermission="permission"
>
  <input
    type="checkbox"
    kendoCheckBox
    [checked]="checked"
    (change)="onValueChange($event)"
  />
  <kendo-label class="k-ml-2" text="Incluir Eventos"></kendo-label>
</div>
