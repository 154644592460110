import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { StudentModel } from '@core/models';
import { StudentsService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<StudentModel>>({
  name: 'students',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class StudentsState extends GenericState<StudentModel> {
  override service: StudentsService;
  constructor(private studentsService: StudentsService) {
    super();
    this.service = studentsService;
  }
}
