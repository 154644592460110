import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionNoticeModel,
  ExternalCollectionNoticeModel,
  ExternalPaymentParamsModel,
  SingleItemResponse,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExternalCollectionsService extends Api<ExternalCollectionNoticeModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<ExternalCollectionNoticeModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/collections/collection-notices`;
  }

  public create(
    data: Partial<ExternalCollectionNoticeModel>
  ): Observable<SingleItemResponse<ExternalCollectionNoticeModel>> {
    const endpoint = `${this.endpoint}/create-external`;
    return this.http.post<SingleItemResponse<ExternalCollectionNoticeModel>>(
      endpoint,
      data
    );
  }
  public getExternalCollectionNotice(
    params: Partial<ExternalPaymentParamsModel>
  ): Observable<CollectionNoticeModel> {
    const endpoint = `${environment.apiUrl}/v1/external/collection-notices/${
      params.collectionNoticeId
    }${`?${new URLSearchParams(params as any).toString()}`}`;
    return this.http
      .get<SingleItemResponse<CollectionNoticeModel>>(endpoint)
      .pipe(
        map((result) => {
          return result.data;
        })
      );
  }

  public resendEmail(
    collectionNoticeId: string
  ): Observable<SingleItemResponse<ExternalCollectionNoticeModel>> {
    const endpoint = `${this.endpoint}/${collectionNoticeId}/external/resend-email`;
    return this.http.post<SingleItemResponse<ExternalCollectionNoticeModel>>(
      endpoint,
      null
    );
  }
}
