import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BinInfoModel,
  PaymentMethodModel,
  SingleItemResponse,
  VerifyPaymentMethodsModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@rcsa-frontend/environments/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodsService extends Api<PaymentMethodModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<PaymentMethodModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/account/payment-methods`;
  }
  public updatePayment(
    data: PaymentMethodModel
  ): Observable<SingleItemResponse<PaymentMethodModel>> {
    const endpoint = `${this.endpoint}/${data.id}`;
    return this.apiService.put(endpoint, JSON.stringify(data));
  }

  public createPaymentMethod(
    data: PaymentMethodModel
  ): Observable<PaymentMethodModel> {
    const endpoint = `${environment.apiUrl}/v1/account/payment-methods/tokenize-save-card`;
    return this.http
      .post<SingleItemResponse<PaymentMethodModel>>(
        endpoint,
        JSON.stringify(data)
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  public getBinInfo(bin: string): Observable<BinInfoModel> {
    const endpoint = `${environment.apiUrl}/v1/account/payment-methods/bin-info/${bin}`;

    return this.http.get<SingleItemResponse<BinInfoModel>>(endpoint).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  public verifyPaymentMethod(
    data: VerifyPaymentMethodsModel,
    isExternal: boolean
  ): Observable<PaymentMethodModel> {
    const endpoint = `${environment.apiUrl}/v1/${
      isExternal ? 'external/' : ''
    }account/payment-methods/${data.id}/verify`;
    return this.http
      .post<SingleItemResponse<PaymentMethodModel>>(
        endpoint,
        JSON.stringify(data)
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }
}
