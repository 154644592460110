import {
  Computed,
  DataAction,
  Payload,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { BackEndPermissionMap } from '@core/models';
import { NgxsOnInit, State } from '@ngxs/store';
import { Observable, map } from 'rxjs';

export class PermissionsStateModel {
  public permissions!: BackEndPermissionMap;
}

@Persistence()
@StateRepository()
@State<PermissionsStateModel>({
  name: 'permissions',
  defaults: { permissions: {} },
})
@Injectable()
export class PermissionsState
  extends NgxsDataRepository<PermissionsStateModel>
  implements NgxsOnInit
{
  @Computed()
  public get permissions$(): Observable<BackEndPermissionMap> {
    return this.state$.pipe(map((state) => state.permissions));
  }

  @DataAction()
  public addPermissions(
    @Payload('permissions') permissions: BackEndPermissionMap
  ): void {
    this.ctx.patchState({
      permissions,
    });
  }
}
