export enum FakeApisEnum {
  ROLE = 'role',
  ROLE_PERMISSION = 'rolePermission',
}

export enum HttpMethodsEnum {
  GET = 'get',
  POST = 'post',
  DELETE = 'delete',
  PATCH = 'patch',
  PUT = 'put',
}
