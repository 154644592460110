import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PagerSettings } from '@progress/kendo-angular-listview';
@Component({
  selector: 'app-generic-list',
  templateUrl: './generic-list.component.html',
  styleUrls: ['./generic-list.component.scss'],
})
export class GenericListComponent {
  @Input() data: any[] = [];
  @Input() textField: string;
  @Input() valueField: string;
  @Input() pageable = false;
  @Input() actionButton = false;
  @Input() actionIcon: string;
  @Output() actionButtonClicked = new EventEmitter();
  public skip = 0;
  public pagerSettings: PagerSettings = {
    pageSizeValues: false,
    buttonCount: 9,
    info: false,
  };

  public onActionClicked(valueField: string) {
    this.actionButtonClicked.next(valueField);
  }
}
