import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { USER_DEFAULT_ROUTE } from '@core/constants';
import { SessionState } from '@core/state/shared';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

export const isAdminGuard: CanActivateFn = () => {
  const sessionState = inject(SessionState),
    store = inject(Store);

  return sessionState.isAdmin
    ? true
    : store.dispatch(new Navigate([USER_DEFAULT_ROUTE]));
};
