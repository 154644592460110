import { CollectionNoticeModel } from './collection-notice.model';
import { ApiModel } from './generic-state.model';
import { PaymentInfoModel } from './payment-info.model';

export interface InvoiceModel extends ApiModel {
  date: string;
  totalAmount: number;
  csdCertificate: string;
  fiscalFolio: string;
  internalFolio: string;
  emitterName: string;
  emitterRfc: string;
  emitterAddress: string;
  emitterFiscalRegime: string;
  receptorName: string;
  receptorRfc: string;
  receptorAddress: string;
  receptorFiscalRegime: string;
  rfcReceptor: string;
  rfcEmitter: string;
  paymentForm: string;
  writenAmount: string;
  satChain: string;
  cfdiSeal: string;
  satSeal: string;
  qrString: string;
  pdfBase64: string;
  xml: string;
  uuid: string;
  subConcepts: InvoiceConceptModel[];
  status: string;
  payment: PaymentInfoModel[];
  collectionNotice: CollectionNoticeModel;
  receptor: string;
  type: string;
  jsonData: string;
  country: string;
  currency: string;
  payments: PaymentInfoModel[];
}

export interface InvoiceConceptModel {
  cantidad: number;
  claveProdServ: string;
  claveUnidad: string;
  descripcion: string;
  importe: number;
  noIdentificacion: string;
  objetoImp: string;
  valorUnitario: number;
  complementoConcepto: ConceptComplementModel;
  impuestos: ConceptTaxModel;
  ivaItem: number;
  montoDescu: number;
}

interface ConceptComplementModel {
  Any: [];
}

interface ConceptTaxModel {
  Traslados: [];
}

export interface StampingParamsModel {
  rfc_id: string;
  cfdi_use?: string;
  stamp_date: string | null;
  card_type?: string;
  email?: string;
}

export interface BillingLogModel extends ApiModel {
  invoiceId: string;
  ip: string;
  action: string;
  userEmail: string;
  userIsImpersonate: boolean;
  userId: string;
  context: any;
  result: any;
}

export enum InvoiceStatus {
  CANCEL = 'cancel',
  STAMP = 'stamp',
  STAMP_PENDING = 'stamp_pending',
  ERROR = 'error',
}
