import { NgxsDataPluginModule } from '@angular-ru/ngxs';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { breadcrumbsFactory } from '@core/services/layout/breadcrumbs.factory';
import { BreadcrumbService } from '@core/services/layout/breadcrumbs.service';
import {
  AlertsState,
  CatalogsState,
  CurrentUserState,
  FilterState,
  LayoutState,
  VersionState,
} from '@core/state/shared';
import { environment } from '@env/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import {
  LOCAL_STORAGE_ENGINE,
  NgxsStoragePluginModule,
} from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { FakeBackendInterceptor } from '@rcsa-frontend/fake-backend/fake-backend.interceptor';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import {
  AuthInterceptor,
  ContentTypeInterceptor,
  ResponseInterceptor,
  UnauthorizedInterceptor,
} from './interceptors';
import { States } from './state';
import { AuthorizationState, SessionState } from './state/shared';

@NgModule({
  declarations: [],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentTypeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: breadcrumbsFactory,
      deps: [BreadcrumbService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FakeBackendInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ],
  imports: [
    DialogModule,
    NgxsModule.forRoot(States, {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: [
        {
          key: AuthorizationState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: CatalogsState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: CurrentUserState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: LayoutState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: SessionState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: FilterState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: VersionState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: AlertsState,
          engine: LOCAL_STORAGE_ENGINE,
        },
      ],
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsDataPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NotificationModule,
  ],
  exports: [
    NgxsModule,
    NgxsLoggerPluginModule,
    NgxsReduxDevtoolsPluginModule,
    NgxsDataPluginModule,
    NgxsStoragePluginModule,
    NgxsRouterPluginModule,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (core) {
      throw new Error('You should import core module only in the root module');
    }
  }
}
