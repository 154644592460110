import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  FamilyModel,
  GradeModel,
  SchoolModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchoolService extends Api<SchoolModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<SchoolModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/schools`;
  }

  public getFamiliesBySchool(
    schoolId: string,
    cycleId: string
  ): Observable<FamilyModel[]> {
    const url = `${environment.apiUrl}/v1/admin/configuration/schools/${schoolId}/cycle/${cycleId}/families`;
    return this.http.get<CollectionResponse<FamilyModel>>(url).pipe(
      map((respose) => {
        return respose.data.items;
      })
    );
  }

  public getGradesBySchool(schoolId: string): Observable<GradeModel[]> {
    const url = `${environment.apiUrl}/v1/admin/configuration/schools/${schoolId}/grades`;
    return this.http.get<GradeModel[]>(url).pipe(
      map((response: any) => {
        return response.grades as GradeModel[];
      })
    );
  }
}
