import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentCardModel } from '@core/models';
import { SPEIPaymentModel } from '@core/models/payment-spei.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentSpeiService extends Api<SPEIPaymentModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<SPEIPaymentModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/payments/payment-management/spei`;
  }

  public printReference(id: string, isAdmin = false): Observable<Blob> {
    const endpoint = isAdmin
      ? `${environment.apiUrl}/v1/admin/payments/payment-management/spei`
      : `${this.endpoint}`;
    return this.http.get(`${endpoint}/pdf/${id}`, {
      responseType: 'blob',
    });
  }

  public multiplePayment(data: PaymentCardModel): Observable<SPEIPaymentModel> {
    const endpoint = `${this.endpoint}/multiple`;
    const params = JSON.stringify(data);
    return this.http.post<SPEIPaymentModel>(endpoint, params);
  }
}
