import { StateModel } from '@core/models';

export const statesFake: StateModel[] = [
  {
    id: '89c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Aguascalientes',
    abbreviation: 'Ags.',
  },
  {
    id: '8bc90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Baja California',
    abbreviation: 'B.C.',
  },
  {
    id: '8dc90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Baja California Sur',
    abbreviation: 'B.C.S.',
  },
  {
    id: '8fc90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Campeche',
    abbreviation: 'Camp.',
  },
  {
    id: '97c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Coahuila',
    abbreviation: 'Coah.',
  },
  {
    id: '99c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Colima',
    abbreviation: 'Col.',
  },
  {
    id: '91c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Chiapas',
    abbreviation: 'Chis.',
  },
  {
    id: '93c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Chihuahua',
    abbreviation: 'Chih.',
  },
  {
    id: '95c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Ciudad de México',
    abbreviation: 'CDMX',
  },
  {
    id: '9bc90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Durango',
    abbreviation: 'Dgo.',
  },
  {
    id: '9fc90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Guanajuato',
    abbreviation: 'Gto',
  },
  {
    id: 'a1c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Guerrero',
    abbreviation: 'Gro.',
  },
  {
    id: 'a3c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Hidalgo',
    abbreviation: 'Hgo.',
  },
  {
    id: 'a5c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Jalisco',
    abbreviation: 'Jal.',
  },
  {
    id: '9dc90ea7-47db-ea11-a823-000d3a33f176',
    name: 'México',
    abbreviation: 'Méx.',
  },
  {
    id: 'a7c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Michoacán',
    abbreviation: 'Mich.',
  },
  {
    id: 'a9c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Morelos',
    abbreviation: 'Mor.',
  },
  {
    id: 'abc90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Nayarit',
    abbreviation: 'Nay.',
  },
  {
    id: 'adc90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Nuevo León',
    abbreviation: 'N.L.',
  },
  {
    id: 'afc90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Oaxaca',
    abbreviation: 'Oax.',
  },
  {
    id: 'b1c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Puebla',
    abbreviation: 'Pue.',
  },
  {
    id: 'b3c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Querétaro',
    abbreviation: 'Qro.',
  },
  {
    id: 'b5c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Quintana Roo',
    abbreviation: 'Q.R.',
  },
  {
    id: 'b7c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'San Luis Potosí',
    abbreviation: 'S.L.P.',
  },
  {
    id: 'b9c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Sinaloa',
    abbreviation: 'Sin.',
  },
  {
    id: 'bbc90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Sonora',
    abbreviation: 'Son.',
  },
  {
    id: 'bdc90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Tabasco',
    abbreviation: 'Tab.',
  },
  {
    id: 'bfc90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Tamaulipas',
    abbreviation: 'Tams.',
  },
  {
    id: 'c1c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Tlaxcala',
    abbreviation: 'Tlax.',
  },
  {
    id: 'c3c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Veracruz',
    abbreviation: 'Ver.',
  },
  {
    id: 'c5c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Yucatán',
    abbreviation: 'Yuc.',
  },
  {
    id: 'c7c90ea7-47db-ea11-a823-000d3a33f176',
    name: 'Zacatecas',
    abbreviation: 'Zac.',
  },
];
