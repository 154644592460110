import { Environment } from '@core/models';

export const environment: Environment = {
  apiUrl: 'https://pagos-api.semperaltius.edu.mx',
  portalUrl: 'https://pagos.semperaltius.edu.mx',
  versionUrl: 'version.json',
  chat: {
    adminUrl:
      'https://static.zdassets.com/ekr/snippet.js?key=b31b1a77-9185-4bd4-83d8-3b5fc55a00cc',
    userUrl:
      'https://static.zdassets.com/ekr/snippet.js?key=ade669ef-43fc-4d45-aac9-d31b0da28930',
  },
  enable3DS: false,
  isSyncfyProd: true,
  tokenizerRouteId: 'QCEIJSWFSU',
  tokenizerEnvironment: 'production',
  isTestEnvironment: false,
  merchantId: '48322226451a42249bebd45bd1a4dc1d',
  production: true,
  productionPortalUrl: 'https://pagos.semperaltius.edu.mx/',
  sentry: { environment: 'production' },
  support: {
    adminParam:
      '&return_to=https%3A%2F%2Fsoporte-pagos-admin.semperaltius.edu.mx%2Fhc%2Fes-419&role=consumer&theme=hc',
    ssoUrl: 'https://rcsasupport.zendesk.com/access/jwt?jwt=',
    userParam:
      '&return_to=https%3A%2F%2Fsoporte-pagos.semperaltius.edu.mx%2Fhc%2Fes-419&role=consumer&theme=hc',
    ticketsUrl: 'https://rcsasupport.zendesk.com/agent/tickets',
  },
  reporterUrl: 'https://rcsa-sandbox-dynamic-reporter.syncfy.dev',
  maxReferenceAmount: 20000,
  allowedConceptsMSI: [
    { IN: 'ee73edaf-45d2-516a-adb3-b4efeb8b66fa' },
    { RI: '967a7d9c-0d62-5a39-a139-12fc95b43a52' },
  ],
  featureFlagsEnabled: true,
  tokenizeCards: false,
  uriHubPlusReporter: 'https://rcsa-sandbox-dynamic-reporter.syncfy/login/',
  enabledSwitcherApplication: false,
};
