import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FeatureFlagAccessModel,
  FeatureFlagCodes,
  SingleItemResponse,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsAccessService extends Api<FeatureFlagAccessModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<FeatureFlagAccessModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/account/feature-flags-access`;
  }

  public checkStatus(
    code: FeatureFlagCodes,
    schoolIds: string
  ): Observable<FeatureFlagAccessModel> {
    const url = `${this.endpoint}/status/${code}/${schoolIds}`;
    return this.http.get<SingleItemResponse<FeatureFlagAccessModel>>(url).pipe(
      map((respose) => {
        return respose.data;
      })
    );
  }
}
