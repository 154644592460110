import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getAllWithoutId } from '@core/functions';
import { CollectionResponse, PaginationModel } from '@core/models';
import { AccountStateModel } from '@core/models/account-state.model';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountStateService extends Api<AccountStateModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<AccountStateModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/payments/account-status/index`;
  }

  public export(params: PaginationModel): Observable<Blob> {
    params.limit = 100000;
    const endpoint = `${environment.apiUrl}/v1/admin/payments/account-status${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public override getAll(
    paginationData?: PaginationModel | undefined
  ): Observable<CollectionResponse<AccountStateModel>> {
    return getAllWithoutId<AccountStateModel>(
      this.apiService,
      this.endpoint,
      paginationData
    );
  }
}
