import { Injectable } from '@angular/core';
import { ExternalInvoiceModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ExternalInvoicesService extends Api<ExternalInvoiceModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<ExternalInvoiceModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/invoicing/invoices`;
  }
}
