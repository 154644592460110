import { Injectable } from '@angular/core';
import { FeatureFlagModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class FeaturesFlagsService extends Api<FeatureFlagModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<FeatureFlagModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/feature-flags`;
  }
}
