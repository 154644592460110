import { Injectable } from '@angular/core';
import { CatalogModel } from '@core/models';
import { environment } from '@env/environment';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CardTypesService extends Api<CatalogModel> {
  override endpoint: string;

  constructor(protected override readonly apiService: ApiService<CatalogModel>) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/account/payment-methods/card-type`;
  }
}
