import { PagerSettings } from '@progress/kendo-angular-grid';
import { ComboboxDataModel, Frecuency, RecursiveDrawerItem } from '../models';
import { ADMIN_FRONTEND_PERMISSIONS } from './security.constants';

export const APP_QUERY_PARAMS = {
  billPayment: 'facturarPago',
  helpCenter: 'centroDeAyuda',
  payCollectionNotice: 'pagarAviso',
  selectBusiness: 'razonSocial',
  regionId: 'regionId',
  businessId: 'businessId',
  schoolId: 'schoolId',
};

export const SUPPORT_DRAWER_ITEM: RecursiveDrawerItem = {
  text: 'SOPORTE',
  cssClass: 'drawer-mobile-support',
  children: [
    {
      text: 'Centro de soporte',
      cssClass: 'drawer-mobile-support',
      actionEvent: () => {
        window.open(
          `${window.location.origin}${
            window.location.pathname.includes('admin') ? '/admin' : ''
          }/soporte`,
          '_blank'
        );
      },
    },
    {
      text: 'Chat de soporte',
      cssClass: 'drawer-mobile-support',
      actionEvent: () => {
        (window as any).zE('messenger', 'open');
      },
    },
  ],
};
export const USER_EVENTS = {
  route: 'eventos',
  text: 'EVENTOS',
  children: [
    {
      text: 'Torneo de la Amistad',
      route: 'torneo-amistad/general/avisos/',
    },
  ],
};

export const CURRENT_CYCLE = '2024-2025';

export const EVENT_MODULES = [
  {
    text: 'General',
    route: 'general',
    permission: ADMIN_FRONTEND_PERMISSIONS.events.crud,
  },
  {
    text: 'Avisos de Cobranza',
    route: 'aviso',
    permission: ADMIN_FRONTEND_PERMISSIONS.events.collections,
  },
  {
    text: 'Pagos',
    route: 'historial-pagos',
    permission: ADMIN_FRONTEND_PERMISSIONS.events.payments,
  },
  {
    text: 'Facturación',
    route: 'historial-facturacion',
    permission: ADMIN_FRONTEND_PERMISSIONS.events.invoices,
  },
];

export const ADMIN_EVENTS = {
  route: 'eventos',
  text: 'EVENTOS',
  children: [],
};

export const ADMIN_DEFAULT_ROUTE = '/admin/app/mi-cuenta/perfil';
export const ADMIN_DRAWER_ITEMS: RecursiveDrawerItem[] = [
  {
    route: 'configuracion',
    text: 'CONFIGURACIÓN',
    children: [
      {
        text: 'Parámetros',
        route: 'parametros',
        permission: ADMIN_FRONTEND_PERMISSIONS.configuration.parameters,
      },
      {
        text: 'Catálogos',
        route: 'catalogos',
        children: [
          {
            text: 'Razones Sociales',
            route: 'razones-sociales',
            permission: ADMIN_FRONTEND_PERMISSIONS.configuration.business,
          },
        ],
      },
      {
        text: 'Familias',
        route: 'familias',
        permission: ADMIN_FRONTEND_PERMISSIONS.configuration.families,
      },
      {
        text: 'Normas Firmadas',
        route: 'normas-administrativas',
        permission:
          ADMIN_FRONTEND_PERMISSIONS.configuration.administrativeRules,
      },
      {
        text: 'Precios',
        route: 'precios',
        permission: ADMIN_FRONTEND_PERMISSIONS.configuration.prices,
      },
      {
        text: 'Funcionalidades',
        route: 'funcionalidades',
        permission: ADMIN_FRONTEND_PERMISSIONS.configuration.featureFlags,
      },
    ],
  },
  {
    route: 'conceptos',
    text: 'CONCEPTOS',
    children: [
      {
        text: 'Subconceptos',
        route: 'subconceptos',
        permission: ADMIN_FRONTEND_PERMISSIONS.concepts.concepts,
      },
      {
        text: 'Asignaciones',
        route: 'asignaciones',
        permission: ADMIN_FRONTEND_PERMISSIONS.concepts.assignment,
      },
    ],
  },
  {
    route: 'externos',
    text: 'EXTERNOS',
    children: [
      {
        route: 'avisos-cobranza',
        text: 'Avisos de cobranza',
        permission: ADMIN_FRONTEND_PERMISSIONS.external.collectionNotices,
      },
      {
        route: 'pagos',
        text: 'Pagos',
        permission: ADMIN_FRONTEND_PERMISSIONS.external.payments,
      },
      {
        route: 'facturacion',
        text: 'Facturación',
        permission: ADMIN_FRONTEND_PERMISSIONS.external.billing,
      },
    ],
  },
  {
    route: 'descuentos',
    text: 'DESCUENTOS',
    children: [
      {
        route: 'descuentos',
        text: 'Descuentos',
        permission: ADMIN_FRONTEND_PERMISSIONS.discounts.administration,
      },
      {
        text: 'Becas',
        route: 'becas',
        children: [
          {
            text: 'Activas',
            permission: ADMIN_FRONTEND_PERMISSIONS.scholarships,
          },
          {
            text: 'Alta de Becas',
            permission: ADMIN_FRONTEND_PERMISSIONS.scholarships, //TODO: Implement permission
            route: 'alta',
          },
          {
            text: 'Por Aprobar',
            permission: ADMIN_FRONTEND_PERMISSIONS.scholarships, //TODO: Implement permission
            route: 'por-aprobar',
          },
        ],
      },
      {
        text: 'Reglas',
        route: 'reglas',
        permission: ADMIN_FRONTEND_PERMISSIONS.discounts.rules,
      },
    ],
  },
  {
    route: 'cupones-descuento',
    text: 'CUPONES DE DESCUENTO',
    children: [
      {
        text: 'Cupones',
        route: 'catalogo',
        permission: ADMIN_FRONTEND_PERMISSIONS.discountCoupons.catalog,
      },
      {
        text: 'Historial',
        route: 'historial',
        permission: ADMIN_FRONTEND_PERMISSIONS.discountCoupons.history,
      },
    ],
  },
  {
    route: 'aviso',
    text: 'AVISO DE COBRANZA',
    children: [
      {
        text: 'Avisos de cobranza',
        permission: ADMIN_FRONTEND_PERMISSIONS.collection.view,
      },
      {
        text: 'Revisión de avisos',
        route: 'revision',
        permission: ADMIN_FRONTEND_PERMISSIONS.collection.review,
      },
      {
        text: 'Simulación de avisos',
        route: 'simulacion',
        permission: ADMIN_FRONTEND_PERMISSIONS.collection.dryRun,
      },
      {
        route: 'anomalias',
        text: 'Anomalias',
        permission: ADMIN_FRONTEND_PERMISSIONS.collection.anomalies,
      },
      {
        route: 'aviso-pagos-recurrente',
        text: 'Aviso Pagos Recurrente',
        permission:
          ADMIN_FRONTEND_PERMISSIONS.collection.failedRecurringPayment,
      },
      {
        route: 'aviso-asientos-contables/financial-entries',
        text: 'Aviso Asientos Contables',
        permission: ADMIN_FRONTEND_PERMISSIONS.collection.financialEntries,
      },
    ],
  },
  {
    route: 'pagos',
    text: 'PAGOS',
    children: [
      {
        text: 'Historial de pagos',
        permission: ADMIN_FRONTEND_PERMISSIONS.payments.history,
      },
      {
        route: 'proceso-validacion',
        text: 'En Proceso de Validación',
        permission: ADMIN_FRONTEND_PERMISSIONS.payments.validationInProcess,
      },
      {
        route: 'comisiones',
        text: 'Comisiones',
        permission: ADMIN_FRONTEND_PERMISSIONS.payments.fees,
      },
      {
        route: 'abandonados',
        text: 'Pagos Abandonados',
        permission: ADMIN_FRONTEND_PERMISSIONS.abandonedPayments,
      },
      {
        route: 'intentos',
        text: 'Intentos',
        permission: ADMIN_FRONTEND_PERMISSIONS.payments.attempts,
      },
      {
        route: 'contracargos',
        text: 'Contracargos',
        permission: ADMIN_FRONTEND_PERMISSIONS.payments.chargebacks,
      },
      {
        text: 'Pagos TPV',
        route: 'pagos-tpv',
        permission: ADMIN_FRONTEND_PERMISSIONS.payments.tvpReport,
      },
    ],
  },
  {
    route: 'contabilidad',
    text: 'CONTABILIDAD',
    children: [
      {
        text: 'Asientos Contables',
        route: 'asientos',
        permission: ADMIN_FRONTEND_PERMISSIONS.accounting,
      },
    ],
  },
  {
    route: 'facturacion',
    text: 'FACTURACIÓN',
    children: [
      {
        text: 'Historial',
        permission: ADMIN_FRONTEND_PERMISSIONS.billing.invoices,
      },
    ],
  },
  {
    route: 'reportes',
    text: 'REPORTES',
    children: [
      {
        route: 'operativos',
        text: 'Reportes Operativos',
        children: [
          {
            text: 'Balanza de Pagos',
            route: 'balanza-de-pagos',
            permission: ADMIN_FRONTEND_PERMISSIONS.reports.balance,
          },
          {
            text: 'Cartera Vencida',
            route: 'cartera',
            permission: ADMIN_FRONTEND_PERMISSIONS.reports.pastDueReport,
          },
          {
            text: 'Concentrado de Cargos por Categorías',
            route: 'cargos-por-categoria',
            permission: ADMIN_FRONTEND_PERMISSIONS.reports.chargesByCategory,
          },
          {
            text: 'Concentrado de Ingresos',
            route: 'concentrado-ingresos-general',
            permission: ADMIN_FRONTEND_PERMISSIONS.reports.incomeConcentration,
          },
          {
            text: 'Detalle de Ingresos',
            route: 'concentrado-ingresos',
            permission: ADMIN_FRONTEND_PERMISSIONS.reports.incomeConcentration,
          },
          {
            text: 'Estado de Cuenta',
            route: 'estado-de-cuenta',
            permission: ADMIN_FRONTEND_PERMISSIONS.reports.accountState,
          },
          {
            text: 'Informe de becas',
            route: 'informe-becas',
            permission: ADMIN_FRONTEND_PERMISSIONS.reports.scholarshipReport,
          },
          {
            text: 'Inscripción y Reinscripción',
            route: 'avisos-in-ri',
            permission:
              ADMIN_FRONTEND_PERMISSIONS.reports.inRiCollectionsReport,
          },
        ],
      },
      {
        text: 'Administrador de Reportes',
        route: 'reporteador-dinamico',
        permission: ADMIN_FRONTEND_PERMISSIONS.reports.reportManager,
      },
      {
        text: 'Corte de Caja Diario',
        route: 'corte-de-caja',
        permission: ADMIN_FRONTEND_PERMISSIONS.reports.dailyPayments,
      },
      {
        text: 'Dispersiones',
        route: 'dispersiones',
        permission: ADMIN_FRONTEND_PERMISSIONS.reports.ordersReport,
      },
      {
        text: 'Generación automática de avisos',
        route: 'generacion-automatica-avisos',
        permission:
          ADMIN_FRONTEND_PERMISSIONS.reports.automaticCollectionGeneration,
      },
      {
        text: 'Generación de avisos de cobranza',
        route: 'generacion-avisos',
        permission: ADMIN_FRONTEND_PERMISSIONS.reports.collectionGeneration,
      },
    ],
  },
  {
    route: 'seguridad',
    text: 'SEGURIDAD',
    children: [
      {
        text: 'Usuarios',
        route: 'usuarios',
        permission: ADMIN_FRONTEND_PERMISSIONS.security.users,
      },
      {
        text: 'Administradores',
        route: 'administradores',
        permission: ADMIN_FRONTEND_PERMISSIONS.security.administrators,
      },
      {
        text: 'Auditoria',
        route: 'auditoria',
        permission: ADMIN_FRONTEND_PERMISSIONS.security.audit,
      },
      {
        text: 'Bitácora',
        route: 'bitacora',
        permission: ADMIN_FRONTEND_PERMISSIONS.security.crm,
      },
      // {
      //   text: 'Roles',
      //   route: 'roles',
      //   permission: ADMIN_FRONTEND_PERMISSIONS.security.roles,
      // }
    ],
  },
  {
    route: 'mi-cuenta',
    text: 'MI CUENTA',
    children: [
      {
        route: 'perfil',
        text: 'Perfil',
      },
    ],
  },
  SUPPORT_DRAWER_ITEM,
];
export const ADMIN_UNLOGGED_DEFAULT_ROUTE = '/admin';

export const BREADCRUMB_ROUTER_DATA = 'breadcrumb';

export const END_POINTS = {
  ADMIN: {
    LOGIN: '/v1/admin/auth/login',
    FORGOT_PASSWORD: '/v1/admin/auth/forgot-password',
    RESET_PASSWORD: '/v1/admin/auth/reset-password',
    CHANGE_PASSWORD: '/v1/auth/change-password',
  },
  USER: {
    LOGIN: '/v1/auth/login',
    FORGOT_PASSWORD: '/v1/auth/forgot-password',
    RESET_PASSWORD: '/v1/auth/reset-password',
    CHANGE_PASSWORD: '/v1/auth/change-password',
  },
};

export const LOGOUT_EXCLUDED_UNAUTHORIZED_ENDPOINTS = [
  END_POINTS.ADMIN.CHANGE_PASSWORD,
  END_POINTS.USER.CHANGE_PASSWORD,
  END_POINTS.ADMIN.LOGIN,
  END_POINTS.USER.LOGIN,
  END_POINTS.ADMIN.RESET_PASSWORD,
  END_POINTS.USER.RESET_PASSWORD,
];

export const MASKS = {
  phoneNumber: '+00 0000000000',
};

export const USER_DEFAULT_ROUTE = '/app/mi-cuenta';
export const USER_DRAWER_ITEMS: RecursiveDrawerItem[] = [
  {
    route: 'avisos-cobranza',
    text: 'AVISO DE COBRANZA',
    children: [
      {
        text: 'Avisos de Cobranza',
        permission: 'collections.collection-notices.list',
      },
    ],
  },
  {
    route: 'historial-pagos',
    text: 'PAGOS',
    children: [
      {
        text: 'Historial de Pagos',
        permission: 'payments.payment-management.list',
      },
    ],
  },
  {
    route: 'historial-facturacion',
    text: 'FACTURACIÓN',
    children: [
      {
        text: 'Historial de Facturación',
      },
    ],
  },

  {
    route: 'mi-cuenta',
    text: 'MI CUENTA',
    children: [
      {
        text: 'Perfil',
        route: 'perfil',
        permission: 'account.profile.list',
      },
      {
        text: 'Normas Administrativas',
        route: 'normas-administrativas',
        permission: 'account.invoicing-data.list',
      },
      {
        text: 'Métodos de Pago',
        route: 'metodos',
        permission: 'account.payment-methods.list',
      },
      {
        text: 'Datos de Facturación',
        route: 'facturacion',
        permission: 'account.invoicing-data.list',
      },
    ],
  },
  SUPPORT_DRAWER_ITEM,
];
export const USER_ADMINISTRATIVE_RULES = {
  text: 'Normas Administrativas',
  route: 'normas-administrativas',
  permission: 'account.invoicing-data.list',
};

export const RVOE_MENU_ITEM = {
  text: 'RVOE',
  route: 'rvoe',
  permission: ADMIN_FRONTEND_PERMISSIONS.configuration.rvoe,
};

export const USER_UNLOGGED_DEFAULT_ROUTE = '/';

export const GENERIC_RFC = 'XAXX010101000';

export const FILES_SOURCES = {
  XMl: 'data:application/text;xml',
  PDF: 'data:application/pdf;base64',
};

export const PAGEABLE_SETTINGS: PagerSettings = {
  buttonCount: 10,
  type: 'numeric',
  pageSizes: [10, 20, 50],
  position: 'bottom',
};

export const REGEX = {
  noBlanks: /^\S+$/,
};

export const PAYMENTS_FRECUENCIES: Frecuency[] = [
  {
    id: 'monthly',
    value: 'Mensual',
  },
  {
    id: 'semester',
    value: '2 exhibiciones',
  },
  {
    id: 'annual',
    value: '1 exhibición',
  },
];

export const DISCOUNT_TYPES = {
  percentage: 'percentage',
  fixed: 'fixed',
};
export const LEDGER_ACCOUNT_CATEGORIES = {
  scholarships: 'scholarships',
  discounts: 'discounts',
  refunds: 'refunds',
};

export const CONFIG_SYSTEM_PARAMS = {
  PARTIALITIES_NUMBER: 'PARTIALITIES_NUMBER',
  PARTIALITIES_BILLING_DAY_MAX: 'PARTIALITIES_BILLING_DAY_MAX',
  SHOW_GROUP_COLUMN: 'SHOW_GROUP_COLUMN',
  FAMILY_PAYMENTS: 'PAYMENTS',
  STUDENTS: 'STUDENTS',
};
export const PARAMETER_CODES = {
  RECURRENT_PAYMENT: 'RECURRENT_PAYMENT',
  PAYMENT_VALIDATION: 'PAYMENT_VALIDATION',
};

export const LEDGER_ACCOUNTS: ComboboxDataModel[] = [
  {
    id: LEDGER_ACCOUNT_CATEGORIES.scholarships,
    description: 'Becas',
    ledgerAccountCategory: LEDGER_ACCOUNT_CATEGORIES.scholarships,
  },
  {
    id: LEDGER_ACCOUNT_CATEGORIES.discounts,
    description: 'Descuentos',
    ledgerAccountCategory: LEDGER_ACCOUNT_CATEGORIES.discounts,
  },
  {
    id: LEDGER_ACCOUNT_CATEGORIES.refunds,
    description: 'Devoluciones',
    ledgerAccountCategory: LEDGER_ACCOUNT_CATEGORIES.refunds,
  },
];

export const DOCUMENTS_TYPES_SV: ComboboxDataModel[] = [
  {
    id: 'dui',
    description: 'DUI',
  },
  {
    id: 'pasaporte',
    description: 'Pasaporte',
  },
];
export const DOCUMENTS_TYPES_MX: ComboboxDataModel[] = [
  {
    id: 'ine',
    description: 'INE',
  },
  {
    id: 'pasaporte',
    description: 'Pasaporte',
  },
];
