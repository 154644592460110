export const adminReports = {
  data: {
    created_at: '2024-07-17T21:15:49.000000Z',
    updated_at: '2024-07-29T19:42:20.000000Z',
    id: 'bda8b567-4481-11ef-995e-0242c0a8e401',
    changed_on_utc: '2024-07-17T20:23:24.000000Z',
    dashboard_title: 'FCC New Coder Survey 2018',
    published: 1,
    slug: null,
    status: 'published',
    is_active: true,
    url: '/superset/dashboard/11/',
    thumbnail_url:
      '/api/v1/dashboard/11/thumbnail/1cb2457674c1a9122f9cc1ec1e5b007f/',
    tags: [],
    json_metadata: null,
    position_json: null,
    roles: [
      {
        object: 'role',
        id: 5,
        name: 'regional_administrator',
        description: 'Administrador Regional',
        created_at: '2024-07-19 00:10:14',
        updated_at: '2024-07-19 00:10:14',
      },
    ],
    users: [
      {
        object: 'user',
        id: '00144407-ca25-eb11-a813-000d3a33f061',
        full_name: 'Saúl Virgen Enríquez',
        name: 'Saúl',
        last_name: 'Virgen',
        second_last_name: 'Enríquez',
        person_id: '00144407-ca25-eb11-a813-000d3a33f061',
        created_at: '2023-10-23 09:18:56',
        updated_at: null,
      },
    ],
  },
  status: true,
  message: 'Dashboard Dinamico actualizado con éxito',
  meta: null,
  error: null,
  show: true,
};
