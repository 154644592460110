<div class="authentication-body">
  <div class="welcome user">
    <div class="hub">
      <img
        src="assets/images/authentication/hub.svg"
        alt="HUB+"
        width="309"
        height="156"
      />
    </div>
    <div class="welcome-bottom">
      <img
        class="authentication-icon"
        src="assets/images/authentication/external.svg"
        alt=""
        width="76"
        height="61"
      />
      <p>Centro de pagos y facturación externa</p>
    </div>
  </div>
  <div class="external-body">
    <router-outlet></router-outlet>
  </div>
  <div
    class="plus-background"
    style="
      background-image: url(assets/images/authentication/plus-background.svg);
    "
  ></div>
</div>
