import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { InRiCollectionNoticesReportModel } from '@core/models';
import { InRiCollectionNoticesReportService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<InRiCollectionNoticesReportModel>>({
  name: 'inRiCollectionNoticesReport',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class InRiCollectionNoticesReportState extends GenericState<InRiCollectionNoticesReportModel> {
  override service: InRiCollectionNoticesReportService;
  constructor(
    inRiCollectionNoticesReportService: InRiCollectionNoticesReportService
  ) {
    super();
    this.service = inRiCollectionNoticesReportService;
  }
}
