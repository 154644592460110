export const MULTI_ITEM_STORE_DEFAULTS = {
  totalItems: 0,
  currentPage: 1,
  limitPerPage: 20,
  items: {},
  pages: {},
  isLoading: false,
  sort: {
    field: '',
  },
};

const CATALOG_DEFAULT = {
  records: [],
  totalItems: 0,
};

export const CATALOGS_STORE_DEFAULTS = {
  areas: CATALOG_DEFAULT,
  banks: CATALOG_DEFAULT,
  business: CATALOG_DEFAULT,
  cardType: CATALOG_DEFAULT,
  classrooms: CATALOG_DEFAULT,
  countries: CATALOG_DEFAULT,
  cycles: CATALOG_DEFAULT,
  grades: CATALOG_DEFAULT,
  ledgerAccount: CATALOG_DEFAULT,
  regions: CATALOG_DEFAULT,
  schools: CATALOG_DEFAULT,
  sections: CATALOG_DEFAULT,
  states: CATALOG_DEFAULT,
  academicYears: CATALOG_DEFAULT,
  fiscalYears: CATALOG_DEFAULT,
  scholarshipsType: CATALOG_DEFAULT,
};
