import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { PastDueReportModel } from '@core/models/reports/past-due-notice.model';
import { PastDueReportService } from '@core/services/admin/reports/past-due-notice/past-due-notice.service';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<PastDueReportModel>>({
  name: 'pastDueReport',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class PastDueReportState extends GenericState<PastDueReportModel> {
  override service: PastDueReportService;
  constructor(pastDueReport: PastDueReportService) {
    super();
    this.service = pastDueReport;
  }
}
