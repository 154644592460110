import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { ExternalPaymentModel } from '@core/models';
import { ExternalPaymentsService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<ExternalPaymentModel>>({
  name: 'externalPayments',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class ExternalPaymentsState extends GenericState<ExternalPaymentModel> {
  override service: ExternalPaymentsService;
  constructor(service: ExternalPaymentsService) {
    super();
    this.service = service;
  }
}
