import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  DynamicReportsModel,
  PermissionPayload,
  SingleItemResponse,
  UserModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DynamicReportsAdminService extends Api<DynamicReportsModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<DynamicReportsModel>,
    private httpClient: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/reports/dynamic-dashboards`;
  }

  public getDetail(
    id: string
  ): Observable<SingleItemResponse<DynamicReportsModel>> {
    const url = `${this.endpoint}/${id}/permissions`;
    return this.apiService.get(url);
  }

  public giveAccess(
    id: string,
    permissions: PermissionPayload
  ): Observable<SingleItemResponse<DynamicReportsModel>> {
    const url = `${this.endpoint}/${id}/permissions/give`;
    return this.apiService.put(url, JSON.stringify(permissions));
  }

  public revokeAccess(
    id: string,
    permissions: PermissionPayload
  ): Observable<SingleItemResponse<DynamicReportsModel>> {
    const url = `${this.endpoint}/${id}/permissions/revoke`;
    return this.apiService.put(url, JSON.stringify(permissions));
  }

  public searchUsers(name: string) {
    const url = `${this.endpoint}/users?search=${name}`;
    return this.httpClient
      .get<CollectionResponse<Partial<UserModel>>>(url)
      .pipe(map((response) => response.data.items));
  }
}
