import { LanguageModel } from '@core/models';

export enum LanguagesEnum {
  es_MX = 'es_MX',
  es_SV = 'es_SV',
}
export const COUNTRY_CODES = {
  SV: 'sv',
  MX: 'mx',
};

export const DEFAULT_LANGUAGE = LanguagesEnum.es_MX;

export const LANGUAGE_ICONS: Map<string, string> = new Map([
  [LanguagesEnum.es_MX, COUNTRY_CODES.MX],
  [LanguagesEnum.es_SV, COUNTRY_CODES.SV],
]);

export const ACCEPTANCE_LANGUAGE: Map<string, string> = new Map([
  [COUNTRY_CODES.MX, LanguagesEnum.es_MX],
  [COUNTRY_CODES.SV, LanguagesEnum.es_SV],
]);

export const REQUIRED_DIGITAL_SEAL_COUNTRIES = [COUNTRY_CODES.MX];

export const LANGUAGES: LanguageModel[] = [
  {
    name: 'Español',
    code: LanguagesEnum.es_MX,
    flagIcon: 'mx',
  },
  {
    name: 'Español',
    code: LanguagesEnum.es_SV,
    flagIcon: 'sv',
  },
];

export const TRANSLATION_KEYS = {
  ACTION: {
    CANCEL_LABEL: 'ACTION.CANCEL_LABEL',
  },
  BUTTONS: {
    CANCEL_COLLECTION_NOTICE: 'BUTTON.CANCEL_COLLECTION_NOTICE',
    CANCEL_LABEL: 'BUTTON.CANCEL_LABEL',
  },
  MENU: {
    CANCEL_COLLECTION_NOTICE: 'MENU.CANCEL_COLLECTION_NOTICE',
  },
  LABELS: {
    BUSINESS_NAME: 'LABEL.BUSINESS_NAME',
    DOCUMENT_IDENTIFIER: 'LABEL.DOCUMENT_IDENTIFIER',
    REASON_CANCEL: 'LABEL.REASON_CANCEL',
    DELETE_INVOICING_DOCUMENT: 'LABEL.DELETE_INVOICING_DOCUMENT',
    DELETE_MSG_INVOICING_DOCUMENT: 'LABEL.DELETE_MSG_INVOICING_DOCUMENT',
    COLLECTION_NOTICE_CANCEL_STATUS: 'LABEL.COLLECTION_NOTICE_CANCEL_STATUS',
  },
  GRID: {
    INVOICE_STAMPED_STATUS: 'GRID.INVOICE_STAMPED_STATUS',
    INVOICE_CANCELD_STATUS: 'GRID.INVOICE_CANCELD_STATUS',
    INVOICE_ERROR_STATUS: 'GRID.INVOICE_ERROR_STATUS',
    INVOICE_STAMPING_PENDING_STATUS: 'GRID.INVOICE_STAMPING_PENDING_STATUS',
    INVOICE_CANCEL_PENDING_STATUS: 'GRID.INVOICE_CANCEL_PENDING_STATUS',
  },
};
