import { Injectable } from '@angular/core';
import {
  AcademicYear,
  BusinessModel,
  CatalogModel,
  CollectionResponse,
  CycleSchoolModel,
  FiscalYear,
  LedgerAccountModel,
  Scholarship,
  SchoolModel,
} from '@core/models';
import { RegionsService } from '@core/services/regions/regions.service';
import { CatalogsState, SessionState } from '@core/state/shared';
import { first, map } from 'rxjs';

import {
  AuthorizationService,
  BanksService,
  BusinessService,
  CardTypesService,
  ClassroomsService,
  CountriesService,
  CyclesService,
  GradesService,
  SchoolService,
  SectionsService,
  StatesService,
} from '@core/services';
import { PendingAdministrativeRulesState } from '@core/state/user';
import { LedgerAccountService } from '../ledger_account/ledger_account.service';
import { ScholarshipsTypeService } from '../scholarships-type/scholarships-type.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogsService {
  private defaultLimit = 10000;
  constructor(
    private authorizationService: AuthorizationService,
    private banksService: BanksService,
    private businesService: BusinessService,
    private cardTypesService: CardTypesService,
    private catalogsState: CatalogsState,
    private classroomsService: ClassroomsService,
    private countriesService: CountriesService,
    private cycleService: CyclesService,
    private gradesService: GradesService,
    private ledgerAccountService: LedgerAccountService,
    private regionsSerivce: RegionsService,
    private schoolService: SchoolService,
    private sectionsService: SectionsService,
    private sessionState: SessionState,
    private statesService: StatesService,
    private administrativeRulesState: PendingAdministrativeRulesState,
    private scholarshipsTypeService: ScholarshipsTypeService
  ) {}

  public init() {
    this.catalogsState.restart();
    if (this.sessionState.isAdmin) {
      this.getRegions();
      this.getBussiness();
      this.getSchools();
      this.getCycles();
      this.getSections();
      this.getGrades();
      this.getLedgerAccounts();
      this.getClassrooms();
      this.getAcademicYears();
      this.getFiscalYears();
      this.getScholarshipsTypes();
    } else {
      this.getBanks();
      this.getCardTypes();
    }
    this.getStates();
    this.getCountries();
    this.getUserData();
  }

  public reloadCatalogs(): void {
    if (this.sessionState.isAdmin && this.sessionState.snapshot.props.user) {
      this.authorizationService.loadPermissions(
        this.sessionState.snapshot.props.user.role?.id as string
      );
    }
    this.getUserData();
  }

  private getUserData(): void {
    if (this.sessionState.isAdmin) {
      return;
    }

    this.getPendingAdministrativeRules();
  }

  private getRegions(): void {
    this.regionsSerivce
      .getAll({
        page: 1,
        limit: this.defaultLimit,
      })
      .pipe(
        map((response: CollectionResponse<CatalogModel>) => {
          return response.data.items;
        })
      )
      .subscribe((records: CatalogModel[]) => {
        this.catalogsState.setCatalogItems(records, 'regions');
      });
  }

  private getBanks(): void {
    this.banksService
      .getAll({
        page: 1,
        limit: this.defaultLimit,
      })
      .pipe(
        map((response: CollectionResponse<CatalogModel>) => {
          return response.data.items;
        })
      )
      .subscribe((records: CatalogModel[]) => {
        this.catalogsState.setCatalogItems(records, 'banks');
      });
  }

  private getCardTypes(): void {
    this.cardTypesService
      .getAll({
        page: 1,
        limit: this.defaultLimit,
      })
      .pipe(
        map((response: CollectionResponse<CatalogModel>) => {
          response.data.items.map((x) => {
            x.name = x.name === 'CREDIT_CARD' ? 'Crédito' : 'Débito';
          });
          return response.data.items;
        })
      )
      .subscribe((records: CatalogModel[]) => {
        this.catalogsState.setCatalogItems(records, 'cardType');
      });
  }

  private getStates(): void {
    this.statesService
      .getAll({
        page: 1,
        limit: this.defaultLimit,
      })
      .pipe(
        map((response: CollectionResponse<CatalogModel>) => {
          return response.data.items;
        })
      )
      .subscribe((records: CatalogModel[]) => {
        this.catalogsState.setCatalogItems(records, 'states');
      });
  }

  private getBussiness(): void {
    this.businesService
      .getAll({
        page: 1,
        limit: this.defaultLimit,
      })
      .pipe(
        map((response: CollectionResponse<BusinessModel>) => {
          return response.data.items;
        })
      )
      .subscribe((records: BusinessModel[]) => {
        this.catalogsState.setCatalogItems(records, 'business');
      });
  }

  private getSchools(): void {
    this.schoolService
      .getAll({
        page: 1,
        limit: this.defaultLimit,
      })
      .pipe(
        map((response: CollectionResponse<SchoolModel>) => {
          return response.data.items;
        })
      )
      .subscribe((records: SchoolModel[]) => {
        this.catalogsState.setCatalogItems(records, 'schools');
      });
  }

  private getCycles(): void {
    this.cycleService
      .getAll({
        page: 1,
        limit: this.defaultLimit,
      })
      .pipe(
        map((response: CollectionResponse<CycleSchoolModel>) => {
          return response.data.items;
        })
      )
      .subscribe((records: CycleSchoolModel[]) => {
        this.catalogsState.setCatalogItems(records, 'cycles');
      });
  }

  private getAcademicYears(): void {
    this.cycleService
      .getAcademicYears()
      .subscribe((records: AcademicYear[]) => {
        this.catalogsState.setCatalogItems(records, 'academicYears');
      });
  }

  private getFiscalYears(): void {
    this.cycleService.getFiscalYears().subscribe((records: FiscalYear[]) => {
      this.catalogsState.setCatalogItems(records, 'fiscalYears');
    });
  }

  private getSections(): void {
    this.sectionsService
      .getAll({
        page: 1,
        limit: this.defaultLimit,
      })
      .pipe(
        map((response: CollectionResponse<CatalogModel>) => {
          return response.data.items;
        })
      )
      .subscribe((records: CatalogModel[]) => {
        this.catalogsState.setCatalogItems(records, 'sections');
      });
  }

  private getGrades(): void {
    this.gradesService
      .getAll({
        page: 1,
        limit: this.defaultLimit,
      })
      .pipe(
        map((response: CollectionResponse<CatalogModel>) => {
          return response.data.items;
        })
      )
      .subscribe((records: CatalogModel[]) => {
        this.catalogsState.setCatalogItems(records, 'grades');
      });
  }

  private getClassrooms(): void {
    this.classroomsService
      .getAll({
        page: 1,
        limit: this.defaultLimit,
      })
      .pipe(
        map((response: CollectionResponse<CatalogModel>) => {
          return response.data.items;
        })
      )
      .subscribe((records: CatalogModel[]) => {
        this.catalogsState.setCatalogItems(records, 'classrooms');
      });
  }

  private getLedgerAccounts(): void {
    this.ledgerAccountService
      .getAll({
        page: 1,
        limit: this.defaultLimit,
      })
      .pipe(
        map((response: CollectionResponse<LedgerAccountModel>) => {
          return response.data.items;
        })
      )
      .subscribe((records: LedgerAccountModel[]) => {
        this.catalogsState.setCatalogItems(records, 'ledgerAccount');
      });
  }

  private getCountries(): void {
    this.countriesService
      .getAll({
        page: 1,
        limit: this.defaultLimit,
      })
      .pipe(
        map((response: CollectionResponse<CatalogModel>) => {
          return response.data.items;
        })
      )
      .subscribe((records: CatalogModel[]) => {
        this.catalogsState.setCatalogItems(records, 'countries');
      });
  }

  private getPendingAdministrativeRules(): void {
    this.administrativeRulesState
      .getPendingAdministrativeRules()
      .pipe(first())
      .subscribe();
  }

  private getScholarshipsTypes(): void {
    this.scholarshipsTypeService
      .getScholarshipsTypes()
      .subscribe((records: Scholarship[]) => {
        this.catalogsState.setCatalogItems(records, 'scholarshipsType');
      });
  }
}
