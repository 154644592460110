import {
  Computed,
  DataAction,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { ALERTS } from '@core/constants';
import {
  PendingAdministrativeRuleModel,
  StudentAdministrativeRuleModel,
} from '@core/models';
import { AdministrativeRulesService } from '@core/services';
import { AlertsState } from '@core/state/shared';
import { State } from '@ngxs/store';
import { Observable, tap } from 'rxjs';

class PendingAdministrativeRulesStore {
  items: PendingAdministrativeRuleModel[];
}

const defaults: PendingAdministrativeRulesStore = {
  items: [],
};

@Persistence()
@StateRepository()
@State<PendingAdministrativeRulesStore>({
  name: 'pendingAdministrativeRules',
  defaults,
})
@Injectable()
export class PendingAdministrativeRulesState extends NgxsDataRepository<PendingAdministrativeRulesStore> {
  constructor(
    private administrativeRulesService: AdministrativeRulesService,
    private alertsState: AlertsState
  ) {
    super();
  }

  @Computed()
  public get pendingItemsCount(): number {
    return this.snapshot.items.length;
  }

  @Computed()
  public get pendingItems$(): PendingAdministrativeRuleModel[] {
    return this.snapshot.items;
  }

  @DataAction()
  public cleanFilter(): void {
    this.ctx.patchState({
      ...defaults,
    });
  }

  public getPendingAdministrativeRulesByCycle(
    ids: string
  ): Observable<StudentAdministrativeRuleModel[]> {
    return this.administrativeRulesService.getAdministrativeRulesByStudentCycle(
      ids
    );
  }

  public getPendingAdministrativeRules(): Observable<
    PendingAdministrativeRuleModel[]
  > {
    return this.administrativeRulesService.getPendingAdministrativeRules().pipe(
      tap((items) => {
        this.patchState({ items });
        this.alertsState.deleteMessage(ALERTS.ADMINISTRATIVE_RULES.PENDING.id);

        if (items.length > 0) {
          this.alertsState.pushMessage(ALERTS.ADMINISTRATIVE_RULES.PENDING);
        }
      })
    );
  }

  // public getAdministrativeRules(): Observable<StudentAdministrativeRuleModel[]> {

  // };
}
