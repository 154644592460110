import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ParameterConfigurationModel,
  ParameterTypeModel,
  SingleItemResponse,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ParameterCatalogService extends Api<ParameterTypeModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<ParameterTypeModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/parameter-catalog`;
  }

  public updateParameter(data: any): Observable<any> {
    const apiUrl = `${this.endpoint}/create`;
    return this.http.put(apiUrl, data);
  }

  public createRecurrentConfig(data: any): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/account/recurrent-charges`;
    return this.http.post(apiUrl, data);
  }
  public deleteRecurrentConfig(id: string): Observable<any> {
    const apiUrl = `${environment.apiUrl}/v1/account/recurrent-charges/${id}`;
    return this.http.delete(apiUrl);
  }

  public getPametersSettings(
    module_id: string,
    module_name: string,
    parameter_catalog_id: string
  ): Observable<ParameterConfigurationModel> {
    const endpoint = `${this.endpoint}/find${`?${new URLSearchParams({
      module_id,
      module_name,
      parameter_catalog_id,
    } as any).toString()}`}`;
    return this.http
      .get<SingleItemResponse<ParameterConfigurationModel>>(endpoint)
      .pipe(
        map((result) => {
          return result.data;
        })
      );
  }
}
