import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseEventsStatus } from '@core/models';
import { NotificationService } from '@core/services';
import { camelizeData } from '@core/utils/transform-data.util';
import { Observable, tap } from 'rxjs';

const responseEventsStatus: ResponseEventsStatus = {
  '200': {
    msg: 'body',
    alertType: 'success',
  },
  '201': {
    msg: 'Elemento creado con éxito.',
    alertType: 'success',
  },
  '401': {
    msg: 'Acceso no autorizado',
    alertType: 'error',
  },
  '206': {
    msg: 'error',
    alertType: 'error',
  },
};

@Injectable({
  providedIn: 'root',
})
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            const body = event.body;
            const { msg, alertType } = responseEventsStatus[`${event.status}`];
            event.body.data = camelizeData(event.body?.data);

            if (Object.hasOwn(body, 'show') && body.show === false) {
              return event;
            }
            this.showAlert(
              alertType,
              msg === 'body'
                ? event.body.message
                : msg === 'error'
                ? this.getMessage(event.body.error)
                : msg
            );
          }
          return event;
        },
        error: (error) => {
          new Promise((resolve) => {
            if (error.error instanceof Blob) {
              const fileReader = new FileReader();
              fileReader.onload = function () {
                resolve(JSON.parse(this.result as string));
              };
              fileReader.readAsText(error.error);
            } else {
              resolve(error.error);
            }
          }).then((errorResponse: any) => {
            this.showAlert(
              'error',
              error.status === 500
                ? 'Error no controlado, contactar a soporte'
                : Array.isArray(errorResponse.error) && error.status === 400
                ? this.getErrorFromArray(errorResponse.error)
                : this.getErrorMsg(errorResponse)
            );
          });
        },
      })
    );
  }

  private getErrorMsg(errorResponse: any): string {
    const { error } = errorResponse;
    return error &&
      Object.hasOwn(error, 'friendly_message') &&
      error['friendly_message'] !== null
      ? error['friendly_message']
      : error?.message || errorResponse?.message;
  }

  private showAlert(type: 'error' | 'success' | 'none', message: string) {
    if (!message) {
      return;
    }
    this.notificationService.displayAlert(message, type, true, 5000);
  }

  private getMessage(error: any): string {
    const props = Object.getOwnPropertyNames(error);
    let msg = '';
    props.forEach((prop) => {
      msg += ` ${error[prop].message}`;
    });
    return msg;
  }

  private getErrorFromArray(errors: any[]): string {
    let msg = '';
    errors.forEach((error: any) => {
      msg += ` ${error?.errors || ''} \n`;
    });
    return msg;
  }
}
