import {
  Computed,
  DataAction,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import {
  MultiItemStore,
  PaginationModel,
  PaymentFeeDetail,
  PaymentFeesModel,
} from '@core/models';
import { PaymentFeesService } from '@core/services/admin';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { formatMultiStoreData } from '@core/utils';
import { State } from '@ngxs/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable, finalize, map, tap } from 'rxjs';

export class PaymentFeeStateModel extends MultiItemStore<any> {}

const defaults = {
  ...MULTI_ITEM_STORE_DEFAULTS,
};

@Persistence()
@StateRepository()
@State<PaymentFeeStateModel>({
  name: 'paymentFeesReport',
  defaults,
})
@Injectable()
export class PaymentFeesState extends NgxsDataRepository<PaymentFeeStateModel> {
  constructor(private paymentFeesService: PaymentFeesService) {
    super();
  }

  @Computed()
  public get pageLimit(): number {
    return this.snapshot.limitPerPage;
  }

  @Computed()
  public get currentPage(): number {
    return this.snapshot.currentPage;
  }

  @Computed()
  public get isLoading(): Observable<boolean> {
    return this.state$.pipe(
      map((stateModel) => {
        return stateModel.isLoading;
      })
    );
  }

  @Computed()
  public get totalItems$(): Observable<number> {
    return this.state$.pipe(
      map((stateModel) => {
        return stateModel.totalItems;
      })
    );
  }

  @Computed()
  public get paymentsByPage$(): Observable<PaymentFeeDetail[]> {
    return this.state$.pipe(
      map((stateModel) => {
        const idsByPage = stateModel.pages[this.currentPage] || [];
        return idsByPage.map((id) => {
          return stateModel.items[id];
        });
      })
    );
  }

  @Computed()
  public get paymentsByPageGrid$(): Observable<GridDataResult | null> {
    return this.state$.pipe(
      map((stateModel) => {
        if (!stateModel.pages[this.currentPage]) {
          return null;
        }
        const idsByPage = stateModel.pages[this.currentPage] || [];
        const data = idsByPage.map((id) => {
          return stateModel.items[id];
        });
        return <GridDataResult>{
          data: data,
          total: stateModel.totalItems,
        };
      })
    );
  }

  @DataAction()
  public cleanState(): void {
    this.ctx.patchState(defaults);
  }

  public setLimit(total: number): void {
    this.patchState({
      limitPerPage: total,
    });
  }
  public toggleLoading(): void {
    this.patchState({
      isLoading: !this.snapshot.isLoading,
    });
  }

  public getPaymentsByPage(
    params: PaginationModel,
    force = false
  ): Observable<PaymentFeesModel> {
    const page = params.page || 1;
    this.patchState({
      currentPage: params.page,
    });

    this.patchState({
      isLoading: true,
    });
    return this.paymentFeesService
      .getAll({
        ...params,
        limit: this.pageLimit,
      })
      .pipe(
        finalize(() => {
          this.patchState({
            isLoading: false,
          });
        }),
        tap((content): void => {
          const currentState = this.getState();
          const { items: payments } = content.data;
          const currentPages = force ? {} : currentState.pages;
          const objectToPatch: Partial<PaymentFeeStateModel> = {
            totalItems: content.data.totalRecords,
            currentPage: page,
            items: {
              ...currentState.items,
              ...formatMultiStoreData(payments),
            },
            pages: {
              ...currentPages,
              [page]: payments.map((payment) => payment.id),
            },
            isLoading: false,
          };
          this.patchState(objectToPatch);
        }),
        map((content) => {
          return content.data;
        })
      );
  }
}
