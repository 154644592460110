import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { GuardianModel } from '@core/models';
import { AccountUsersService } from '@core/services';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { GenericState, GenericStateModel } from '../../generic.state';

@StateRepository()
@State<GenericStateModel<GuardianModel>>({
  name: 'accountUsers',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class AccountUserState extends GenericState<GuardianModel> {
  override service: AccountUsersService;

  constructor(private accountUserService: AccountUsersService) {
    super();
    this.service = accountUserService;
  }
}
