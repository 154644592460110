import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { FinancialEntriesModel } from '@core/models';
import { FinancialEntriesService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { State } from '@ngxs/store';
import { Observable, map } from 'rxjs';
import { MULTI_ITEM_STORE_FINANCIAL_ENTRIE_DEFAULTS } from './finantial-entries-initial.state';

@StateRepository()
@State<GenericStateModel<FinancialEntriesModel>>({
  name: 'FinancialEntries',
  defaults: {
    ...MULTI_ITEM_STORE_FINANCIAL_ENTRIE_DEFAULTS,
  },
})
@Injectable()
export class FinancialEntriesState extends GenericState<FinancialEntriesModel> {
  override service: FinancialEntriesService;
  constructor(private financialEntriesService: FinancialEntriesService) {
    super();
    this.service = this.financialEntriesService;
  }
  @Computed()
  public get datesSaved(): {
    startDate?: string;
    endDate?: string;
  } {
    return {
      startDate: this.snapshot.startDate,
      endDate: this.snapshot.endDate,
    };
  }
  public saveDates(startDate: string, endDate: string): void {
    this.patchState({
      startDate,
      endDate,
    });
  }
  public getFinancialEntriesById(
    id: string
  ): Observable<FinancialEntriesModel> {
    return this.state$.pipe(
      map((FinancialEntriesState) => {
        return FinancialEntriesState.items[id];
      })
    );
  }
}
