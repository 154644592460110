<app-header class="print-border"></app-header>
<kendo-drawer-container>
  <kendo-drawer
    [autoCollapse]="false"
    [expanded]="layoutState.isSidenavOpen"
    [isItemExpanded]="isItemExpanded"
    [items]="menuItems"
    mode="push"
    (select)="onMenuItemSelect($event)"
    class="non-printable"
  >
  </kendo-drawer>
  <ng-container *ngIf="isSidenavOpen$ | async">
    <div class="drawer-user non-printable">
      <div class="avatar">{{ avatarLetters$ | async }}</div>
      <div class="words">
        <div class="name">{{ userName$ | async }}</div>
        <div class="role">{{ (user$ | async)?.role }}</div>
        <div class="role">{{ versionApp }}</div>
      </div>
    </div></ng-container
  >
  <kendo-drawer-content
    [ngClass]="{ 'no-breadcrumbs': isUnathorized$ | async }"
  >
    <app-breadcrumbs
      [content]="content"
      class="non-printable"
    ></app-breadcrumbs>

    <div class="row" *ngIf="alertsState.shouldDisplay">
      <div class="col-12">
        <ul class="help-card">
          <li
            class="ms-3"
            *ngFor="let message of alertsState.messages$ | async"
          >
            {{ message.message }}
          </li>
        </ul>
      </div>
    </div>

    <div #content class="section-content">
      <router-outlet></router-outlet>
    </div>
  </kendo-drawer-content>
</kendo-drawer-container>

<kendo-dialog
  *ngIf="(showVersionDialog$ | async) === true"
  [minWidth]="250"
  [width]="'35%'"
  [height]="'auto'"
  (close)="reloadVersion()"
>
  <div class="row">
    <div class="col-12 mt-4 text-center">
      <img
        class="hub non-printable"
        src="assets/images/common/hub.svg"
        width="110"
        height="70"
        alt="HUB+"
      />
    </div>

    <div class="col-12 mt-2 text-center">
      <h5>Nueva versión disponible</h5>
    </div>

    <div class="col-12 mt-2 text-center">
      <p>
        Seguimos mejorando para ti, haz clic en actualizar ahora para obtener la
        última versión de la aplicación.
      </p>
    </div>

    <div class="col-12 my-4 text-center">
      <button
        class="mt-1"
        kendoButton
        themeColor="primary"
        (click)="reloadVersion()"
      >
        ACTUALIZAR AHORA
      </button>
    </div>
  </div>
</kendo-dialog>
