import { StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { StudentCycleModel } from '@core/models';
import { StudentCyclesService } from '@core/services/admin';
import { NgxsOnInit, State } from '@ngxs/store';
import { map } from 'rxjs';

export class StudentCyclesStateModel {
  public loading: { [id_student: string]: boolean };
  public studentsCycles!: {
    [id_student: string]: StudentCycleModel[] | undefined;
  };
}

@StateRepository()
@State<StudentCyclesStateModel>({
  name: 'studentsCycles',
  defaults: { loading: {}, studentsCycles: {} },
})
@Injectable()
export class StudentCyclesState
  extends NgxsDataRepository<StudentCyclesStateModel>
  implements NgxsOnInit
{
  constructor(private studentCyclesService: StudentCyclesService) {
    super();
  }

  public getStudentCycles$(id: string) {
    if (!this.snapshot.studentsCycles[id]) {
      this.patchState({
        loading: { ...this.snapshot.loading, [id]: true },
      });
      this.studentCyclesService.getAll({ student_id: id }).subscribe({
        next: (studentCyclesResponse) => {
          if (studentCyclesResponse.data.items.length > 0) {
            this.patchState({
              loading: { ...this.snapshot.loading, [id]: false },
              studentsCycles: {
                ...this.snapshot.studentsCycles,
                [id]: studentCyclesResponse.data.items[0].studentCycle,
              },
            });
          } else {
            this.patchState({
              loading: { ...this.snapshot.loading, [id]: false },
            });
          }
        },
        error: () => {
          this.patchState({
            loading: { ...this.snapshot.loading, [id]: false },
          });
        },
      });
    }
    return this.state$.pipe(
      map((studentCycleState) => studentCycleState.studentsCycles[id])
    );
  }

  public isLoadingStudentCycles$(id: string) {
    return this.state$.pipe(
      map((studentCycleState) => studentCycleState.loading[id])
    );
  }
}
