import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dynamic-field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicFieldComponent),
      multi: true,
    },
  ],
})
export class DynamicFieldComponent implements ControlValueAccessor {
  @Input() type = 'string';
  public onChange?: (value: string | boolean) => void;
  public onTouched?: () => void;
  public disabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public value: string | boolean;

  public onValueChange(value: string | boolean): void {
    if (!this.onChange) {
      return;
    }
    this.onChange(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled.next(isDisabled);
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public registerOnChange(fn: (value: string | boolean) => void): void {
    this.onChange = fn;
  }

  public writeValue(value: string | boolean): void {
    this.value = value;
  }
}
