import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  CrmLogDetailModel,
  CrmLogModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CrmLogsService extends Api<CrmLogModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<CrmLogModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/security/crm`;
  }

  public getDetail(
    id: string
  ): Observable<CollectionResponse<CrmLogDetailModel>> {
    return this.http.get<CollectionResponse<CrmLogDetailModel>>(
      `${this.endpoint}/${id}`
    );
  }
}
