import { ExtendedBreadCrumb } from '@core/models';
import { LayoutState } from '@core/state/shared';
import { clone } from 'lodash';
import { take } from 'rxjs';

export function updateBreadcrumbs(
  layoutState: LayoutState,
  searchTextRoute: string,
  newUrl: string
): void {
  layoutState.breadcrumbs$
    .pipe(take(1))
    .subscribe((response: ExtendedBreadCrumb[]) => {
      const breadcrumbs = clone(response);
      const currentIndex = breadcrumbs.findIndex(
        (b) => b.text === searchTextRoute
      );
      if (currentIndex < 0) {
        return;
      }
      breadcrumbs[currentIndex] = {
        ...breadcrumbs[currentIndex],
        url: newUrl,
      };
      layoutState.setBreadcrumbs(breadcrumbs);
    });
}
