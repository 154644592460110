import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { FinancialEntrySummaryModel } from '@core/models';
import { FinancialEntrySummaryService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { Observable } from 'rxjs';

@StateRepository()
@State<GenericStateModel<FinancialEntrySummaryModel>>({
  name: 'financialEntrySummary',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class FinancialEntrySummaryState extends GenericState<FinancialEntrySummaryModel> {
  override service: FinancialEntrySummaryService;
  constructor(
    private financialEntrySummaryService: FinancialEntrySummaryService
  ) {
    super();
    this.service = this.financialEntrySummaryService;
  }
  public getFinancialEntrySummary(
    startDate?: string,
    endDate?: string
  ): Observable<FinancialEntrySummaryModel> {
    return this.getFinancialEntrySummary(startDate, endDate);
  }
}
