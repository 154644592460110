import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { ExternalInvoiceModel } from '@core/models';
import { ExternalInvoicesService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<ExternalInvoiceModel>>({
  name: 'externalInvoices',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class ExternalInvoicesState extends GenericState<ExternalInvoiceModel> {
  override service: ExternalInvoicesService;
  constructor(service: ExternalInvoicesService) {
    super();
    this.service = service;
  }
}
