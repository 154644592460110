import { camelCase, snakeCase } from 'lodash';

export function camelizeData(obj: any): any {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((currentObj) => {
      return camelizeData(currentObj);
    });
  }
  const result: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelCaseKey = camelCase(key);
      result[camelCaseKey] = camelizeData(obj[key]);
    }
  }
  return result;
}

export function snakeData(obj: any): any {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((currentObj) => {
      return snakeData(currentObj);
    });
  }
  const result: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeCaseKey = snakeCase(key);
      result[snakeCaseKey] = snakeData(obj[key]);
    }
  }
  return result;
}
