import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { find } from 'lodash';
import { Observable, delay, of } from 'rxjs';
import { fakeApisConstant } from './constants';
import { HttpMethodsEnum } from './enums/fake-apis.enum';
import { FakeApisModel } from './models';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const { url, method } = request;
    let urlObject: URL;
    try {
      urlObject = new URL(url);
    } catch {
      const baseUrl = window.location.origin;
      urlObject = new URL(url, baseUrl);
    }
    const fakeEndpointEnabled = this.isFakeEnabledForEndpoint(
      urlObject.pathname,
      HttpMethodsEnum[method as keyof typeof HttpMethodsEnum]
    );
    if (fakeEndpointEnabled) {
      return of(
        new HttpResponse({
          status: 200,
          url,
          body: (fakeEndpointEnabled as FakeApisModel).data[
            HttpMethodsEnum[method as keyof typeof HttpMethodsEnum]
          ],
        })
      ).pipe(delay(500));
    }
    return next.handle(request);
  }

  private isFakeEnabledForEndpoint(
    endpoint: string,
    httpMethod: HttpMethodsEnum
  ): FakeApisModel | boolean {
    const currentEndpointOb = find(fakeApisConstant, (ol) => {
      ol.regex.lastIndex = 0;
      return ol.regex.exec(endpoint);
    });
    const found = (currentEndpointOb || {}) as FakeApisModel;
    return found.enabled && found.methods.includes(httpMethod) ? found : false;
  }
}
