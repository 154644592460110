<kendo-contextmenu
  [alignToAnchor]="true"
  [target]="target"
  showOn="click"
  (select)="action($event)"
>
  <kendo-menu-item text="Centro de soporte">
    <ng-template kendoMenuItemTemplate let-item="item">
      <div>
        <i class="ph ph-book-open"></i>
        {{ item.text }}
      </div>
    </ng-template>
  </kendo-menu-item>
  <kendo-menu-item text="Chat de soporte">
    <ng-template kendoMenuItemTemplate let-item="item">
      <div>
        <i class="ph ph-chat-circle-dots"></i>
        {{ item.text }}
      </div>
    </ng-template>
  </kendo-menu-item>
</kendo-contextmenu>
