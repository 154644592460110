import { Injectable } from '@angular/core';
import { StudentCyclesResponseModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class StudentCyclesService extends Api<StudentCyclesResponseModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<StudentCyclesResponseModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/students/studentCycles`;
  }
}
