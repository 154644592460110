import { ApiModel } from './generic-state.model';

export interface PaymentsCardRespondeModel {
  id: string;
  guardianId: string;
  paymentTypeId: 0;
  paymentMethodId: string;
  transactionId: string;
  paymentDate: string;
  totalPaid: string;
  discountApplied: boolean;
  reference: string;
  description: string;
  status: string;
}

export interface PaymentCardModel {
  id?: string;
  payment_type_id?: number;
  payment_method_id?: string;
  total_paid?: number;
  discount_applied?: boolean;
  is_discount_applied?: boolean;
  description?: string;
  collection_notice_id: string | string[];
  deferred: boolean;
  months?: number;
  cvv?: string;
  store_code?: string;
  email?: string;
  name?: string;
  last_name?: string;
}

export interface AbandonedPaymentModel extends ApiModel {
  tpvSchoolId: string;
  school: string;
  paymentDate: string;
  externalCompletedAt: string;
  reference: string;
  tpvAuthorization: string;
  transactionId: string;
  status: string;
  totalPaid: number;
  externalTransferProcessor: string;
  businessName: string;
  collectionNoticeId: string;
  tpvTransactionId: string;
  rejectedReason: string;
  tpvSchoolName: string;
  currency: string;
}

export enum FixPaymentsReasons {
  TPV_ERROR = 'tpv_error',
  GROUPED_PAYMENT = 'grouped_payment',
  TPV_CANCELLATION = 'TPV Cancel',
  EXTERNAL_PAYMENT = 'External',
  COLLECTION_ADJUST = 'collection_adjust',
}

export interface FixAbandonedPaymentModel {
  status?: string;
  date?: string;
  rejected_reason?: string;
  tpv_school_id?: string;
  text?: string;
  collectionNoticeId?: string;
}

export interface IdempotencyKeyModel {
  idempotencyKey: string;
}
