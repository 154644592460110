import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PaymentHistoryModel,
  SingleItemResponse,
  VoucherModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VoucherService extends Api<VoucherModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<VoucherModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/payments/voucher`;
  }

  public uploadVoucher(
    voucher: VoucherModel,
    paymentId: string
  ): Observable<SingleItemResponse<PaymentHistoryModel>> {
    return this.http.post<SingleItemResponse<PaymentHistoryModel>>(
      `${this.endpoint}/${paymentId}`,
      voucher
    );
  }
}
