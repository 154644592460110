import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  InvoiceModel,
  SingleItemResponse,
  StampingParamsModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { pickBy } from 'lodash';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService extends Api<InvoiceModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<InvoiceModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/account/invoicing/invoices`;
  }

  public stamp(
    paymentId: string,
    rfcId: string,
    cfdiUse: string
  ): Observable<InvoiceModel> {
    const endpoint = `${environment.apiUrl}/v1/account/invoicing/payments/${paymentId}/stamp`;
    const params = JSON.stringify({ rfc_id: rfcId, cfdi_use: cfdiUse });
    return this.apiService.post(endpoint, params).pipe(
      map((data: CollectionResponse<InvoiceModel>) => {
        if (Array.isArray(data.data)) {
          return data.data[0];
        }
        return data.data;
      })
    );
  }

  public stampCollectionNotice(
    paymentId: string,
    rfcId: string,
    stampDate: string | null,
    cfdiUse?: string,
    cardType?: string
  ): Observable<InvoiceModel> {
    const endpoint = `${environment.apiUrl}/v1/account/invoicing/collection_notices/${paymentId}/stamp`;
    let params: StampingParamsModel = {
      rfc_id: rfcId,
      cfdi_use: cfdiUse,
      stamp_date: stampDate,
      card_type: cardType,
    };
    params = pickBy(params, (value) => value !== null) as StampingParamsModel;
    return this.apiService.post(endpoint, JSON.stringify(params)).pipe(
      map((data: CollectionResponse<InvoiceModel>) => {
        if (Array.isArray(data.data)) {
          return data.data[0];
        }
        return data.data;
      })
    );
  }

  public cancel(invoiceId: string): Observable<InvoiceModel> {
    const endpoint = `${environment.apiUrl}/v1/account/invoicing/invoices/${invoiceId}/cancel`;
    return this.apiService.put(endpoint).pipe(
      map((data: SingleItemResponse<InvoiceModel>) => {
        return data.data;
      })
    );
  }

  public exportPDF(id: string): Observable<Blob> {
    const endpoint = `${this.endpoint}/${id}/pdf`;
    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public retryStamp(
    collectionNoticeId: string,
    rfc: Partial<InvoiceModel>
  ): Observable<SingleItemResponse<InvoiceModel>> {
    const endpoint = `${environment.apiUrl}/v1/account/invoicing/collection_notices/${collectionNoticeId}/stamp`;
    return this.apiService.put(endpoint, JSON.stringify(rfc));
  }
}
