import { Injectable } from '@angular/core';
import { CurrencyModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService extends Api<CurrencyModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<CurrencyModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/currency`;
  }
}
