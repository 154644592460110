import { CardProcessorsEnum } from '@core/models';

export function getLogoProcessorCard(processorName?: string): string {
  switch (processorName?.toLocaleLowerCase()) {
    case CardProcessorsEnum.MASTER_CARD:
      return 'logo-master';
    case CardProcessorsEnum.AMEX:
      return 'logo-amex';
    case CardProcessorsEnum.VISA:
      return 'logo-visa';
    default:
      return 'logo-master';
  }
}
