export interface AccountingDateModel {
  name: string;
  key: AccountingDateEnum;
  value?: string;
}

export enum AccountingDateEnum {
  END_MONTH = 'end_month',
  START_MONTH = 'start_month',
  SPECIFIC_DATE = 'specific_date',
  ON_EMIT = 'on_emit_notice',
  EXACT_DATE = 'exact_date',
}

export enum AccoutingDateTypesEnum {
  SPECIFIC_DATE = 'Día en especifico',
  END_MONTH = 'Fin de mes',
  START_MONTH = 'Inicio de mes',
  ON_EMIT = 'Emisión de aviso',
  EXACT_DATE = 'Día en especifico',
}

export interface DeadlineAtModel {
  [key: string]: any;
  deadline_at_type: string;
  deadline_at: string;
}
