import { AutomaticCollectionNoticesGenerationReportState } from './automatic-collection-notices/automatic-collection-notices.state';
import { BalancePaymentNoticeState } from './balance-payment-notice/balance-payment-notice.state';
import { ChargeByCategoryReportState } from './charge-by-category/charge-by-category.state';
import { CollectionNoticesGenerationReportState } from './collection-notices-generation/collection-notices-generation.state';
import { DailyPaymentsReportState } from './daily-payments/daily-payments.state';
import { DynamicReportsAdminState } from './dynamic-reports-admin/dynamic-reports-admin.state';
import { GeneralIncomeConcentrationReportState } from './general-income-concentration/general-income-concentration.state';
import { InRiCollectionNoticesReportState } from './in-ri-collection-notices/in-ri-collection-notices.state';
import { IncomeConcentrationReportState } from './income-concentration/income-concentration.state';
import { PastDueReportState } from './past-due-notice/past-due-notice.state';
import { PaymentOrdersReportState } from './payment-orders/payment-orders.state';
import { ScholarshipsReportState } from './scholarships-reports/scholarships-report.state';

import { TpvPaymentsReportState } from './tpv-payments/tpv-payments.state';
export {
  AutomaticCollectionNoticesGenerationReportState,
  BalancePaymentNoticeState,
  ChargeByCategoryReportState,
  CollectionNoticesGenerationReportState,
  DailyPaymentsReportState,
  DynamicReportsAdminState,
  GeneralIncomeConcentrationReportState,
  IncomeConcentrationReportState,
  InRiCollectionNoticesReportState,
  PastDueReportState,
  PaymentOrdersReportState,
  ScholarshipsReportState,
  TpvPaymentsReportState,
};

export const ReportsStates = [
  CollectionNoticesGenerationReportState,
  BalancePaymentNoticeState,
  AutomaticCollectionNoticesGenerationReportState,
  InRiCollectionNoticesReportState,
  PaymentOrdersReportState,
  ScholarshipsReportState,
  PastDueReportState,
  IncomeConcentrationReportState,
  GeneralIncomeConcentrationReportState,
  TpvPaymentsReportState,
  ChargeByCategoryReportState,
  DailyPaymentsReportState,
  DynamicReportsAdminState,
];
