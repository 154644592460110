import { Injectable } from '@angular/core';
import {
  ADMIN_UNLOGGED_DEFAULT_ROUTE,
  DEFAULT_LANGUAGE,
  END_POINTS,
  USER_UNLOGGED_DEFAULT_ROUTE,
} from '@core/constants';
import {
  ChangePassword,
  CurrentUserModel,
  FeatureFlagAccessModel,
  FeatureFlagCodes,
  Login,
  SessionModel,
  StudentModel,
  UserModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { CatalogsService } from '@core/services/catalogs/catalogs.service';
import { PersistedStates } from '@core/state';
import {
  CurrentUserState,
  FilterState,
  LayoutState,
  SessionState,
} from '@core/state/shared';
import { environment } from '@env/environment';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { StateReset, StateResetAll } from 'ngxs-reset-plugin';
import {
  catchError,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { FeatureFlagsAccessService } from '../account';
import { AuthorizationService } from '../authorization/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends Api<SessionModel> {
  override endpoint: string;
  constructor(
    apiService: ApiService<SessionModel>,
    private authorizationService: AuthorizationService,
    private catalogsService: CatalogsService,
    private currentUserState: CurrentUserState,
    private sessionState: SessionState,
    private store: Store,
    private filterState: FilterState,
    private layoutState: LayoutState,
    private featureFlagsAccessService: FeatureFlagsAccessService
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/auth`;
  }

  public changePassword(
    credentials: ChangePassword,
    isAdmin = false
  ): Observable<SessionModel> {
    this.endpoint = `${environment.apiUrl}${
      isAdmin
        ? END_POINTS.ADMIN.CHANGE_PASSWORD
        : END_POINTS.USER.CHANGE_PASSWORD
    }`;
    return this.post(credentials);
  }

  public isLoggedIn(): boolean {
    return !!this.sessionState.snapshot.props.accessToken;
  }

  public login(
    credentials: Login,
    isAdmin = false
  ): Observable<CurrentUserModel> {
    this.endpoint = `${environment.apiUrl}${
      isAdmin ? END_POINTS.ADMIN.LOGIN : END_POINTS.USER.LOGIN
    }`;

    return this.post(credentials).pipe(
      tap((session: SessionModel) => {
        this.saveToken({
          accessToken: session.token,
          email: credentials.email,
          isAdmin,
          zendesk: session.zendesk,
          user: session.user,
          lang: session.lang,
          idPymntProvider: session.idPymntProvider,
          country: session.country,
          schoolAccess: isAdmin
            ? this.getSchoolsAccess(session.user || ({} as UserModel))
            : '',
        });
        if (isAdmin) {
          this.authorizationService.loadPermissions(
            session.user?.role?.id as string
          );
        }
        this.initStore();
      }),
      switchMap(() => {
        return this.currentUserState.getCurrentUser();
      }),
      switchMap((currentUser) => {
        if (isAdmin) {
          return of(currentUser);
        }
        const schoolIds = `${currentUser.students
          .map(
            (student: StudentModel) =>
              student.school?.id || student.schoolNextCycle?.id
          )
          .filter((school) => school !== undefined)}`;
        const featureFlagObservers = this.configFeatureFlags(schoolIds);
        return forkJoin(featureFlagObservers).pipe(
          catchError(() => {
            return of(undefined);
          }),
          take(1),
          map((features) => {
            if (!features) {
              return currentUser;
            }
            this.currentUserState.saveFeatureFlags(features.eventFlag);
            this.currentUserState.saveFeatureFlags(features.gropFieldFlag);
            return currentUser;
          })
        );
      })
    );
  }

  public getSchoolsAccess(user: UserModel): string | undefined {
    return `${user.access.rules
      .filter((rule) => rule.modelType === 'school')
      .map((rule) => rule.modelId)}`;
  }

  public logout() {
    const isAdmin = this.sessionState.isAdmin;
    this.store.dispatch(new StateReset(...PersistedStates));
    this.store.dispatch(new StateResetAll());
    this.filterState.cleanFilter();
    this.currentUserState.reset();
    this.store.dispatch(
      new Navigate([
        isAdmin ? ADMIN_UNLOGGED_DEFAULT_ROUTE : USER_UNLOGGED_DEFAULT_ROUTE,
      ])
    );
    this.sessionState.removeSession();
  }

  private initStore(): void {
    this.catalogsService.init();
  }

  private saveToken(session: SessionModel): void {
    this.sessionState.addSession(session);
    this.layoutState.setLanguage(session.lang || DEFAULT_LANGUAGE);
  }

  private fetchFlag(
    schoolIds: string,
    flag: FeatureFlagCodes
  ): Observable<FeatureFlagAccessModel> {
    return this.featureFlagsAccessService.checkStatus(flag, schoolIds);
  }

  private configFeatureFlags(schoolIds: string) {
    return {
      gropFieldFlag: this.fetchFlag(
        schoolIds,
        FeatureFlagCodes.SHOW_GROUP_FIELD
      ),
      eventFlag: this.fetchFlag(
        schoolIds,
        FeatureFlagCodes.FRIENDSHIP_TOURNAMENT
      ),
    };
  }
}
