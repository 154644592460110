import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { DiscountCouponsModel } from '@core/models';
import { DiscountCouponsService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { Observable, finalize, switchMap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<DiscountCouponsModel>>({
  name: 'discountCoupons',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class DiscountCouponsState extends GenericState<DiscountCouponsModel> {
  override service: DiscountCouponsService;

  constructor(private discountsCouponsService: DiscountCouponsService) {
    super();
    this.service = discountsCouponsService;
  }
  public clearTable() {
    this.patchState({ pages: {} });
  }

  public createDiscountCoupon(
    data: DiscountCouponsModel
  ): Observable<DiscountCouponsModel[]> {
    this.toggleLoading();
    return this.discountsCouponsService.post(data).pipe(
      finalize(() => {
        this.patchState({
          isLoading: false,
        });
      }),
      switchMap((): Observable<DiscountCouponsModel[]> => {
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        return this.getAllByPage({ page: lastPage }, true);
      })
    );
  }

  public deleteDiscountCoupon(id: string): Observable<DiscountCouponsModel[]> {
    return this.discountsCouponsService.delete(id).pipe(
      finalize(() => {
        this.toggleLoading();
      }),
      switchMap((): Observable<DiscountCouponsModel[]> => {
        return this.getAllByPage({ page: this.currentPage }, true);
      })
    );
  }
  public updateDiscountCoupon(
    data: DiscountCouponsModel
  ): Observable<DiscountCouponsModel[]> {
    this.toggleLoading();
    return this.discountsCouponsService.update(data, data.id).pipe(
      finalize(() => {
        this.patchState({
          isLoading: false,
        });
      }),
      switchMap((): Observable<DiscountCouponsModel[]> => {
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        return this.getAllByPage({ page: lastPage }, true);
      })
    );
  }
}
