import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ExtendedBreadCrumb } from '@core/models';
import { LayoutState } from '@core/state/shared';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements AfterViewInit {
  @ViewChild('breadcrumbsDetail', {
    read: ElementRef,
    static: false,
  })
  public breadcrumbsDetail: ElementRef;

  @Input()
  public content: HTMLDivElement;

  constructor(public layoutState: LayoutState, private store: Store) {}

  public ngAfterViewInit(): void {
    const observer = new MutationObserver(() => {
      this.initBreadcrumbDetail();
    });
    observer.observe(this.content, {
      childList: true,
    });
    this.initBreadcrumbDetail();
  }

  public onItemClick(item: ExtendedBreadCrumb): void {
    this.store.dispatch(new Navigate([item.url]));
  }

  private initBreadcrumbDetail(): void {
    this.breadcrumbsDetail.nativeElement.innerHTML = '';
    const elements = this.content.querySelectorAll('.breadcrumbs-right-side');
    if (elements.length > 0) {
      this.breadcrumbsDetail.nativeElement.append(elements[0]);
    }
  }
}
