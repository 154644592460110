import {
  AdministrativeRulesState,
  AuthorizationState,
  CatalogsState,
  CurrentUserState,
  FilterState,
  FiscalRegimesState,
  LayoutState,
  PageableState,
  SessionState,
  VersionState,
} from './';
import { AlertsState } from './global-alert/global-alert.state';

export * from './administrative-rules/administrative-rules.state';
export * from './authorization/authorization.state';
export * from './catalogs/catalogs.state';
export * from './current-user/current-user.state';
export * from './filter/filter.state';
export * from './fiscal-regime/fiscal-regime.state';
export * from './global-alert/global-alert.state';
export * from './layout/layout.state';
export * from './pageable/pageable.state';
export * from './session/session.state';
export * from './version/version.state';

export const SharedStates = [
  AdministrativeRulesState,
  AlertsState,
  AuthorizationState,
  CatalogsState,
  CurrentUserState,
  FiscalRegimesState,
  LayoutState,
  SessionState,
  FilterState,
  PageableState,
  VersionState,
];

export const SharedPersistedStates = [
  AuthorizationState,
  AlertsState,
  CatalogsState,
  CurrentUserState,
  LayoutState,
  SessionState,
  FilterState,
  PageableState,
  VersionState,
];
