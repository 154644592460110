import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbandonedPaymentModel, FixAbandonedPaymentModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AbandonedPaymentsService extends Api<AbandonedPaymentModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<AbandonedPaymentModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/payments/tpv/abandoned`;
  }

  public fixPayment(
    data: FixAbandonedPaymentModel,
    paymentId: string
  ): Observable<FixAbandonedPaymentModel> {
    const endpoint = `${environment.apiUrl}/v1/admin/payments/payment-management/${paymentId}`;
    return this.http.put(endpoint, data);
  }

  public fixTpvCancelPayment(
    paymentId: string
  ): Observable<FixAbandonedPaymentModel> {
    const endpoint = `${environment.apiUrl}/v1/admin/payments/tpv/cancel/${paymentId}`;
    return this.http.put(endpoint, null);
  }

  public fixTPVSchoolPayment(
    data: FixAbandonedPaymentModel,
    paymentId: string
  ): Observable<FixAbandonedPaymentModel> {
    const endpoint = `${environment.apiUrl}/v1/admin/payments/tpv/${paymentId}/school`;
    return this.http.patch(endpoint, data);
  }

  public fixTotalAmount(
    data: Partial<FixAbandonedPaymentModel>
  ): Observable<FixAbandonedPaymentModel> {
    const apiUrl = `${environment.apiUrl}/v1/admin/collections/collection-notices/updateFinalAmount/${data.collectionNoticeId}`;
    return this.http.put(apiUrl, data);
  }
}
