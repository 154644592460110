import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import {
  ContextMenuSelectEvent,
  ContextMenuTargetContainerDirective,
} from '@progress/kendo-angular-menu';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-support-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class SupportMenuComponent {
  @Input()
  public link: string;

  @Input()
  public newTab = true;

  @Input()
  public target:
    | string
    | ContextMenuTargetContainerDirective
    | ElementRef<any>
    | HTMLElement;

  public action(event: ContextMenuSelectEvent) {
    if (event.index === '0') {
      window.open(this.link, this.newTab ? '_blank' : '_self');
    }
    if (event.index === '1') {
      (window as any).zE('messenger', 'open');
    }
  }
}
