import { Injectable } from '@angular/core';
import { SectionModel } from '@core/models';
import { environment } from '@env/environment';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class SectionsService extends Api<SectionModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<SectionModel>
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/sections`;
  }
}
