import { ADMIN_FRONTEND_PERMISSIONS } from '@core/constants';
import { getListPermission } from '@core/functions';
import { environment } from '@env/environment';
import { SwitcherOptionModel } from '@widgets/switcher-menu/models/switcher-menu-option-model';

export const SWITCHER_OPTIONS: SwitcherOptionModel[] = [
  {
    key: ADMIN_FRONTEND_PERMISSIONS.switcher.hubAdmin,
    title: 'Hub+ Admin',
    icon: 'ph ph-buildings',
    isOpenNewTab: true,
    link: '/admin',
    isLink: true,
    permission: getListPermission(ADMIN_FRONTEND_PERMISSIONS.switcher.hubAdmin),
  },
  {
    key: ADMIN_FRONTEND_PERMISSIONS.switcher.hubReports,
    title: 'Hub+ Reporteador',
    icon: 'ph ph-chart-pie',
    isOpenNewTab: true,
    link: environment.uriHubPlusReporter,
    isLink: true,
    permission: getListPermission(
      ADMIN_FRONTEND_PERMISSIONS.switcher.hubReports
    ),
  },
  {
    key: ADMIN_FRONTEND_PERMISSIONS.switcher.hubPayments,
    title: 'Hub+ Padres',
    icon: 'ph ph-users',
    isOpenNewTab: true,
    link: '/',
    isLink: true,
    permission: getListPermission(
      ADMIN_FRONTEND_PERMISSIONS.switcher.hubPayments
    ),
  },
];

export const ENABLED_SWITCHER_APPLICATION =
  environment.enabledSwitcherApplication ?? false;
