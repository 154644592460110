import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { ConceptModel } from '@core/models/concept.model';
import { ConceptService } from '@core/services';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { values } from 'lodash';
import { Observable, map } from 'rxjs';

@StateRepository()
@State<GenericStateModel<ConceptModel>>({
  name: 'concepts',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class ConceptState extends GenericState<ConceptModel> {
  override service: ConceptService;
  constructor(private conceptService: ConceptService) {
    super();
    this.service = conceptService;
  }

  @Computed()
  public get assignmentItems$(): Observable<ConceptModel[]> {
    return this.state$.pipe(
      map((stateModel) => {
        return values(stateModel.items).filter(
          (item) => !item.name.toLowerCase().includes('saldo')
        );
      })
    );
  }

  @Computed()
  public get collectionNoticeConcepts$(): Observable<ConceptModel[]> {
    return this.state$.pipe(
      map((stateModel) => {
        return values(stateModel.items).filter(
          (item) =>
            ![
              'inscripcion',
              'Inscripcion',
              'inscripción',
              'Inscripción',
              'reinscripcion',
              'Reinscripcion',
              'reinscripción',
              'Reinscripción',
              'descuento',
              'Descuento',
              'colegiatura',
              'Colegiatura',
              'saldo',
              'Saldo',
            ].some((string) => item.name.includes(string))
        );
      })
    );
  }
}
