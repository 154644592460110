import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConceptCategoryModel,
  ConceptManagmentModel,
  PaginationModel,
  SingleItemResponse,
} from '@core/models';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ConceptsManagmentService extends Api<ConceptManagmentModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<ConceptManagmentModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/concepts/sub-concepts`;
  }
  public updateConceptManagement(
    data: ConceptManagmentModel
  ): Observable<SingleItemResponse<ConceptManagmentModel>> {
    return this.apiService.put(
      `${this.endpoint}/${data.id}`,
      JSON.stringify(data)
    );
  }
  public getConceptManagementById(
    id: string
  ): Observable<SingleItemResponse<ConceptManagmentModel>> {
    return this.apiService.get(`${this.endpoint}/${id}`);
  }
  public exportConcepts(params: PaginationModel): Observable<Blob> {
    params.limit = 1000000;
    params.page = 1;
    const endpoint = `${this.endpoint}/export/excel${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;
    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }
  public getAllConceptCategories(): Observable<ConceptCategoryModel[]> {
    return this.http
      .get<{ data: { items: ConceptCategoryModel[] } }>(
        `${environment.apiUrl}/v1/admin/concepts/categories?limit=200`
      )
      .pipe(map((response) => response.data.items));
  }
}
