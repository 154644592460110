import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthorizationService } from '@core/services';
import { SessionState } from '@core/state/shared';

@Directive({
  selector: '[appHasPermission]',
})
export class HasPermissionActionDirective implements OnInit {
  @Input('appHasPermission')
  public permission: string;

  constructor(
    private authService: AuthorizationService,
    private sessionState: SessionState,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  public ngOnInit() {
    const allowAction = this.sessionState.isAdmin
      ? this.authService.hasPermission(this.permission)
      : false;

    if (allowAction) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
