import { CurrencyPipe } from '@angular/common';
import { CollectionNoticeModel } from '../collection-notice.model';
import { ComboboxModel } from '../combobox-data.model';
import { InvoicingDataModel } from '../invoicing-data.model';

export interface PartialPaymentRefundModel {
  collection_notice_id: string;
  reference: string;
  refund_amount?: number;
  refund_date?: string;
  refund_reason_id?: string;
  refund_reason: string;
  accessories: AllowedAccessoriesToRefundModel[];
}

export interface PartialPaymentRefundPayloadModel {
  payment_id: string;
  collection_notice_refunds: PartialPaymentRefundModel[];
}

export interface PartialRefundReasonModel {
  allowedAccessoriesToRefund: AllowedAccessoriesToRefundModel[];
  allowedPartialAmountToRefund:
    | AllowedPartialAmountToRefund[]
    | AllowedPartialAmountToRefund;
  collectionNotice: CollectionNoticeModel;
  reasonRefund: ComboboxModel;
}

export interface AllowedAccessoriesToRefundModel {
  id: string;
  amount: number;
  is_selected: boolean;
}

export interface RefundReasonsModel extends ComboboxModel {
  name: string;
}

export interface AllowedPartialAmountToRefund {
  percentage: number;
  amount: number;
  value: string;
}

export interface AllowedAmountsCollectionNoticeModel {
  [collectionId: string]: AllowedPartialAmountToRefund[];
}

export interface RFCCollectionNoticeModel {
  [collectionId: string]: InvoicingDataModel[];
}

export class AllowedPartialAmountToRefundModel
  implements AllowedPartialAmountToRefund
{
  percentage: number;
  amount: number;
  value: string;

  constructor(item: AllowedPartialAmountToRefund, currencyPipe: CurrencyPipe) {
    this.percentage = item.percentage;
    this.amount = item.amount;
    this.value = `${currencyPipe.transform(item.amount, 'MXN')} - (${
      item.percentage
    }%)`;
  }
}
