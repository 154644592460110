<h6>{{ inputLabel }}</h6>
<div class="mt-3" [ngClass]="{ 'ng-invalid': touched && !base64 }">
  <div class="custom-dropzone" [kendoUploadDropZone]="id">
    <p>Arrastra aquí tu archivo.</p>
  </div>
  <kendo-fileselect
    #fileSelect
    [restrictions]="restrictions"
    (select)="select($event)"
    (remove)="remove()"
    [zoneId]="id"
    [(ngModel)]="filesList"
  >
  </kendo-fileselect>
  <kendo-formerror *ngIf="touched && !base64">
    {{ "Este archivo es requerido" }}
  </kendo-formerror>
  <kendo-formerror *ngIf="errorMsg || (touched && !base64)">
    {{ errorMsg }}
  </kendo-formerror>
</div>
