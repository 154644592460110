import {
  Computed,
  DataAction,
  Payload,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { arrayToMap } from '@core/functions';
import {
  BusinessModel,
  CatalogModel,
  CatalogType,
  CatalogsStore,
  GradeModel,
} from '@core/models';
import { CATALOGS_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { uniqBy, values } from 'lodash';
import { Observable, map } from 'rxjs';

export class CatalogsStateModel extends CatalogsStore<CatalogModel> {}

@Persistence()
@StateRepository()
@State<CatalogsStateModel>({
  name: 'catalogs',
  defaults: {
    catalogs: {
      ...CATALOGS_STORE_DEFAULTS,
    },
  },
})
@Injectable()
export class CatalogsState extends NgxsDataRepository<CatalogsStateModel> {
  @Computed()
  public get schools$(): Observable<CatalogModel[]> {
    return this.state$.pipe(
      map((stateModel) => {
        return stateModel.catalogs.schools.records;
      })
    );
  }
  @Computed()
  public get states$(): Observable<CatalogModel[]> {
    return this.state$.pipe(
      map((stateModel) => {
        return stateModel.catalogs.states.records;
      })
    );
  }
  @Computed()
  public get academicYears$(): Observable<CatalogModel[]> {
    return this.state$.pipe(
      map((stateModel) => {
        return stateModel.catalogs.academicYears.records;
      })
    );
  }

  @Computed()
  public get countries$(): Observable<CatalogModel[]> {
    return this.state$.pipe(
      map((countryModel) => {
        return countryModel.catalogs.countries.records;
      })
    );
  }
  @Computed()
  public get ledgerAccount$(): Observable<CatalogModel[]> {
    return this.state$.pipe(
      map((stateModel) => {
        return stateModel.catalogs.ledgerAccount.records;
      })
    );
  }

  @DataAction()
  public pushItem(
    @Payload('item') item: CatalogModel,
    @Payload('catalog')
    catalog: CatalogType
  ): void {
    this.ctx.setState((state) => {
      const updatedCatalogs = {
        ...state.catalogs,
        [catalog]: {
          ...state.catalogs[catalog],
          records: [...state.catalogs[catalog].records, item],
          totalItems: state.catalogs[catalog].totalItems + 1,
        },
      };

      return {
        ...state,
        catalogs: updatedCatalogs,
      };
    });
  }

  @DataAction()
  public restart(): void {
    this.ctx.setState((state) => {
      return {
        ...state,
        catalogs: {
          ...CATALOGS_STORE_DEFAULTS,
        },
      };
    });
  }

  @DataAction()
  public setCatalogItems(
    @Payload('records') records: CatalogModel[],
    @Payload('catalog')
    catalog: CatalogType
  ): void {
    this.ctx.setState((state) => {
      const updatedCatalogs = {
        ...state.catalogs,
        [catalog]: {
          ...state.catalogs[catalog],
          records,
          totalItems: records.length,
        },
      };

      return {
        ...state,
        catalogs: updatedCatalogs,
      };
    });
  }

  public getItems(catalog: CatalogType): Observable<CatalogModel[]> {
    return this.state$.pipe(
      map((catalogsState) => {
        return values(catalogsState.catalogs[catalog].records);
      })
    );
  }

  public getItemsLength(catalog: CatalogType): number {
    return this.snapshot.catalogs[catalog].totalItems;
  }

  public getItemsMap(
    catalog: CatalogType
  ): Observable<{ [id: string]: CatalogModel }> {
    return this.state$.pipe(
      map((catalogsState) => {
        return arrayToMap(catalogsState.catalogs[catalog].records, 'id');
      })
    );
  }

  public getFilteredBusiness(
    ...regionsIds: string[]
  ): Observable<BusinessModel[]> {
    return this.state$.pipe(
      map((catalogsState) => {
        const business = values(catalogsState.catalogs.regions.records)
          .filter((region) => regionsIds.includes(region.id))
          .map((region) => region.business)
          .flat();

        return business.length > 0
          ? (uniqBy(business, (business) => business?.id) as BusinessModel[])
          : [];
      })
    );
  }

  public getFilteredSchools(
    businessIds: string[],
    regionId?: string
  ): Observable<CatalogModel[]> {
    return this.state$.pipe(
      map((catalogsState) => {
        const business = values(catalogsState.catalogs.regions.records)
          .filter((region) => !regionId || region.id === regionId)
          .map((region) => region.business)
          .flat()
          .filter((business) =>
            business ? businessIds.includes(business.id) : false
          );

        const schools = business
          .map((business) => business?.schools || [])
          .flat();

        return schools.length > 0 ? uniqBy(schools, (school) => school.id) : [];
      })
    );
  }

  public getFilteredGrades(sectionId: number): Observable<GradeModel[]> {
    return this.state$.pipe(
      map((catalogsState) => {
        const grades = values(catalogsState.catalogs.grades.records)
          .filter((grade) => grade['sectionId'] === sectionId)
          .flat();

        return grades.length > 0
          ? (uniqBy(grades, (grades) => grades.id) as GradeModel[])
          : [];
      })
    );
  }
}
