import { FinancialEntriesModel } from '@core/models';

export const ledgerSeatData: FinancialEntriesModel[] = [
  {
    accountName: 'Banco',
    accountNumber: '1005',
    amount: '50000',
    dateApply: new Date().toLocaleString(),
    id: '105',
    type: 'Cargo',
    relatedNotice: '14260',
    credit: 0,
    debit: 1000,
  },
  {
    accountName: 'Clientes',
    accountNumber: '1006',
    amount: '15000',
    dateApply: new Date().toLocaleString(),
    id: '106',
    type: 'Abono',
    relatedNotice: '14260',
    credit: 1110,
    debit: 0,
  },
];
