import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CollectionResponse,
  EconomicActivitiesModel,
  FiscalRegimeModel,
} from '@core/models';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';
import { Api } from '../api.state';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class FiscalRegimeService extends Api<FiscalRegimeModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<FiscalRegimeModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/configuration/fiscal-regimes`;
  }

  public getEconomicActivities(
    country: string
  ): Observable<EconomicActivitiesModel[]> {
    const endpoint = `${environment.apiUrl}/v1/admin/configuration/economic-activities?country=${country}&limit=1000`;
    return this.http
      .get<CollectionResponse<EconomicActivitiesModel>>(endpoint)
      .pipe(
        map((result) => {
          return result.data.items;
        })
      );
  }
}
