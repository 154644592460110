import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { CollectionResponse, DiscountModel, RvoeModel } from '@core/models';
import { RvoeService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';
import { Observable, finalize, switchMap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<DiscountModel>>({
  name: 'rvoe',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class RvoeState extends GenericState<RvoeModel> {
  override service: RvoeService;

  constructor(private rvoeService: RvoeService) {
    super();
    this.service = rvoeService;
  }
  public buildStringEndpoint(schoolId: string): void {
    this.service.buildStringEndpoint(schoolId);
  }

  public createRvoe(
    data: RvoeModel,
    schoolId: string
  ): Observable<RvoeModel[]> {
    this.buildStringEndpoint(schoolId);
    this.toggleLoading();
    return this.rvoeService.post(data).pipe(
      finalize(() => {
        this.patchState({
          isLoading: false,
        });
      }),
      switchMap((): Observable<RvoeModel[]> => {
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        return this.getAllByPage({ page: lastPage }, true);
      })
    );
  }
  public updateRvoe(
    data: RvoeModel,
    schoolId: string
  ): Observable<RvoeModel[]> {
    this.buildStringEndpoint(schoolId);
    this.toggleLoading();
    return this.rvoeService.update(data, data.id).pipe(
      finalize(() => {
        this.patchState({
          isLoading: false,
        });
      }),
      switchMap((): Observable<RvoeModel[]> => {
        return this.getAllByPage({ page: this.snapshot.currentPage }, true);
      })
    );
  }
  public deleteRvoe(
    id: string,
    schoolId: string
  ): Observable<CollectionResponse<RvoeModel>> {
    this.buildStringEndpoint(schoolId);
    this.toggleLoading();
    return this.rvoeService.delete(id).pipe(
      finalize(() => {
        this.toggleLoading();
      })
    );
  }
}
