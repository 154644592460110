import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import {
  ADMIN_DEFAULT_ROUTE,
  APP_QUERY_PARAMS,
  USER_DEFAULT_ROUTE,
} from '@core/constants';
import { isLoggedInFn } from '@core/functions';
import { SessionState } from '@core/state/shared';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

export const isUnloggedGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  const router = inject(Router),
    sessionState = inject(SessionState),
    store = inject(Store);

  const isLoggedIn = isLoggedInFn(),
    pathToNavigate = sessionState.isAdmin
      ? ADMIN_DEFAULT_ROUTE
      : USER_DEFAULT_ROUTE;

  if (redirectIfLogged(route, router, isLoggedIn)) {
    return false;
  }

  return isLoggedIn ? store.dispatch(new Navigate([pathToNavigate])) : true;
};

function redirectIfLogged(
  route: ActivatedRouteSnapshot,
  router: Router,
  isLoggedIn: boolean
) {
  const billPayment = route.queryParams[APP_QUERY_PARAMS.billPayment],
    payCollectionNotice =
      route.queryParams[APP_QUERY_PARAMS.payCollectionNotice];

  if (isLoggedIn) {
    if (billPayment) {
      router.navigateByUrl(`app/historial-pagos/facturar/${billPayment}`);
      return true;
    } else if (payCollectionNotice) {
      router.navigateByUrl(`app/avisos-cobranza/pagos/${payCollectionNotice}`);
      return true;
    }
  }

  return false;
}
