import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FinancialEntriesModel,
  GeneratedFinancialEntriesModel,
  PaginationModel,
  SingleItemResponse,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FinancialEntriesService extends Api<FinancialEntriesModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<FinancialEntriesModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/accounting/financial-entries`;
  }

  public exportFinancialEntries(
    params: PaginationModel,
    isSAP: boolean
  ): Observable<Blob> {
    const endpoint = `${this.endpoint}/export/excel${
      isSAP ? '' : '/report'
    }${`?${new URLSearchParams(params as any).toString()}`}`;

    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }

  public generatedFinancialEntries(): Observable<string> {
    const endpoint = `${this.endpoint}/generated`;
    return this.http
      .get<SingleItemResponse<GeneratedFinancialEntriesModel>>(endpoint)
      .pipe(map((response) => response.data.date));
  }

  public exportZIPFinancialEntries(params: PaginationModel): Observable<Blob> {
    const endpoint = `${
      this.endpoint
    }/export/excel/split${`?${new URLSearchParams(params as any).toString()}`}`;
    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }
}
