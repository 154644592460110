import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { PriceModel } from '@core/models';
import { PricesService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<PriceModel>>({
  name: 'Prices',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class PricesState extends GenericState<PriceModel> {
  override service: PricesService;

  constructor(private pricesService: PricesService) {
    super();
    this.service = pricesService;
  }
}
