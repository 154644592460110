import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentUserState, SessionState } from '@core/state/shared';
import { environment } from '@env/environment';
import { Observable, first, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ZendeskTokenService {
  constructor(
    private currentUserState: CurrentUserState,
    private http: HttpClient,
    private sessionState: SessionState
  ) {}

  public getToken(): Observable<string> {
    return this.http
      .get<{ data: { jwtToken: string } }>(
        `${environment.apiUrl}${
          this.sessionState.isAdmin ? '/v1/admin/auth/jwt' : '/v1/auth/jwt'
        }`,
        {
          params: {
            name: this.currentUserState.currentUser?.name as string,
            email: this.sessionState.email,
            user_id: this.currentUserState.currentUser?.id as string,
          },
        }
      )
      .pipe(
        first(),
        map((response) => response.data.jwtToken)
      );
  }
}
