import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CollectionResponse, FamilyModel, StudentModel } from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FamiliesService extends Api<FamilyModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<FamilyModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/families`;
  }

  public getStudentsByFamily(familyId: string): Observable<StudentModel[]> {
    const url = `${environment.apiUrl}/v1/admin/families/${familyId}/students`;
    return this.http.get<CollectionResponse<StudentModel>>(url).pipe(
      map((respose) => {
        return respose.data.items;
      })
    );
  }

  public getFamiliesBySchool(schoolId: string): Observable<FamilyModel[]> {
    const url = `${this.endpoint}?school_id=${schoolId}&limit=1000`;
    return this.http.get<CollectionResponse<FamilyModel>>(url).pipe(
      map((respose) => {
        return respose.data.items;
      })
    );
  }
}
