import { LanguagesEnum } from '@core/constants';
import { capitalize } from 'lodash';
import { AccesoryModel } from './accessory.model';
import { CatalogModel } from './catalog.model';
import { ClassroomModel } from './classrooms.model';
import { CommonModel } from './common.model';
import { ConceptModel } from './concept.model';
import { CustomResponse } from './custom.response.model';
import { FamilyPersonModel } from './family-person.model';
import { ApiModel } from './generic-state.model';
import { GradeModel } from './grade.model';
import { GuardianModel } from './guardian.model';
import { FinancialEntriesModel } from './ledger-seat.model';
import { SchoolModel } from './school.model';
import { SectionModel } from './section.model';
import { StudentModel } from './student.model';

export interface CollectionNoticeModel extends ApiModel {
  accessories: AccesoryModel[];
  administrativeRulesAccepted: boolean;
  amount?: string;
  belongsToDate: string;
  belongsTo: string;
  concept_name?: string;
  concept: ConceptModel;
  cycle?: CommonModel;
  deadlineAt: string;
  emittedAt: string;
  finalAmount: number;
  vat: number;
  vatFinal: number;
  guardian: FamilyPersonModel;
  guardians: string[];
  id: string;
  invoiceId: string;
  isCashierRejected: number;
  isGeneralPublic: boolean;
  isPayable: boolean;
  isTutorHidden: string;
  payment?: {
    data: {
      items: CollectionNoticePaymentModel[];
      total: number;
    };
  };
  paymentId: string;
  paymentMethodId?: string;
  paymentType?: number;
  reference: string;
  school_name?: string;
  school?: SchoolModel;
  schoolConceptId: string;
  status: string;
  student_name?: string;
  student: StudentModel;
  studentSchoolRelationId: string;
  subConcept: CatalogModel;
  is_cashier_rejected?: boolean;
  isMigrated: boolean;
  isDiscountLocked: boolean;
  final_amount?: number;
  hasDiscountCouponTransaction: boolean;
  section: SectionModel;
  classroom: ClassroomModel;
  grade: GradeModel;
  subConceptFriendlyName: string;
  partialRefundAmount: number;
  isPrepaid: boolean;
  source: string;
  interest?: number;
  currency: string;
  isSplit: boolean;
  isValid: boolean;
  email?: string;
  description?: string;
  name?: string;
  last_name?: string;
  financialEntries: FinancialEntriesModel[];
  countryCode: string;
  langAndCountry: CollectionConfigLanguageModel;
}

export interface CollectionNoticeRejectionModel {
  conceptCategoryId: string;
  conceptId: string;
  description: string;
  id: string;
  subConceptId: string;
  team: string;
}

export interface CollectionNoticeRejectionHistoryModel {
  area: { id: string; name: string };
  areaId: string;
  authorizingDate: string;
  authorizingText: string;
  authorizingUser: string;
  authorizingUserObject: { fullName: string };
  collectionNoticeId: string;
  id: string;
  reasonForRejectionId: string;
  reportingDate: string;
  reportingText: string;
  reportingUser: string;
  reportingUserObject: { fullName: string };
  solvingDate: string;
  solvingText: string;
  solvingUser: string;
  solvingUserObject: { fullName: string };
  status: string;
  zdTicketId: string;
}

export interface CollectionNoticeStatusModel {
  id: string;
  name: string;
  status?: string;
}

export enum CollectionNoticeStatus {
  IN_PAYMENT = 'en proceso de pago',
  CANCELED = 'cancelado',
  PENDING = 'pendiente',
  EXPIRED = 'vencido',
  PAID = 'pagado',
  REFUND = 'devuelto',
  BILLED = 'facturado',
  PUBLIC_BILLED = 'facturado publico',
  PAYMENT_VALIDATION = 'pago en validacion',
  PAYMENT_INITIALIZING = 'inicializando pago',
  REJECTED = 'rechazado',
  CANCELLED = 'cancelado',
  IN_PAYMENT_PROCESS = 'en proceso de pago',
  INITIALIZING_PAYMENT = 'inicializando pago',
  PENDINDG_REFUND = 'Devolución Pendiente',
  PENDINDG_CHARGE_BACK = 'Pendiente Por Contracargo',
}

export interface CollectionNoticePaymentResponse<T> extends CustomResponse<T> {
  status: boolean;
  data: {
    items: T[];
    total: number;
    finalAmount: number;
    guardian: string[];
    guardians: GuardianModel[];
  };
}

export const COLLECTION_NOTICE_STATUS: CollectionNoticeStatusModel[] = [
  {
    id: capitalize(CollectionNoticeStatus.PENDING),
    name: capitalize(CollectionNoticeStatus.PENDING),
  },
  {
    id: capitalize(CollectionNoticeStatus.PAID),
    name: capitalize(CollectionNoticeStatus.PAID),
  },
  {
    id: capitalize(CollectionNoticeStatus.EXPIRED),
    name: capitalize(CollectionNoticeStatus.EXPIRED),
  },
];

export interface CollectionNoticeRemoveParams {
  collection_notice: string;
  description?: string;
  reference: string;
}

export interface CollectionNoticeApproveModel {
  is_tutor_hidden: boolean;
  is_cashier_rejected?: boolean;
}

export interface CollectionNoticePaymentModel {
  description: string;
  externalBank: string;
  externalCompletedAt: string;
  externalReferenceProcessor: string;
  externalTransferProcessor: string;
  fee: number;
  feeAmount: number;
  feeCardBrand: string;
  feeCardType: string;
  feePaymentMethod: string;
  feePercentage: number;
  guardianId: string;
  hasDevolution: number;
  id: string;
  isDiscountApplied: number;
  manualExpire: number;
  metaIdCollectionNotice: string;
  metaIdSchool: string;
  paymentDate: string;
  paymentMethodId: string;
  paymentMethodType: string;
  paymentType: string;
  pins?: CollectionNoticePaymentPinModel[];
  reference: string;
  refundAmount: string;
  refundDate: string;
  refundRequestDate: string;
  refundTransactionId: string;
  status: string;
  totalPaid: number;
  tpvSchoolId: string;
  transactionId: string;
}

export interface CollectionNoticePaymentPinModel {
  pin: string;
  processorName: string;
}

export interface StudentSchoolCycleCollection {
  school_id: string;
  cycle: string;
  isPrepaid: boolean;
}

export interface CollectionNoticeEvent {
  name: string;
  value: string;
  event: string;
}

export interface CollectionNoticeSource {
  name: string;
  value: string;
}

export interface CoreCollectionNotice {
  source: string;
  topology_id: string;
  event_type:
    | 'alta_inscripcion'
    | 'alta_reinscripcion'
    | 'alta_colegiatura'
    | 'baja_inscripcion'
    | 'baja_reinscripcion'
    | 'baja_colegiatura'
    | 'nuevo_ingreso_atipico';
  academic_year_id: string;
  origin_school_id?: string;
  school_id: string;
  section_id: string;
  grade_id: string;
  student_id: string;
  is_atypical: any;
  emitted_at?: string;
  deadline_at?: string;
  belongs_to_date: string;
  is_tutor_hidden: any;
  is_cashier_hidden: any;
}

export interface CollectionNoticesFilterableFieldsModel {
  reference?: boolean;
  enrollmentNumber?: boolean;
  category?: boolean;
  student?: boolean;
  familyCode?: boolean;
  subconcept?: boolean;
  cycle?: boolean;
  emittedAt?: boolean;
  deadlineAt?: boolean;
  belongsDate?: boolean;
  status?: boolean;
}

export interface ReconciliationModel {
  collectionNoticeId: string[];
  paymentId: string;
  ticketNumber: string;
  evidence: string;
  conciliation_data: any;
}

export interface CollectionConfigLanguageModel {
  country: string;
  idPymntProvider: string;
  lang: LanguagesEnum;
}
