import { Component, Input } from '@angular/core';
import { BadgeThemeColor } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-badge-status',
  templateUrl: './badge-status.component.html',
  styleUrls: ['./badge-status.component.css'],
})
export class BadgeStatusComponent {
  @Input() status?: string;
  private codeColor: {
    [key: string]: { badge: BadgeThemeColor; text: string };
  } = {
    vencido: {
      badge: 'error',
      text: 'white',
    },
    cancelado: {
      badge: 'error',
      text: 'white',
    },
    pendiente: {
      badge: 'info',
      text: 'white',
    },
    pagado: {
      badge: 'success',
      text: 'white',
    },
  };
  public get badgeColor(): BadgeThemeColor {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.status || !this.codeColor[this.status.toLowerCase()]) {
      return 'dark';
    }
    return this.codeColor[this.status.toLowerCase()].badge;
  }

  public get textColor(): string {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.status || !this.codeColor[this.status.toLowerCase()]) {
      return 'dark';
    }
    return this.codeColor[this.status.toLowerCase()].text;
  }
}
