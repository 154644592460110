<kendo-daterange class="d-inline">
  <kendo-label [text]="text" [ngClass]="(disabled$ | async) ? 'disabled' : ''">
    <kendo-textbox
      (click)="popup.toggle()"
      [(ngModel)]="dateRange.value"
      #anchor
      [readonly]="true"
      [disabled]="!!(disabled$ | async)"
    >
      <ng-template
        kendoTextBoxSuffixTemplate
        *ngIf="clearable && dateRange.value"
      >
        <button
          kendoButton
          fillMode="clear"
          size="small"
          (click)="clear($event)"
        >
          <i class="ph ph-x"></i>
        </button>
      </ng-template>
    </kendo-textbox>
  </kendo-label>
  <kendo-floatinglabel text="Fecha Inicio">
    <kendo-dateinput
      kendoDateRangeStartInput
      [(ngModel)]="startDate"
      (valueChange)="onValueChange(popup)"
      [format]="format"
    ></kendo-dateinput>
  </kendo-floatinglabel>
  <kendo-floatinglabel text="Fecha Final">
    <kendo-dateinput
      [(ngModel)]="endDate"
      kendoDateRangeEndInput
      [format]="format"
      (valueChange)="onValueChange(popup)"
    ></kendo-dateinput>
  </kendo-floatinglabel>
  <kendo-daterange-popup
    #popup
    [anchor]="{ nativeElement: anchor.input.nativeElement }"
  ></kendo-daterange-popup>
</kendo-daterange>
