import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LANGUAGE_ICONS } from '@core/constants';
import { AuthService } from '@core/services';
import { LayoutState, SessionState } from '@core/state/shared';
import { TranslateService } from '@ngx-translate/core';
import {
  ENABLED_SWITCHER_APPLICATION,
  SWITCHER_OPTIONS,
} from '@widgets/apps-layout/constants/constants';
import { SwitcherOptionModel } from '@widgets/switcher-menu/models/switcher-menu-option-model';
import { Observable } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  public isAdmin = this.sessionState.isAdmin;
  public isSidenavOpen$: Observable<boolean>;
  public readonly SwitcherOptions: SwitcherOptionModel[] = SWITCHER_OPTIONS;
  public readonly ENABLED_SWITCHER_APPLICATION = ENABLED_SWITCHER_APPLICATION;
  public readonly LANGUAGE_ICONS = LANGUAGE_ICONS;
  constructor(
    private authService: AuthService,
    public layoutState: LayoutState,
    private sessionState: SessionState,
    public translate: TranslateService
  ) {}

  public get languageIcon(): string {
    return this.LANGUAGE_ICONS.get(this.layoutState.language) || 'mx';
  }
  public ngOnInit(): void {
    this.isSidenavOpen$ = this.layoutState.isSidenavOpen$;
  }

  public logout(): void {
    this.authService.logout();
  }

  public setLanguage(lang: string): void {
    this.translate.use(lang);
    this.layoutState.setLanguage(lang);
  }
}
