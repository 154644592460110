import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ADMIN_DEFAULT_ROUTE } from '@core/constants';
import { SessionState } from '@core/state/shared';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

export const isUserGuard: CanActivateFn = () => {
  const sessionState = inject(SessionState),
    store = inject(Store);

  return sessionState.isAdmin
    ? store.dispatch(new Navigate([ADMIN_DEFAULT_ROUTE]))
    : true;
};
