import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenizePaymentMethodModel, TokenizedCardResponseModel } from '@core/models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenizePaymentService {
  public endpoint = `${environment.apiUrl}/v1/account/payment-methods/tokenize`;

  constructor(private http: HttpClient) { }
  public tokenizePayment(data: TokenizePaymentMethodModel): Observable<TokenizedCardResponseModel> {
    return this.http.post<TokenizedCardResponseModel>(this.endpoint, JSON.stringify(data));
  }
}
