import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { loadScript } from '@core/functions';
import { GlobalState } from '@core/state';
import { environment } from '@env/environment';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private global: GlobalState) {}

  public ngOnInit() {
    this.global.activateModel('test');
    this.loadZendeskChatScript();
  }

  private loadZendeskChatScript() {
    loadScript(
      'ze-snippet',
      window.location.pathname.includes('admin')
        ? environment.chat.adminUrl
        : environment.chat.userUrl,
      false
    );
  }
}
