import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { ChargesByCategoryReportModel } from '@core/models';
import { ChargeByCategoryReportService } from '@core/services/admin';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { State } from '@ngxs/store';

@StateRepository()
@State<GenericStateModel<ChargesByCategoryReportModel>>({
  name: 'chargeByCategoryReport',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class ChargeByCategoryReportState extends GenericState<ChargesByCategoryReportModel> {
  override service: ChargeByCategoryReportService;
  constructor(paymentOrders: ChargeByCategoryReportService) {
    super();
    this.service = paymentOrders;
  }
}
