import { EventCategoryModel } from '@core/models';

export const EVENTS_CATEGORIES: EventsCategories = {
  HOSPITALITY: 'hoteleria',
  APPORTIONMENT: 'prorrateo',
  INSCRIPTION: 'inscripcion',
};

export interface EventsCategories {
  [name: string]: string;
}

export const HOSPITALITY_CONCEPTS_ROOMS: EventsCategories[] = [
  { SIGLE: 'Habitación sencilla' },
  { DOUBLE: 'Habitación doble' },
  { SUITE: 'Habitación suite' },
];

export const HOSPITALITY_DEFAULT_DATA: EventCategoryModel = {
  committedAmount: '0',
  conceptId: '',
  friendly: 'Hotelería',
  id: '',
  isHotel: true,
  name: '',
  paidAmount: 0,
  pendingAmount: 0,
  schoolId: '',
  schoolName: '',
  schoolSubConceptAmount: 0,
  schoolSubConceptId: '',
  schoolSubConceptName: '',
  subConceptId: '',
  totalAmount: 0,
  activeMonths: '',
};
