import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import {
  AdminModel,
  CollectionResponse,
  CommonFilters,
  PaginationModel,
} from '@core/models';
import { AdminsService } from '@core/services';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { formatMultiStoreData } from '@core/utils/format-multi-store-data.util';
import { State } from '@ngxs/store';
import { Observable, finalize, first, map, tap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<AdminModel>>({
  name: 'admin',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class AdminsState extends GenericState<AdminModel> {
  override service: AdminsService;
  constructor(private adminsService: AdminsService) {
    super();
    this.service = adminsService;
  }

  public createAdmin(adminData: AdminModel): Observable<AdminModel> {
    this.toggleLoading();
    return this.adminsService
      .post(adminData)
      .pipe(finalize(() => this.toggleLoading()));
  }

  public deleteAdmin(id: string): Observable<CollectionResponse<AdminModel>> {
    return this.adminsService.delete(id);
  }

  public editAdmin(adminData: AdminModel, id: string): Observable<AdminModel> {
    this.toggleLoading();
    return this.adminsService
      .update(adminData, id)
      .pipe(finalize(() => this.toggleLoading()));
  }

  public editAdminName(
    data: {
      name: string;
      last_name: string;
      second_last_name?: string;
    },
    id: string
  ): Observable<AdminModel> {
    this.toggleLoading();
    return this.adminsService
      .updateName(data, id)
      .pipe(finalize(() => this.toggleLoading()));
  }

  public getAdminById(id: string): Observable<AdminModel> {
    if (!(this.snapshot.items[id] as AdminModel | undefined)) {
      this.adminsService.find(id).subscribe((admin) => {
        this.patchState({
          items: { ...this.snapshot.items, [admin.id]: admin },
        });
      });
    }
    return this.state$.pipe(
      first((adminsState) => !!adminsState.items[id]),
      map((adminStateModel) => {
        return adminStateModel.items[id];
      })
    );
  }

  public getAdminsByPage(
    page = 1,
    filters: CommonFilters,
    force = false,
    params?: PaginationModel
  ): Observable<AdminModel[]> {
    if (this.isPageLoaded(page) && !force) {
      this.patchState({
        currentPage: page,
      });
      return this.itemsByPage$;
    }
    this.toggleLoading();
    if (force) {
      this.patchState({
        pages: {},
      });
    }
    return this.adminsService
      .getAll({
        is_admin: true,
        ...filters,
        page,
        limit: this.pageLimit,
        ...params,
      })
      .pipe(
        finalize(() => {
          this.patchState({
            isLoading: false,
          });
        }),
        tap((content): void => {
          const currentState = this.getState();
          const { items: admins } = content.data;
          const objectToPatch: Partial<GenericStateModel<AdminModel>> = {
            totalItems: content.data.total,
            currentPage: page,
            items: { ...currentState.items, ...formatMultiStoreData(admins) },
            pages: {
              ...currentState.pages,
              [page]: admins.map((admin) => admin.id),
            },
            isLoading: false,
          };
          this.patchState(objectToPatch);
        }),
        map((content) => {
          return content.data.items;
        })
      );
  }

  public toggleIsImpersonate(admin: AdminModel) {
    this.patchState({
      items: {
        ...this.snapshot.items,
        [admin.id]: {
          ...admin,
          isImpersonate: !admin.isImpersonate,
        },
      },
    });
  }
}
