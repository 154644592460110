import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  InvoiceModel,
  InvoicingDataModel,
  StampingParamsModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@rcsa-frontend/environments/environment';
import { pickBy } from 'lodash';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExternalInvoicingService extends Api<InvoicingDataModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<InvoicingDataModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/external/account/invoicing-data`;
  }

  public stampCollectionNotice(
    params: StampingParamsModel,
    collectionNoticeId: string
  ): Observable<InvoiceModel> {
    const endpoint = `${environment.apiUrl}/v1/external/account/invoicing/collection_notices/${collectionNoticeId}/stamp`;
    params = pickBy(params, (value) => value !== null) as StampingParamsModel;
    return this.apiService.post(endpoint, JSON.stringify(params)).pipe(
      map((data: any) => {
        if (Array.isArray(data.data)) {
          return data.data[0];
        }
        return data.data;
      })
    );
  }

  public exportPDF(id: string, email: string): Observable<Blob> {
    const endpoint = `${environment.apiUrl}/v1/external/account/invoicing/invoices/${id}/pdf?email=${email}`;
    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }
}
