import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { CollectionResponse, ConceptManagmentModel } from '@core/models';
import { ConceptsManagmentService } from '@core/services';
import { GenericState, GenericStateModel } from '@core/state/generic.state';
import { MULTI_ITEM_STORE_DEFAULTS } from '@core/state/initial-state';
import { formatMultiStoreData } from '@core/utils/format-multi-store-data.util';
import { State } from '@ngxs/store';
import { Observable, finalize, map, switchMap, tap } from 'rxjs';

@StateRepository()
@State<GenericStateModel<ConceptManagmentModel>>({
  name: 'conceptsManagment',
  defaults: {
    ...MULTI_ITEM_STORE_DEFAULTS,
  },
})
@Injectable()
export class ConceptsManagementState extends GenericState<ConceptManagmentModel> {
  override service: ConceptsManagmentService;
  constructor(private conceptsManagmentService: ConceptsManagmentService) {
    super();
    this.service = conceptsManagmentService;
  }

  public createConceptsManagement(
    data: ConceptManagmentModel
  ): Observable<ConceptManagmentModel[]> {
    this.toggleLoading();
    return this.conceptsManagmentService.post(data).pipe(
      finalize(() => {
        this.patchState({
          isLoading: false,
        });
      }),
      switchMap((): Observable<ConceptManagmentModel[]> => {
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        return this.getAllByPage({ page: lastPage }, true);
      })
    );
  }

  public deleteConceptsManagement(
    id: string
  ): Observable<ConceptManagmentModel[]> {
    return this.conceptsManagmentService.delete(id).pipe(
      finalize(() => {
        this.toggleLoading();
      }),
      switchMap((): Observable<ConceptManagmentModel[]> => {
        return this.getAllByPage({ page: this.currentPage }, true);
      })
    );
  }

  public getConceptsManagementById(
    id: string
  ): Observable<ConceptManagmentModel> {
    return this.state$.pipe(
      map((conceptsManagementState) => {
        return conceptsManagementState.items[id];
      })
    );
  }

  public getConceptsManagementByIds(
    id: string
  ): Observable<ConceptManagmentModel> {
    return this.conceptsManagmentService.getConceptManagementById(id).pipe(
      map((result) => {
        return result.data;
      })
    );
  }

  public updateConceptsManagment(
    payment: ConceptManagmentModel
  ): Observable<ConceptManagmentModel[]> {
    this.toggleLoading();
    return this.conceptsManagmentService.updateConceptManagement(payment).pipe(
      finalize(() => {
        this.patchState({
          isLoading: false,
        });
      }),
      switchMap((): Observable<ConceptManagmentModel[]> => {
        const pages = Object.keys(this.snapshot.pages);
        const lastPage =
          pages.length > 0 ? parseInt(pages[pages.length - 1]) : 1;
        return this.getAllByPage({ page: lastPage }, true);
      })
    );
  }

  public getAllConcepts(): Observable<
    CollectionResponse<ConceptManagmentModel>
  > {
    return this.conceptsManagmentService.getAll({ page: 1, limit: 1000 }).pipe(
      tap((content): void => {
        const { items: concepts } = content.data;
        this.patchState({
          totalItems: content.data.total,
          currentPage: 1,
          items: formatMultiStoreData(concepts),
          pages: {
            1: concepts.map((concept) => concept.id),
          },
        });
      })
    );
  }
}
