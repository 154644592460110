import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApplyDiscountCouponModel,
  DiscountCouponsModel,
  PaginationModel,
} from '@core/models';
import { Api } from '@core/services/api.state';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DiscountCouponsService extends Api<DiscountCouponsModel> {
  override endpoint: string;

  constructor(
    protected override readonly apiService: ApiService<DiscountCouponsModel>,
    private http: HttpClient
  ) {
    super(apiService);
    this.endpoint = `${environment.apiUrl}/v1/admin/discount-coupons`;
  }

  public applyCoupon(data: ApplyDiscountCouponModel): Observable<boolean> {
    const apiUrl = `${this.endpoint}/apply`;
    return this.apiService.post(apiUrl, JSON.stringify(data)).pipe(
      map(() => {
        return true;
      })
    );
  }

  public export(params: PaginationModel): Observable<Blob> {
    params.limit = 100000;
    const endpoint = `${this.endpoint}/export/excel${`?${new URLSearchParams(
      params as any
    ).toString()}`}`;
    return this.http.get(endpoint, {
      responseType: 'blob',
    });
  }
}
